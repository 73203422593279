import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Box,
  TextField,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import GroupsIcon from "@material-ui/icons/People";
import axios from "axios";

import MenuIcon from "@material-ui/icons/Menu";
import { PowerSettingsNew } from "@material-ui/icons";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AccountCircle from "@material-ui/icons/AccountCircle";
import {
  Switch,
  Route,
  NavLink,
  useHistory,
  useLocation,
} from "react-router-dom";
import routes, {
  zmcItems,
  fzcItems,
  partnerItems,
  enterpriseItems,
  otherEnterpriseItems,
  oplCertifiedEnterpriseItems,
} from "routes";
import logo from "images/Nepzaimage.png";
import CompanyRequestDetials from "../CompanyRequest/CompanyRequestDetails";
import ZmcActionSubmitted from "./ZmcActionSubmitted";
import { data } from "../EnterpriseDashboard/EnterpriseList";
import Logout from "../../images/logout.svg";
import { AppConfig } from "app-config";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import StarBorder from "@material-ui/icons/StarBorder";
import CheckList from "pages/checklist/CheckList";
import UserProfile from "pages/UserProfile";
const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    padding: "1em 0",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  menuLink: {
    position: "relative",
    display: "block",
    // padding:' 0 3em'
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: "#47BB78",
    color: "white",
  },
  logo: {
    maxWidth: "80%",
  },
  icons: {
    color: "red !important",
  },
  input: {
    color: "#000",
    padding: "1em",
    border: "1px solid rgba(176, 174, 174, 1)",
    borderRadius: "2em",
    fontSize: "14px",
    width: "480px",
  },
  profileImage: {
    height: "20px",
    width: "20px",
  },
}));

function ResponsiveDrawer(props) {
  const { window } = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [openCheckList, setOpenCheckList] = useState(false);
  const [iconColor, setColor] = useState(false);
  const [enterpriseClass, setEnterpriseClass] = useState(enterpriseItems);
  const [regStatus, setRegStatus] = useState(false);
  const user = localStorage.getItem("userDetails");
  const [open, setOpen] = React.useState(false);
  const [profile, setProfile] = useState(null);

  const handleClick = () => {
    setOpen(!open);
  };

  const userData = JSON.parse(user);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const id = userData.data.id;

  const history = useHistory();
  const certificateOfRegStatus = async () => {
    if (userData?.data?.company?.entity_type_id == 4) {
      try {
        const response = await fetch(`${AppConfig.URL}/opl-reg/status`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        });
        const { status, data, message } = await response.json();
        if (status === true) {
          setRegStatus(data?.opl_certificate);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  React.useEffect(() => {
    certificateOfRegStatus();
    // getUserZone();
  }, []);
  const getUser = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  //get enterprise detail
  const getLoggedUser = async (e) => {
    try {
      const response = await axios.get(`${AppConfig.URL}/users/${id}/find`, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, message, data } = response;

      if (status) {
        setProfile(data);
        console.log("User Profile Data", data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let url = `${AppConfig.URL}/cert-of-reg/status`;

  let reqMethod = "GET";

  const getApplicationStatus = async () => {
    const user = localStorage.getItem("userDetails");
    const userData = JSON.parse(user);
    try {
      const response = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
      });
      const { status, data } = await response.json();
      console.log("CERT__DATADATA", data);
      if (data?.cert_of_reg === true) {
        setEnterpriseClass(otherEnterpriseItems);
      }

      console.log("CERT STATUS!!!", status);
    } catch (err) {
      console.log(err);
    }
  };

  const getOplCertificate = async () => {
    const user = localStorage.getItem("userDetails");
    const userData = JSON.parse(user);
    console.log("LOGGED USERDAT", userData);
    try {
      const response = await fetch(`${AppConfig.URL}/opl-reg/status`, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
      });
      const { status, message, data } = await response.json();
      console.log("DATADATA!!!", data);
      if (data?.opl_certificate === true) {
        setEnterpriseClass(oplCertifiedEnterpriseItems);
      }

      console.log("CERT STATUS!!!", status);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getApplicationStatus();
  }, []);

  useEffect(() => {
    getLoggedUser();
  }, []);

  useEffect(() => {
    getOplCertificate();
  }, []);

  const getUserDetails = () => {
    return JSON.parse(localStorage.getItem("userDetails"));
  };

  const handleCheckList = () => {
    setOpenCheckList(!openCheckList);
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const visibleRoutes = (route) => {
    console.log("VISIBLE ROUTE", route);
    if (regStatus && route.title == "OPL Application") {
      return { ...route, title: "", icon: "" };
    } else if (regStatus && route.title == "OPL Requirements") {
      return { ...route, title: "", icon: "" };
    } else if (!regStatus && route.title == "Nepza Services") {
      return { ...route, title: "", icon: "" };
    } else if (!regStatus && route.title == "Directors") {
      return { ...route, title: "", icon: "" };
    } else if (!regStatus && route.title == "View OPL Certificate") {
      return { ...route, title: "", icon: "" };
    } else  if(regStatus && route.title === "View Operating License") {
      return {...route, title: "", icon: ""}
    } else {
      return route;
    }
  };

  const drawer = () => (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <div style={{ marginBottom: "3rem" }}>
        <img src={logo} className={classes.logo} alt="logo" />
      </div>

      {/* <Divider style={{ marginTop: "18px" }} /> */}
      {/* <ListItem className={classes.item}>Main Menu</ListItem> */}
      {(getUserDetails()?.data?.company?.entity_type_id === 4
        ? zmcItems
        : getUserDetails()?.data?.company?.entity_type_id === 3
        ? fzcItems
        : getUserDetails()?.data?.company?.entity_type_id === 2
        ? enterpriseClass
        : partnerItems
      ).map((route, index) =>
        route.hidden ? (
          ""
        ) : (
          <>
            {visibleRoutes(route).icon && (
              <NavLink
                to={route.path}
                exact
                className={classes.menuLink}
                activeClassName={classes.menuActive}
                key={index}
              >
                <ListItem button className={classes.menuItem}>
                  <ListItemIcon>
                    {/* {<route.icon className={classes.menuIcon} />} */}

                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <img
                        src={route.icon}
                        alt="icon"
                        className={classes.icons}
                      />
                    </Box>
                  </ListItemIcon>
                  <ListItemText primary={route.title} />
                </ListItem>
              </NavLink>
            )}
          </>
        ),
      )}

      {/*Checklist tab starts here */}
      {getUserDetails()?.data?.company?.entity_type_id === 4 && regStatus && (
        <>
          <ListItem button onClick={handleClick}>
            <ListItemIcon>
              <GroupsIcon />
            </ListItemIcon>
            <ListItemText primary="My Enterprises" />
            {open ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <NavLink
                to="/me/EnterpriseDashboard"
                activeClassName={classes.menuActive}
                className={classes.menuLink}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Dashboard" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/me/EnterpriseApplication"
                activeClassName={classes.menuActive}
                className={classes.menuLink}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Applications" />
                </ListItem>
              </NavLink>
              <NavLink
                to="/me/zmc-zones"
                activeClassName={classes.menuActive}
                className={classes.menuLink}
              >
                <ListItem button className={classes.nested}>
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Enterprise Inventory" />
                </ListItem>
              </NavLink>
            </List>
          </Collapse>
        </>
      )}

      <Divider style={{ marginTop: "15rem" }} />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <img src={Logout} alt="logout" />
          </ListItemIcon>
          <ListItemText style={{ color: "red" }} primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {/* {getUser()?.data?.company?.name} */}
            <input
              placeholder="Search something here"
              className={classes.input}
            />
          </Typography>

          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            style={{ color: "gray", marginRight: "2px" }}
          >
            <NavLink to="/me/profile" exact>
              {profile?.data.profile_image ? (
                <img
                  src={profile?.data.profile_image}
                  alt="noimage"
                  className={classes.profileImage}
                />
              ) : (
                <AccountCircle />
              )}
            </NavLink>

            {/* <NavLink to="/me/profile" exact>
              <AccountCircle />
            </NavLink> */}
          </IconButton>

          <Typography variant="h6" style={{ color: "black", fontSize: "18px" }}>
            {/* {getUser()?.data?.fullname} */}
            {profile?.data?.fullname}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === "rtl" ? "right" : "left"}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer()}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer()}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />

        <Switch>
          {/* <Route exact path="/profile">
            <UserProfile />
          </Route> */}

          {routes.items.map((route, index) => (
            <Route exact path={route.path} key={index}>
              {<route.component />}
            </Route>
          ))}
          <Route path="/me/company-request-details/:rowId">
            <CompanyRequestDetials />
          </Route>
          <Route path="/me/zmc-action-submitted">
            <ZmcActionSubmitted />
          </Route>

          {/*Route the checklist tab here here */}
          <Route path="/me/checklist">
            <CheckList />
          </Route>
        </Switch>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
