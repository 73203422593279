import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppConfig } from "app-config";
import { Typography, Button } from "@material-ui/core";
import logo from "../../images/nepza-logo.jpg";
import arms from "../../images/coat-arms.png";
import PdfDownloader from "../../utils/PdfDownloader";
import nepza from "../../images/nepza-certificate.png";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const useStyles = makeStyles({
  mainContainer: {
    backgroundImage: `url(${nepza})`,
    minHeight: "200px",
    overflow: "auto",
    // backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: "900px",
    height: "1300px",
    position: "relative",
  },
  heading: {
    fontSize: "1.3rem",
    color: "#45B675",
    textAlign: "center",
    fontWeight: "bold",
    marginTop: "4rem",
  },
  download: {
    position: "absolute",
    right: "90%",
    top: "20px",
  },
  reg: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    // backgroundColor: "white",

    padding: "2rem",
    // background: `url(${logo})`,
  },
  title: {
    color: "green",
    fontWeight: "bold",
    fontSize: "2rem",
  },
  cert: {
    // color: "#000",
    // fontSize: "2rem",
    // marginTop: "9rem",
    // fontWeight: "bold",

    fontStyle: "italic",
    color: "#F88379",
    fontFamily: "Dancing Script",
    fontSize: "2rem",
    marginTop: "3rem",
    fontWeight: "bold",
  },
  delcare: {
    fontSize: "2rem",
    marginTop: "1rem",
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  details: {
    margin: "5rem",
    textAlign: "left",
    lineHeight: "30px",
    color: "black !important",
  },
  name: {
    fontSize: "1.5rem",
    fontWeight: "bold",
    marginTop: "3rem",
  },
  imageContainer: {
    marginBottom: "6rem",
    height: "150px",
  },
  image: {
    height: "100%",
  },
  date: {
    fontWeight: "bold",
    marginTop: "3rem",
  },
  signature: {
    textAlign: "center",
  },
});

const ViewCertificateOfRegistration = () => {
  const [cert, setCert] = useState({});

  const pdfRef = useRef();

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  let url = `${AppConfig.URL}/cert-of-reg/status`;

  const getCert = async () => {
    try {
      const response = await fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
      });

      const { status, message, data } = await response.json();
      if (data?.cert_of_reg === true) {
        setCert(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  //format date
  const formatDate = (date) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    if (date) {
      return new Date(date).toLocaleDateString(undefined, options);
    }
    return null;
  };

  //download function
  const downloadPDF = (e) => {
    e.preventDefault();
    const input = pdfRef.current;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = 30;
      pdf.addImage(
        imgData,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio,
      );
      pdf.save("certificate.pdf");
    });
  };

  useEffect(() => {
    getCert();
  }, []);

  const classes = useStyles();
  return (
    <>
      <Button onClick={downloadPDF} style={{ marginTop: "1rem", background: "#46BA77", color:"#fff" }}>
        Dowload Certificate
      </Button>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        ref={pdfRef}
      >
        <div className={classes.mainContainer}>
          {/* <PdfDownloader
          downloadFileName="CustomPdf" 
          rootElementId="doctId" 
        /> */}

          {/* <div className={classes.download}>
          <CloudDownloadIcon onClick={downloadPDF} />
        </div> */}

          <span style={{ position: "absolute", top: "200px", right: "120px" }}>
            NEPZA FORM RC/{cert?.certificate?.rcc_number}
          </span>

          <Typography className={classes.heading}>
            NIGERIA EXPORT PROCESSING ZONES AUTHORITY (NEPZA)
          </Typography>

          <div className={classes.reg} id="doctId">
            <div className={classes.imageContainer}>
              <img src={logo} alt="logo" className={classes.image} />
            </div>

            <Typography className={classes.cert}>
              Certificate of Registration
            </Typography>

            <Typography className={classes.delcare}>
              I hereby Certify that
            </Typography>

            <Typography className={classes.name}>
              {cert?.certificate?.bearer_corporate_name}
            </Typography>

            <Typography className={classes.details}>
              Is this day licensed under the NIGERIA EXPORT PROCESSING ZONES
              AUTHORITY Act 63 of 1992 as{" "}
              <strong>FZE in {cert?.zone?.zone_name}</strong> Subject to among
              other conditions, the mandatory requirment of resolving all
              disputes claims, or differences arising from, relating to or
              connected with the operation and management of all Zones or
              approved activities within the Zones under the NEPZA Arbitration
              Rules and Mediation Guidelines.
            </Typography>

            <Typography className={classes.date}>
              Given under my hand at Abuja this{" "}
              {formatDate(cert?.certificate?.date_approved)}
            </Typography>

            {/* <Typography>
            Date this{" "}
            <span style={{ textDecoration: "underline" }}>May 27th, 2023</span>
          </Typography> */}
            {/* <Typography>
            This License Expires on{" "}
            <span style={{ textDecoration: "underline" }}>May 27th, 2024</span>
          </Typography> */}
            {/* <Typography className={classes.signature}>
            Authorised Signature
          </Typography> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCertificateOfRegistration;
