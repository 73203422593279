import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig } from "app-config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
}));

const zoneType = [
  {
    type: "Private",
    id: 2,
  },
  {
    type: "Public",
    id: 1,
  },
];

const user = localStorage.getItem("userDetails");

// console.log("USERHER :", user);

// console.log("INITIALSTATEID:", initialState.zone_id);

const RequestForm = ({ changeStep, setRequest }) => {
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const { name, setName } = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [input, setInput] = "";
  const initialState = {
    proposedName: userData?.data?.last_success_search?.search,
    acronym: userData?.data?.company?.name,
    zone_id: userData?.data?.last_success_search?.company?.zone?.zone_id,
    state: "",
    address: "",
    zoneType:
      userData?.data?.last_success_search?.company?.zone?.zone_type?.zone_type,
    zmc_profile_id:
      userData?.data?.last_success_search?.company?.zone?.zmc_profile_id,
  };
  const [requestFormData, setRequestFormData] = useState(initialState);
  const [states, setStates] = useState(null);

  const classes = useStyles();

  // console.log("userData :", userData);
  console.log(requestFormData);

  const handleSubmit = (e) => {
    e.preventDefault();
    setRequest(requestFormData);
    changeStep();
    // console.log("YELLOW", requestFormData);
    sessionStorage.setItem("OPLRequestData", JSON.stringify(requestFormData));
    console.log("FORMDATA", requestFormData);
  };

  const getStates = async () => {
    try {
      const resp = await fetch(`${AppConfig.URL}/states/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const respData = await resp.json();
      console.log({ respData });
      setStates(respData.data);
    } catch (err) {
      console.log(err);
    }
  };
  // const handleChange = (e) => {
  //   setInput(e.target.value);
  //   console.log("INPUT: ", input);
  // };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setRequestFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getStates();
  }, []);
  console.log(userData?.data?.last_success_search?.company?.address);

  return (
    <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
      <form className={classes.root}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div>
            <div>
              <p style={{ paddingLeft: "20px" }}>Proposed Name</p>
              <TextField
                id="outlined-required"
                value={userData?.data?.last_success_search?.search}
                variant="outlined"
                style={{ margin: "20px", background: "#C4C4C4" }}
                disabled
                onChange={handleDataChange}
              />
            </div>

            <div>
              <p style={{ paddingLeft: "20px" }}>Enterprise Address</p>
              <TextField
                id="outlined-required"
                // value={userData?.data?.last_success_search?.company?.address}
                value={requestFormData.address}
                name="address"
                placeholder="Enter address...."
                variant="outlined"
                style={{ margin: "20px" }}
                multiline
                rows={5}
                rowsMax={10}
                onChange={handleDataChange}
                // disabled
              />
            </div>

            {/* <div>
          <TextField
            id="outlined-required"
            label="Address"
            value={userData.data.company.address}
            variant="outlined"
            style={{ margin: "20px" }}
            onChange={handleDataChange}
          />
          <TextField
            select
            label="Zones"
            id="outlined-required"
            variant="outlined"
            style={{ margin: "20px" }}
            name="zoneType"
            value={requestFormData.zone}
            onChange={handleDataChange}
          >
            {zoneType.map((zone, index) => (
              <MenuItem key={index} value={zone.id}>
                {zone.type}
              </MenuItem>
            ))}
          </TextField>
        </div> */}

            <div>
              <p style={{ paddingLeft: "20px" }}>State</p>
              <TextField
                select
                id="outlined-required"
                variant="outlined"
                style={{ margin: "20px" }}
                name="state"
                value={requestFormData.state}
                onChange={handleDataChange}
              >
                {states?.map((state) => (
                  <MenuItem key={state.state_id} value={state.state_id}>
                    {state.state_name}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>

        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#48BB78",
              padding: "1rem 12rem",
              color: "white",
              outline: "none",
              textTransform: "capitalize",
            }}
            onClick={handleSubmit}
          >
            Continue Application
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RequestForm;
