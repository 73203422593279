import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import Swal from "sweetalert2";
import { Stepper, Step, StepLabel, Button } from "@material-ui/core";
// import {AppConfig} from "../../app-config"
import UploadDocuments from "../UploadDocuments";
import Payment from "../InitiatePayment";
import Finish from "../Finish";
import Proccesses from "../Processes";
import CreateOplApplication from "./CreateOplApplication";
import OPLSpecialty from "./OPLSpecialty";
import OplPrincipalOfficers from "./OplPrincipalOfficers";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  Form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}));

export default function ZMCOplApplication() {
  const [steps, setSteps] = useState([
    "Start Application",
    "Select specialities",
    "Add Directors",
    "Upload FZR Form",
    "Make Payments",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [serviceId, setServiceId] = useState("");
  const [process, setProcess] = useState({ name: "" });
  const [request, setRequest] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [applicationId, setApplicationId] = useState("");
  const [category, setCategory] = useState({ name: "" });
  const user = localStorage.getItem("userDetails");
  const [loading, setLoading] = useState(false);

  const userData = JSON.parse(user);
  const classes = useStyles();
  // const threeStepsProcess = ['Zone OPL', 'Enterprise Registration & CTC of Documents', 'Zone OPL Renewal Fees', 'Registry Search', 'Change of Ownership', 'Change of Name of Enterprise']
  const threeStepsProcess = ["OPL Application"];

  useEffect(() => {
    if (threeStepsProcess.includes(category.name)) {
      setSteps(["Start Application", "Upload Documents", "Make Payment"]);
    }
  }, [category.name]);

  return (
    <div>
      <AppBar elevation={0} className={classes.appBar}>
        <Stepper
          className={classes.root}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </AppBar>

      <div className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          {activeStep === 0 && (
            <CreateOplApplication
              setApplicationId={setApplicationId}
              currentStep={setActiveStep}
              serviceId={serviceId}
              setServiceId={setServiceId}
              setProcess={setProcess}
              processCat={setCategory}
            />
            //   <OPLSpecialty
            //     setApplicationId={setApplicationId}
            //     currentStep={setActiveStep}
            //     serviceId={serviceId}
            //     setServiceId={setServiceId}
            //     setProcess={setProcess}
            //     setRequest={setRequest}
            //     reqBody={request}
            //     processCat={setCategory}
            //   />
            // <OplPrincipalOfficers
            //   setApplicationId={setApplicationId}
            //   currentStep={setActiveStep}
            //   serviceId={serviceId}
            //   setServiceId={setServiceId}
            //   setProcess={setProcess}
            //   processCat={setCategory}
            //   setRequest={setRequest}
            //   reqBody={request}
            // />
          )}
          {activeStep === 1 && (
            <Proccesses
              user={userData}
              applicationId={applicationId}
              currentStep={setActiveStep}
              setRequest={setRequest}
              process={process}
            />
          )}
          {activeStep === 2 && (
            <OplPrincipalOfficers
              applicationId={applicationId}
              process={process}
              serviceId={serviceId}
              setRequest={setRequest}
              reqBody={request}
              currentStep={setActiveStep}
            />
          )}
          {activeStep === 3 && (
            <UploadDocuments
              user={userData}
              applicationId={applicationId}
              process={process}
              serviceId={serviceId}
              reqBody={request}
              currentStep={setActiveStep}
              setPaymentData={setPaymentData}
            />
          )}
          {activeStep === 4 && process.name === "ZMC OPL" && (
            <Payment
              user={userData}
              applicationId={applicationId}
              serviceId={serviceId}
              reqBody={request}
              paymentData={paymentData}
            />
          )}
        </Paper>
      </div>
    </div>
  );
}
