import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import MuiAlert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppConfig } from "app-config";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "2rem auto",
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    heading: {
        textAlign: 'center',
        paddingBottom: 16
    },
    bold: {
        fontWeight: 'bold'
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function Zones() {
    const matches = useMediaQuery("(min-width:768px)");
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [modalType, setModalType] = React.useState("");
    const [alert, showAlert] = React.useState(false);
    const [zone, setZone] = React.useState({});
    const [zones, setZones] = React.useState([]);
    const [createModal, showCreateModal] = React.useState(false);

    const userDetails = localStorage.getItem('userDetails');

    const userData = JSON.parse(userDetails)

    const userPermission = localStorage.getItem("userPermission")

    React.useEffect(() => {
        getZones();
    }, []);

    const getZones = async () => {
        setLoading(true);

        try {
            const url = AppConfig.URL +
                "/zmc/zones";
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { data, message, status } = await response.json();
            if (status) {
                console.log(data)
                setZones(data);
                setLoading(false);
            } else {
                setZones([]);
                setLoading(false);
                Swal.fire('', message, 'info');
            }
        } catch (error) {
            setLoading(false);
            Swal.fire('Something went wrong', 'Unable to get zones', 'error');
        }
    };

    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert(false);
    };

    if (loading) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Typography variant="h3" className={classes.heading} color="textSecondary">My Zones</Typography>
            <Paper className="container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell className={classes.bold}>S/N</TableCell>
                            <TableCell className={classes.bold}>Name</TableCell>
                            <TableCell className={classes.bold}>Address</TableCell>
                            <TableCell className={classes.bold}>State</TableCell>
                            <TableCell className={classes.bold}>LGA</TableCell>
                            <TableCell className={classes.bold}>Promoter</TableCell>
                            {/* <TableCell className={classes.bold}>Action</TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {zones && zones.map((zone, index) => (
                            <TableRow key={zone.id}>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell >{zone.name}</TableCell>
                                <TableCell >{zone.address}</TableCell>
                                <TableCell >{zone.state}</TableCell>
                                <TableCell >{zone.lga}</TableCell>
                                <TableCell >{zone.promoter_name}</TableCell>
                                {/* <TableCell ><Button variant="outlined" color="primary">
                                    View</Button></TableCell> */}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </div>
    );
}



