import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Dialog } from "@material-ui/core";
import { AppConfig } from "../../app-config";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minWidth: 500,
    padding: "20px",
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  appBar: {
    position: "relative"
  },

}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewTeam({
  handleClose,
  open,
  getTeams,
  data,
  type,
  userData
}) {
  const [team, setTeam] = React.useState({
    firstname: "", surname: "", middlename: "", nationality: "", address: "", designation: ""
  });
  const [error, setError] = React.useState({
    firstname: "", surname: "", middlename: "", nationality: "", address: "", designation: ""
  });

  const [loading, setLoading] = React.useState(false);

  const [alert, showAlert] = React.useState(false);

  const classes = useStyles();
  const userDetails = localStorage.getItem('userDetails');
  const userToken = JSON.parse(userDetails)

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setTeam({
      ...team,
      [event.target.name]: event.target.value
    });
  };

  const handleSubmit = async () => {

    var body = {
      firstname: team.firstname ? team.firstname : userData.firstname,
      surname: team.surname ? team.surname : userData.surname,
      middlename: team.middlename ? team.middlename : userData.middlename,
      nationality: team.nationality ? team.nationality : userData.nationality,
      address: team.address ? team.address : userData.address,
      designation: team.designation ? team.designation : userData.designation,
      id: userData.id,

    }
    var userToken = localStorage.getItem('userDetails') ? JSON.parse(localStorage.getItem('userDetails')).access_token : null
    try {
      setLoading(true);
      const { status } = await fetch(`${AppConfig.URL}/zmc/team/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify(body)
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        setTeam({
          firstname: "", surname: "", middlename: "", nationality: "", address: "", designation: "", id: ""
        });
        showAlert(true);
        setTimeout(() => {
          handleClose();
          getTeams();
        }, 1000);
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }

    } catch (err) {
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  };

  const closeModal = () => {
    setTeam({
      firstname: "", surname: "", middlename: "", nationality: "", address: "", designation: "", id: ""
    });
    setError({
      firstname: "", surname: "", middlename: "", nationality: "", address: "", designation: ""
    });
    handleClose();
  };

  const updateTeam = () => {
    setTeam(data)
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showAlert(false);
  };

  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          "Director updated successfully!"
                </Alert>
      </Snackbar>
      <Dialog open={open} onClose={closeModal} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              View Director
                        </Typography>
            <IconButton
              edge="start"
              color="inherit"
              disabled={loading}
              onClick={closeModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Paper className={classes.root}>
            <div className={classes.tableWrapper}>
              <Table className={classes.table}>
                <TableBody>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>First Name</TableCell>
                    <TableCell align="right">{data.firstname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Surname</TableCell>
                    <TableCell align="right">{data.surname}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Nationality</TableCell>
                    <TableCell align="right">{data.nationality}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Year Appointed</TableCell>
                    <TableCell align="right">{data.year_appointed}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>DOB</TableCell>
                    <TableCell align="right">{data.dob}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell align="right">{data.status}</TableCell>
                  </TableRow>
                  <TableRow >
                    <TableCell align="left" style={{ fontWeight: "bold" }}>Image</TableCell>
                    {data.passport_url ? <TableCell align="right"> <img src={data.passport_url} width="200px" /></TableCell> : "No passport attached"
                    }
                  </TableRow>
                </TableBody>
                <TableFooter>
                  <TableRow>

                  </TableRow>
                </TableFooter>
              </Table>
            </div>
          </Paper>
        </div>

      </Dialog>
    </div>
  );
}
