import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from "@material-ui/core";
import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { AppConfig } from "app-config";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  button: {
    margin: "2rem",
    backgroundColor: "#68d391",
    color: "white",
    fontSize: "16px",
    width: "12rem",
    padding: "1rem",
    borderRadius: "8px",
  },
  dummyButton: {
    backgroundColor: "green",
    color: "white",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  buttonProgress: {
    // color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  initiatePayment: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "30px",
  },
  formContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
}));

const OplProcessesApplication = ({
  setServiceId,
  serviceId,
  currentStep,
  setApplicationId,
  setPaymentData,
  setProcess,
  applicationId,
  paymentData,
}) => {
  const [isSelected, setIsSelected] = useState(false);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [category, setCategory] = useState({});
  const [cat, setCat] = useState({});
  const [selectedSubCat, setSelectedSub] = useState({});
  const [moneyValue, setMoneyValue] = useState("");
  const [assetValue, setAssetValue] = useState("");
  const [alert, showAlert] = useState(false);
  const [serviceFee, setServiceFee] = useState("");
  const [serviceName, setServiceName] = useState("");
  // const [process, setProcess] = useState({ name: "" });
  // const [passedUserData, setPassedUserData] = useState(null);

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  console.log("Payment Data", paymentData);

  // console.log("Access Token", userData.access_token);

  //opl services
  const oplServices = [
    "Fabrication & Marine Operations",
    "Manufacturing/Packaging/Assembling",
    "Services (including Banking, Financial, Professional)",
    "Other Services",
    "Furniture and Fittings",
    "Building Material/Electrical.",
    "Construction",
    "Tourism/Entertainment",
    "Agric/Agro-Allied",
    "Real Estate",
  ];

  //ref
  const buttonRef = useRef(null);

  //select category
  const selectCategory = (e) => {
    setCategory(e.target.value);
    setServiceId(e.target.value);
    console.log("SERVICE_ID_VALUE", e.target.value);
    setIsSelected(true);
    const sub = categories.find((element) => element.id === e.target.value);
    // setFee(fee);
    console.log("SUB", sub);
    setSelectedSub(sub);
    setCat(sub);
    setProcess(sub);
  };

  const formatAsMoney = (fee) => {
    if (fee > 0)
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(fee);
    else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(0);
    }
  };

  //fetch OPL services
  const fetchOPL = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/start-reg/opl`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status) {
        setCategories(data.category.oplService);
        console.log(data.category.oplService);
        // setProcess(data.category.name);
        console.log(data.category);
        // setFee(data.category.oplService);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      if (!window.navigator.onLine) {
        Swal.fire("Internet", "Check your connection", "error");
      } else {
        Swal.fire("Something went wrong", "Unable to get record", "error");
      }
    }
  };

  const handleValueChange = (e) => {
    let value = e.target.value;
    const parsedValue = value.replace(/[^0-9]/g, "");
    setAssetValue(parsedValue);
    let toMoney = Number(parsedValue).toLocaleString("en-US");
    // console.log(toMoney);
    setMoneyValue(toMoney);
  };

  //ref button function
  const btnFunc = () => {
    buttonRef.current.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        buttons: 1,
      }),
    );
  };

  //submit application for payment
  const handleSubmit = async () => {
    try {
      setLoading(true);
      let info;
      let reqMethod = "POST";
      let url = AppConfig.URL + "/application/add";
      selectedSubCat.fee_type === "stages" ||
      selectedSubCat.fee_type === "fraction"
        ? (info = {
            company_id: userData["data"].company_id,
            service_id: serviceId,
            amount: assetValue,
          })
        : (info = {
            company_id: userData["data"].company_id,
            service_id: serviceId,
          });

      let fetchData = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },

        body: JSON.stringify(info),
      }).then((res) => res.json());
      console.log("fetchData", fetchData);
      setApplicationId(fetchData.data.id);
      console.log("Asset Fee", fetchData.data.fee);
      setServiceFee(fetchData.data.fee);
      setServiceName(fetchData.data.service.name);
      let currentApplicationId = fetchData.data.id;
      let submitUrl = AppConfig.URL + "/submit-reg/opl-application/enterprise";
      selectedSubCat.fee_type === "fixed"
        ? (info = {
            application_id: currentApplicationId,
            zone_id: userData["data"].last_success_search.company.zone.zone_id,
            isRenewal: 0,
            zmc_profile_id:
              userData["data"].last_success_search.company.zone.zmc_profile_id,
          })
        : (info = {
            application_id: currentApplicationId,
          });

      let submitData = await fetch(submitUrl, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },

        body: JSON.stringify(info),
      }).then((res) => res.json());

      if (submitData.status) {
        setLoading(false);
        showAlert(true);
        console.log("SUBMITDATA", submitData);
        setPaymentData(submitData);
        Swal.fire({
          title: "Submission was Received",
          text: `${submitData.message}  ${submitData.data.rrr}`,
          confirmButtonColor: "#48BB78",
          confirmButtonText: "Proceed to pay",
          iconHtml: `<img src='/check.png'/>`,
          showCancelButton: true,
          cancelButtonText: "",
        }).then((res) => (res.isConfirmed ? btnFunc() : null));
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOPL();
  }, []);

  const classes = useStyles();

  return (
    <>
      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ marginBottom: "1rem" }}>Enterprise Speciality</p>
              <TextField
                id="outlined-required"
                select
                variant="outlined"
                name="serviceId"
                value={category}
                style={{ width: "400px", marginRight: "20px" }}
                onChange={(e) => selectCategory(e)}
              >
                {categories.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </div>

            <div>
              <p style={{ marginBottom: "1rem" }}>Application Fee</p>
              <TextField
                id="outlined-required"
                variant="outlined"
                value={
                  selectedSubCat.fee_type === "stages" ||
                  selectedSubCat.fee_type === "fraction"
                    ? "$ " + moneyValue
                    : formatAsMoney(
                        selectedSubCat.actual_fee
                          ? selectedSubCat.actual_fee
                          : selectedSubCat.actual_fee,
                      )
                }
                onChange={handleValueChange}
                style={{
                  width: "400px",
                  marginRight: "20px",
                  background: "#F5F4F4",
                }}
                disabled
              />
            </div>
            {localStorage.setItem("payBefore", selectedSubCat.payBefore)}
            {localStorage.setItem("name", selectedSubCat.name)}
            {localStorage.setItem(
              "processing_fee",
              selectedSubCat.processing_fee,
            )}
            {localStorage.setItem("actual_fee", selectedSubCat.actual_fee)}
          </div>
          {isSelected && (
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: "#47BB78",
                  padding: "1rem 12rem",
                  color: "white",
                  outline: "none",
                  marginTop: "3rem",
                  // textTransform: "capitalize",
                }}
                onClick={handleSubmit}
              >
                Submit for Payment
              </Button>
            </div>
          )}
          {loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </form>

        <form
          enctype="multipart/form-data"
          method="post"
          name="SubmitRemitaForm"
          // action={`https://remitademo.net/remita/onepage/api/v1/payment.spa`}
          action={`${paymentData?.data?.action}/finalize.reg`}
          className={classes.formContainer}
          target="_blank"
        >
          <input
            type="hidden"
            value={`${paymentData?.data?.merchantId}`}
            name="merchantId"
          />
          <input
            type="hidden"
            value={`${paymentData?.data?.hash}`}
            name="hash"
          />
          <input type="hidden" value={`${paymentData?.data?.rrr}`} name="rrr" />
          {/* <input type="hidden" value="https://nepzaopms.com/finalize" name="responseurl" /> */}
          <input
            type="hidden"
            value={`${window.location.origin}/finalize?amount=${serviceFee}&name=${serviceName}`}
            name="responseurl"
          />
          <input
            type="submit"
            className={classes.button}
            value="Pay Now"
            name="submit_btn"
            style={{ display: "none" }}
            ref={buttonRef}
          />
        </form>
      </div>
    </>
  );
};

export default OplProcessesApplication;
