import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TableFooter,
  TablePagination,
  Button,
  Box,
  Typography,
} from "@material-ui/core";
import ActionButton from "./ActionButtons";
import LastPageIcon from "@material-ui/icons/LastPage";
import FirstPageIcon from "@material-ui/icons/LastPage";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { AppConfig } from "app-config";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { Pagination } from "@material-ui/lab";

//get date
const now = new Date();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableCell: {
    color: "#B0AEAE",
  },
  editButton: {
    border: "1px solid #47BB78",
    marginRight: "5px",
    textTransform: "capitalize",
  },
  deleteButton: {
    border: "1px solid #EB4B4B",
    background: "#EB4B4B",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#EB4B4B",
      color: "white",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  paginated: {
    backgroundColor: "white",
    marginTop: "15px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingInline: "60px",
    paddingBlock: "18px",
  },
});

function createData(name, amount, remarks, date, actions) {
  return { name, amount, remarks, date, actions };
}

const rows = [
  createData(
    "OPL Registration",
    "$50,000.00",
    "Awaiting zone feedback",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Cerificate of registration",
    "$750.00",
    "Awaiting zone feedback",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Import & export charges",
    "$200,000.00",
    "Awaiting zone feedback",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Export charges only",
    "$50,000.00",
    "Awaiting zone feedback",
    "05/07/2022",
    <ActionButton />,
  ),
  createData(
    "Import charges only",
    "$150,000.00",
    "Awaiting zone feedback",
    "05/07/2022",
    <ActionButton />,
  ),
];

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onChangePage } = props;

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <div className={classes.root}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const PendingRequestsTab = ({ data, status, message }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [statusColor, setStatusColor] = React.useState("#47BB78");
  const [statusBackground, setStatusBackground] = React.useState(
    "rgba(71, 187, 120, 0.3)",
  );
  const [loading, setLoading] = useState(false);
  const [paginated, setPaginated] = useState(false);

  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  //format as money code
  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  //get pending requests from endpoint
  const getPendingRequests = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/inventory`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status === true) {
        console.log("DATA IS HERE", data);
        setLoading(false);
        setLastPage(data.last_page);
        let newData = data?.data?.map((info) => {
          return info;
        });
        console.log("NEW DATA", newData);
        let pendingData = newData?.filter((data) => data.status === "pending");
        setPendingRequests(pendingData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getPendingRequests();
  }, [page]);

  return (
    <>
      {loading && (
        <div className={classes.spinner}>
          <CircularProgress />
        </div>
      )}
      {pendingRequests.length > 0 ? (
        <TableContainer component={Paper} elevation={0}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell}>
                  Application type
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  Amount
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Status
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  Date of request
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {pendingRequests?.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell component="th" scope="row">
                    {row?.service?.name}
                  </TableCell>
                  <TableCell align="left">
                    {formatAsMoney(row?.service?.actual_fee)}
                  </TableCell>
                  <TableCell align="right">
                    <Typography
                      style={{
                        color:
                          row?.status === "approved"
                            ? "#47BB78"
                            : row?.status === "pending"
                            ? "#FFD300"
                            : "red",
                        textAlign: "center",
                        fontSize: "13px",
                        borderRadius: "20px",
                        padding: "5px 10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {row?.status}
                    </Typography>
                  </TableCell>
                  <TableCell align="center">
                    {moment(row?.application_date).format("M/D/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>No Requests</Typography>
      )}
      <Box className={classes.paginated}>
        <Button color="default" className={classes.btn}>
          <span style={{ marginRight: "5px" }}>
            <ArrowBackIcon />
          </span>
          Previous
        </Button>
        <Pagination
          component="Box"
          count={lastPage}
          onChange={handleChangePage}
          page={page}
          classes={{ ul: classes.ul }}
          color="standard"
        />
        <Button color="default" className={classes.btn}>
          Next
          <span>
            <ArrowForwardIcon />
          </span>
        </Button>
      </Box>
    </>
  );
};

export default PendingRequestsTab;
