import {TextField} from "@material-ui/core"
import {makeStyles} from "@material-ui/core/styles"
import React, {useState, useEffect} from "react"
import AddSpecialty from "./AddSpecialty"

const useStyles = makeStyles(theme => ({
  container: {
    margin: "2em 0",
    height: "100%",
    display: "flex",
    alignItems: "start",
    width: "100%",
    background: "rgba(250, 250, 251, 1)",
  },
  wrapper: {
    width: "50%",
    background: "#fff",
    margin: "2em",
    padding: "0 2em",
  },
  formFields: {
    padding: "1em 0",
  },
  title: {
    margin: "10px 0",
    fontSize: "16px",
  },
  btn: {
    margin: "1em 0",
    width: "100%",
    padding: "1em 0",
    color: "rgba(71, 187, 120, 1)",
    background: "rgba(71, 187, 120, 0.05)",
    border: "1px dashed rgba(71, 187, 120,1)",
    borderRadius: "5px",
    outline: "none",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0",
  },
  btnCancel: {
    background: "rgba(231, 46, 46, 1)",
    padding: "1em 4em",
    borderRadius: "5px",
    color: "#fff",
  },
  btnContinue: {
    background: "rgba(71, 187, 120, 1)",
    padding: "1em 4em",
    borderRadius: "5px",
    color: "#fff",
  },
}))
const OPLSpecialty = ({currentStep, setRequest, reqBody}) => {
  const classes = useStyles()

  const [specialties, setSpecialties] = useState([])
  const [open, setOpen] = useState(false)
  const handleAddSpecialties = () => {
    setOpen(!open)
  }

  const handleSubmit = () => {
    setRequest({specialties})
    currentStep(prevActiveStep => prevActiveStep + 1)
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          {specialties.map((item, i) => (
            <div className={classes.formFields}>
              <p className={classes.title}>Enterprise specialty {i + 1}</p>
              <TextField
                type="text"
                value={item}
                variant="outlined"
                fullWidth
                // onChange={e => handleChange(e, i)}
              />
            </div>
          ))}
          <button className={classes.btn} onClick={handleAddSpecialties}>
            + Add {specialties.length > 0 ? "More Specialties " : "Specialty"}
          </button>
          <div className={classes.btnGroup}>
            <button className={classes.btnCancel}>Cancel</button>
            <button className={classes.btnContinue} onClick={handleSubmit}>
              Continue
            </button>
          </div>
        </div>
      </div>
      <AddSpecialty
        open={open}
        handleClose={() => setOpen(false)}
        setSpecialties={setSpecialties}
        specialties={specialties}
      />
    </>
  )
}

export default OPLSpecialty
