import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import MuiAlert from "@material-ui/lab/Alert"
import Paper from "@material-ui/core/Paper"
import Swal from "sweetalert2"
import {Stepper, Step, StepLabel, Button} from "@material-ui/core"
// import {AppConfig} from "../../app-config"
import UploadDocuments from "../../UploadDocuments"
import CertificateOfApplication from "./CertificateOfApplication"
import Payment from "../../InitiatePayment"
import Finish from "../../Finish"
// import SelectZmc from "./SelectZmc"
import Proccesses from "../../Processes"
import {ClearAll} from "@material-ui/icons"
import ZMCCreateApplication from "../../ZmcDashboard/ZMCCreateApplication"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  Form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}))

export default function StartCertificateApplication() {
  const [steps, setSteps] = useState([
    "Start Application",
    "Fill Request Form",
    "Upload Documents",
    "Make Payments",
  ])
  const [activeStep, setActiveStep] = useState(0)
  const [serviceId, setServiceId] = useState("")
  const [process, setProcess] = useState({name: ""})
  const [request, setRequest] = useState({})
  const [paymentData, setPaymentData] = useState({})
  const [applicationId, setApplicationId] = useState("")
  const [category, setCategory] = useState({name: ""})
  const user = localStorage.getItem("userDetails")
  const [loading, setLoading] = useState(false)

  const userData = JSON.parse(user)
  const classes = useStyles()
  // const threeStepsProcess = ['Zone OPL', 'Enterprise Registration & CTC of Documents', 'Zone OPL Renewal Fees', 'Registry Search', 'Change of Ownership', 'Change of Name of Enterprise']
  const threeStepsProcess = [
    // 'Operating License (OPL)',
    "Registration of Instrument",
    "Registration of Asset Valuation",
    "Increase in Share Capital",
    "Certified True Copies",
    "Alteration of Memorandum and Articles of Association",
    "Change of Directors",
    "Change of Objects",
    "Filing of Annual Returns",
    "Mortgage", // Looks like it doesn't have documents
    "Replacement of Lost OPL",
  ]

  const handleReset = () => {
    setActiveStep(0)
    setSteps([
      "Start Application",
      "Fill Request Form",
      "Upload Documents",
      "Make Payment",
    ])
    window.location.reload()
  }

  useEffect(() => {
    if (threeStepsProcess.includes(category.name)) {
      setSteps(["Start Application", "Upload Documents", "Make Payment"])
    }
  }, [category.name])

  useEffect(() => {
    const pay = localStorage.getItem("payBefore")
    const name = localStorage.getItem("name")

    if (threeStepsProcess.includes(category.name)) {
      if (
        pay === "1" &&
        name !== "Equitable" &&
        name !== "Registry Search" &&
        name !== "Legal"
      ) {
        setSteps(["Start Application", "Upload Documents", "Make Payment"])
      } else if (
        pay === "0" &&
        name !== "Equitable" &&
        name !== "Registry Search" &&
        name !== "Legal"
      ) {
        setSteps(["Start Application", "Upload Documents", "Finish"])
      } else if (
        pay === "1" &&
        (name === "Equitable" || name === "Registry Search" || name === "Legal")
      ) {
        setSteps(["Start Application", "Make Payment"])
      } else if (
        pay === "0" &&
        (name === "Equitable" || name === "Registry Search" || name === "Legal")
      ) {
        setSteps(["Start Application", "Finish"])
      }
    } else {
      if (pay === "1") {
        if (name === "Zone OPL" || name === "Zone OPL Renewal Fees") {
          setSteps([
            "Start Application",
            "Select Zone and ZMC",
            "Upload Documents",
            "Make Payment",
          ])
        } else if (name === "Registry Search") {
          setSteps(["Start Application", "Make Payment"])
        } else {
          setSteps([
            "Start Application",
            "Fill Request Form",
            "Upload Documents",
            "Make Payment",
          ])
        }
      } else if (pay === "0") {
        if (name === "Free Zone" || name === "Industrial Park") {
          setSteps([
            "Start Application",
            "Fill Request Form",
            "Upload Documents",
            "Make Payment",
          ])
        } else {
          setSteps([
            "Start Application",
            "Fill Request Form",
            "Upload Documents",
            "Finish",
          ])
        }
      }
    }
  }, [activeStep])
  console.log(category)
  return (
    <div>
      <AppBar elevation={0} className={classes.appBar}>
        {/* <Toolbar> */}
        {/* <Button
            style={{fontWeight: 700, color: "#ffffff"}}
            startIcon={<ClearAll />}
            onClick={handleReset}
          >
            Reset
          </Button> */}
        {/* {activeStep > 0 ? ( */}
        <Stepper
          className={classes.root}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {/* ) : null} */}
        {/* </Toolbar> */}
      </AppBar>
      {threeStepsProcess.includes(category.name) ? (
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            {activeStep === 0 && (
              // <ZMCCreateApplication
              // setApplicationId={setApplicationId}
              // currentStep={setActiveStep}
              // serviceId={serviceId}
              // setServiceId={setServiceId}
              // setProcess={setProcess}
              // processCat={setCategory}
              // />
              <CertificateOfApplication
                setApplicationId={setApplicationId}
                currentStep={setActiveStep}
                serviceId={serviceId}
                setServiceId={setServiceId}
                setProcess={setProcess}
                processCat={setCategory}
              />
            )}
            {activeStep === 1 && (
              <UploadDocuments
                user={userData}
                process={process}
                applicationId={applicationId}
                serviceId={serviceId}
                reqBody={request}
                currentStep={setActiveStep}
                setPaymentData={setPaymentData}
              />
            )}
            {activeStep === 2 && localStorage.getItem("payBefore") === "1" ? (
              <Payment
                user={userData}
                applicationId={applicationId}
                serviceId={serviceId}
                reqBody={request}
                paymentData={paymentData}
              />
            ) : (
              activeStep === 2 && <Finish />
            )}
          </Paper>
        </div>
      ) : (
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            {activeStep === 0 && (
              //   <ZMCCreateApplication
              //     setApplicationId={setApplicationId}
              //     currentStep={setActiveStep}
              //     serviceId={serviceId}
              //     setServiceId={setServiceId}
              //     setProcess={setProcess}
              //     processCat={setCategory}
              //   />
              <CertificateOfApplication
                setApplicationId={setApplicationId}
                currentStep={setActiveStep}
                serviceId={serviceId}
                setServiceId={setServiceId}
                setProcess={setProcess}
                processCat={setCategory}
              />
            )}
            {activeStep === 1 && (
              <Proccesses
                user={userData}
                applicationId={applicationId}
                currentStep={setActiveStep}
                setRequest={setRequest}
                process={process}
              />
            )}
            {activeStep === 2 && (
              <UploadDocuments
                user={userData}
                applicationId={applicationId}
                process={process}
                serviceId={serviceId}
                reqBody={request}
                currentStep={setActiveStep}
                setPaymentData={setPaymentData}
              />
            )}
            {activeStep === 3 &&
            (localStorage.getItem("payBefore") === "1" ||
              process.name === "Certificate of Registration" ||
              localStorage.getItem("name") === "Free Zone" ||
              localStorage.getItem("name") === "Industrial Park") ? (
              <Payment
                user={userData}
                applicationId={applicationId}
                serviceId={serviceId}
                reqBody={request}
                paymentData={paymentData}
              />
            ) : (
              activeStep === 3 && <Finish />
            )}
          </Paper>
        </div>
      )}
    </div>
  )
}
