import React, { useState } from "react";
import { Button, TextField, Typography } from "@material-ui/core";
import axios from "axios";
import Swal from "sweetalert2";
import { AppConfig } from "app-config";
import { useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

const EnterpriseRegistration = ({
  setApplicationId,
  setProcess,
  currentStep,
  setServiceId,
  serviceId,
}) => {
  const [applicationInfo, setApplicationInfo] = useState("");
  const [applicationFee, setApplicationFee] = useState(null);
  const [documentType, setDocumentType] = useState([]);
  const [passedData, setPassedData] = useState("");
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [categories, setCategories] = useState([]);

  //get user
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  const getCertificateInformation = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/start-reg/cert-of-reg`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const { status, message, data } = await response.json();
      if (status) {
        setApplicationInfo(data.service.name);
        setProcess(data.service);
        setApplicationFee(data.service.actual_fee);
        setDocumentType(data.service.documents);
        console.log("RESPONSEDATA!!!", data);
        setServiceId(data.service.id);
        console.log("DOCUMENTS", data.service.documents);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (err) {
      console.log(err);
    }
  };

  localStorage.setItem("documentsName", JSON.stringify(documentType));

  console.log("DOCUMENT TYPE", documentType);

  useEffect(() => {
    console.log("SERVICEIDDD!!", serviceId);
    getCertificateInformation();
  }, []);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let info;
      let reqMethod = "POST";
      let url = AppConfig.URL + "/application/add";
      info = { company_id: userData["data"].company_id, service_id: serviceId };

      const { status, data } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
        // body: JSON.stringify({
        //   company_id: userData['data'].company_id,
        //   service_id: serviceId,
        //   amount: assetValue
        // })
        body: JSON.stringify(info),
        // body: JSON.stringify({company_id: userData['data'].company_id, service_id: subCategory})
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        setApplicationId(data.id);
        // currentStep((prevActiveStep) => prevActiveStep + 1);
        currentStep((prevActiveStep) => {
          if (
            localStorage.getItem("name") === "Equitable" ||
            localStorage.getItem("name") === "Legal"
          ) {
            return prevActiveStep + 2;
          } else if (localStorage.getItem("name") === "Registry Search") {
            return prevActiveStep + 3;
          } else {
            return prevActiveStep + 1;
          }
        });
      } else if (!window.navigator.online) {
        Swal.fire("Internet", "Check your connection", "error");
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
        <form>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ marginBottom: "1rem" }}>Application Type</p>
              <TextField
                id="outlined-required"
                variant="outlined"
                value={applicationInfo}
                style={{ width: "400px", marginRight: "20px" }}
                disabled
              />
            </div>

            <div>
              <p style={{ marginBottom: "1rem" }}>Application Fee</p>
              <TextField
                id="outlined-required"
                variant="outlined"
                value={formatAsMoney(applicationFee)}
                style={{
                  width: "400px",
                  marginRight: "20px",
                  background: "#F5F4F4",
                }}
                disabled
              />
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              onClick={handleSubmit}
              variant="contained"
              style={{
                backgroundColor: "#47BB78",
                padding: "1rem 12rem",
                color: "white",
                outline: "none",
                marginTop: "3rem",
                textTransform: "capitalize",
              }}
            >
              Start Application
            </Button>
          </div>
          {loading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2em",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </form>
      </div>
    </>
  );
};

export default EnterpriseRegistration;
