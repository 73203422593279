import React, {useState, useEffect} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Swal from "sweetalert2"
import {Stepper, Step, StepLabel, MenuItem} from "@material-ui/core"
import {AppConfig} from "../../app-config"
// import ZoneDeclaration from "./ZoneDeclaration"
// import UploadDocuments from "./UploadDocuments"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
    textTransform: "capitalize",
    width: "400px",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}))

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CreateOplApplication({
  setApplicationId,
  processCat,
  setProcess,
  currentStep,
  setServiceId,
  serviceId,
}) {
  const [loading, setLoading] = useState(false)
  const [alert, showAlert] = useState(false)
  const [categories, setCategories] = useState([])
  const [cat, setCat] = useState({})
  const user = localStorage.getItem("userDetails")
  const [certificate, setCertificate] = useState({})

  const userData = JSON.parse(user)
  const classes = useStyles()
  console.log(userData)
  useEffect(() => {
    getService()
  }, [])

  const formatAsMoney = fee => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee)
  }

  const getService = async () => {
    setLoading(true)

    try {
      const response = await fetch(`${AppConfig.URL}/start-reg/zmc-opl`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      })
      const {status, data, message} = await response.json()

      if (status === true) {
        setCertificate(data)
        setServiceId(data?.service?.id)
        setProcess(data?.service)
      } else {
        Swal.fire("", message, "info")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (!window.navigator.onLine) {
        Swal.fire("Internet", "Check your connection", "error")
      } else {
        Swal.fire("Something went wrong", "Unable to get record", "error")
      }
    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let info
      let reqMethod = "POST"
      let url = AppConfig.URL + "/application/add"
      info = {
        company_id: userData["data"].company_id,
        service_id: serviceId,
      }

      const {status, data} = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
        // body: JSON.stringify({
        //   company_id: userData['data'].company_id,
        //   service_id: serviceId,
        //   amount: assetValue
        // })
        body: JSON.stringify(info),
        // body: JSON.stringify({company_id: userData['data'].company_id, service_id: subCategory})
      }).then(res => res.json())
      if (status) {
        setLoading(false)
        setApplicationId(data.id)
        showAlert(true)
        // currentStep((prevActiveStep) => prevActiveStep + 1);
        currentStep(prevActiveStep => {
          if (
            localStorage.getItem("name") === "Equitable" ||
            localStorage.getItem("name") === "Legal"
          ) {
            return prevActiveStep + 2
          } else if (localStorage.getItem("name") === "Registry Search") {
            return prevActiveStep + 3
          } else if (cat?.name === "Operating License (OPL)") {
            return prevActiveStep + 3
          } else {
            return prevActiveStep + 1
          }
        })
      } else if (!window.navigator.online) {
        Swal.fire("Internet", "Check your connection", "error")
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info")
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      Swal.fire("Something went wrong", "Unable to process request", "info")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") return
    showAlert(false)
  }

  //   const handleValueChange = e => {
  //     let value = e.target.value
  //     const parsedValue = value.replace(/[^0-9]/g, "")
  //     setAssetValue(parsedValue)
  //     let toMoney = new Number(parsedValue).toLocaleString("en-US")
  //     setMoneyValue(toMoney)
  //   }

  console.log(cat)

  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{vertical: "bottom", horizontal: "right"}}
      >
        <Alert onClose={closeAlert} severity="success">
          Application Created Successfully!
        </Alert>
      </Snackbar>

      <div>
        <form className={classes.form}>
          <div className={classes.textfields}>
            <TextField
              id="outlined-error-helper-text"
              className={classes.select}
              variant="outlined"
              label="Application Type"
              name="certificate"
              value={"ZMC OPL"}
              //   onChange={e => setCertificate(e.target.value)}
              disabled
            />
            <TextField
              id="outlined-error-helper-text"
              className={classes.select}
              variant="outlined"
              // label="Application Fee"
              name="serviceId"
              value={formatAsMoney(certificate?.service?.actual_fee)}
              disabled
            />
          </div>

          <Button
            // autoFocus
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            size="large"
            disabled={loading}
            elevation={0}
          >
            Start Application
          </Button>
        </form>

        {loading && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}
