import React, { useState, useEffect } from "react";
import { TextField, Button, MenuItem } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import axiosInstance from "../Api/Api.js";
import { AppConfig } from "../app-config";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "50ch",
    },
  },
}));

let url = `${AppConfig.URL}/state/list`;

const zoneType = [
  {
    type: "Private",
    id: 2,
  },
  {
    type: "Public",
    id: 1,
  },
];

const states = [
  "FCT",
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Bornu",
  "Cross River",
  "Delta",
  "Edo",
  "Ekiti",
  "Enugu",
  "Ebonyi",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nassarawa",
  "Niger",
  "Ogun",
  "Osun",
  "Oyo",
  "Ondo",
  "Plateau",
  "River",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
];

const user = localStorage.getItem("userDetails");

// console.log("USERHER :", user);

const userData = JSON.parse(user);

const initialState = {
  proposedName: userData?.data?.last_success_search?.search,
  acronym: userData?.data?.company?.name,
  zone_id: userData?.data?.last_success_search?.company?.zone?.zone_id,
  state: "",
  address: userData?.data?.company?.address,
  zoneType:
    userData?.data?.last_success_search?.company?.zone?.zone_type?.zone_type,
  zmc_profile_id:
    userData?.data?.last_success_search?.company?.zone?.zmc_profile_id,
};

// console.log("INITIALSTATEID:", initialState.zone_id);

const RequestForm = ({ changeStep }) => {
  const { name, setName } = useState("");
  const [specialties, setSpecialties] = useState([]);
  const [input, setInput] = "";
  const [requestFormData, setRequestFormData] = useState(initialState);
  const [states, setStates] = useState(null);

  const classes = useStyles();

  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);

  // console.log("userData :", userData);

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("submit");
    changeStep();
    // console.log("YELLOW", requestFormData);
    sessionStorage.setItem("OPLRequestData", JSON.stringify(requestFormData));
    console.log("FORMDATA", requestFormData);
  };

  const getStates = async () => {
    try {
      const resp = await fetch(`${AppConfig.URL}/states/list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData?.access_token}`,
        },
      });
      const respData = await resp.json();
      console.log({ respData });
      setStates(respData.data);
    } catch (err) {
      console.log(err);
    }
  };
  // const handleChange = (e) => {
  //   setInput(e.target.value);
  //   console.log("INPUT: ", input);
  // };

  const handleDataChange = (e) => {
    const { name, value } = e.target;
    setRequestFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    getStates();
  }, []);

  return (
    <div style={{ marginTop: "3rem", marginBottom: "3rem" }}>
      <form className={classes.root}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-required"
            label="Name"
            value={userData.data.last_success_search.search}
            variant="outlined"
            style={{ margin: "20px" }}
            disabled
            onChange={handleDataChange}
          />
          <TextField
            id="outlined-required"
            label="Email"
            value={userData?.data?.company?.email}
            variant="outlined"
            style={{ margin: "20px" }}
            onChange={handleDataChange}
            disabled
          />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TextField
            id="outlined-required"
            label="Address"
            value={userData.data.company.address}
            variant="outlined"
            style={{ margin: "20px" }}
            onChange={handleDataChange}
          />
          <TextField
            select
            label="Zones"
            id="outlined-required"
            variant="outlined"
            style={{ margin: "20px" }}
            name="zoneType"
            value={requestFormData.zone}
            onChange={handleDataChange}
          >
            {zoneType.map((zone, index) => (
              <MenuItem key={index} value={zone.id}>
                {zone.type}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* <TextField
            id="outlined-required"
            label="Speciality"
            variant="outlined"
            style={{ margin: "20px" }}
          >
           
          </TextField> */}
          <TextField
            select
            label="State"
            id="outlined-required"
            variant="outlined"
            style={{ margin: "20px" }}
            name="state"
            value={requestFormData.state}
            onChange={handleDataChange}
          >
            {states?.map((state) => (
              <MenuItem key={state.state_id} value={state.state_id}>
                {state.state_name}
              </MenuItem>
            ))}
          </TextField>
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#48BB78",
              padding: "1rem 19.5rem",
              color: "white",
              outline: "none",
            }}
            onClick={handleSubmit}
          >
            Continue Application
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RequestForm;
