import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Dialog, MenuItem, Grid } from "@material-ui/core";
import { AppConfig } from "../app-config";
import axiosInstance from "../Api/Api.js";


const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative"
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    container: {
        display: "flex",
        height: "100%",
        alignItems: "center",
        justifyContent: "center"
    },
    paper: {
        minHeight: "45vh",
        height: "auto",
        width: "50%",
        minWidth: "300px"
        // verticalAlign: "middle",
    },
    form: {
        width: "100%",
        display: "flex",
        height: "50px",
        alignItems: "center",
        justifyContent: "center",
        padding: "8rem 0",
        flexDirection: "column",
        margin: '8rem 0'
    },
    text: {
        width: "40%",
        marginBottom: "1.4rem",
        marginRight: 8
    },
    btnContainer: {
        textAlign: 'center'
    },
    button: {
        marginTop: "1rem",
    },
    inline: {
        marginLeft: "1rem"
    },
    large: {
        width: theme.spacing(12),
        height: theme.spacing(12),
        marginRight: "1rem"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "1rem",
        height: "100%"
    },
    parent: {
        padding: 16
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ZoneDeclaration({
    user, applicationId, currentStep, setRequest
}) {
    const [ApplicationZones, setApplicationZones] = useState({
        proposed_name: "", state: "", address: "", lga: "", zone_type_id: "", specialty: ""
    });
    const [error, setError] = useState({
        proposed_name: "", state: "", lga: "", address: "", zone_type_id: "", specialty: ""
    });

    const [loading, setLoading] = useState(false);
    const [selected, isSelected] = useState(false);
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState('');
    const [lgaList, setLgaList] = useState([])
    const [lga, setLga] = useState('')
    const [selectedSubCat, setSelectedLga] = useState({})
    const [alert, showAlert] = useState(false);
    const [specialties, setSpecialties] = useState([]);

    const classes = useStyles();

    const handleChange = (event) => {
        setError({ ...error, [event.target.name]: "" });
        setApplicationZones({
            ...ApplicationZones,
            [event.target.name]: event.target.value
        });
    };

    const selectSubCategory = (e) => {
        setLga(e.target.value);
        const sub = lgaList.find(element => element.id === e.target.value);
        setSelectedLga(sub)
    };

    const handleSubmit = async () => {
        const { proposed_name, short_name, address, zone_type_id, specialty } = ApplicationZones;

        if (!proposed_name) setError({ ...error, proposed_name: "Please provide a Proposed name" });
        if (!short_name) setError({ ...error, proposed_name: "Please provide a short name" });
        if (!address) setError({ ...error, proposed_name: "Please provide an address" });
        if (!zone_type_id) setError({ ...error, proposed_name: "Please provide a zone type" });
        if (!stateId) setError({ ...error, state: "Please provide a State" });
        if (!lga) setError({ ...error, lga: "Please provide a Lga" });
        if (!specialty) setError({ ...error, specialty: "Please provide a Specialty" });


        const body = {
            application_id: applicationId,
            company_id: user['data'].company_id,
            state: stateId,
            proposed_name,
            short_name,
            address,
            zone_type_id,
            specialty,
            lga,
        };

        setRequest(body);

        setApplicationZones({
            proposed_name: "", short_name: "", address: "", state: "", lga: "", zone_type_id: "", specialty: "", application_zonescol: "", applicaiton_id: "", user_id: ""
        });
        currentStep((prevActiveStep) => prevActiveStep + 1);

        showAlert(true);


    };

    const getSpecialties = () => {
        console.log('first')
        setLoading(true)
        axiosInstance
            .get("/specialty/list")
            .then(function (response) {
                if (response.status === 200) {
                    setSpecialties(response.data.data.data);
                } else {
                    console.log(response);
                }
            })
            .catch(function (error) {
                console.log('error',error)
                // console.log(error.response.data.message);
            })
            .finally(() => setLoading(false));
    }

    const selectState = async (e) => {
        setLoading(true);
        isSelected(true)

        setStateId(e.target.value);
        try {
            const response = await fetch(`${AppConfig.URL}/states/get/${e.target.value}`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setLgaList(data)
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to load local government areas', 'error');
            setLoading(false)
        }

    }
    const getStates = async () => {
        setLoading(true)
        try {
            const response = await fetch(`${AppConfig.URL}/states/list`, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${user.access_token}`
                }
            });
            const { status, data, message } = await response.json();
            if (status === true) {
                setStates(data);
            } else {
                Swal.fire('', message, 'info');
            }
            setLoading(false)
        } catch (error) {
            Swal.fire('Something went wrong', 'Unable to load states', 'error');
            setLoading(false)
        }
    };

    React.useEffect(() => {
        getSpecialties();
        getStates()
    }, []);


    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert(false);
    };

    return (
        <div>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    Zone declared successfully!
                </Alert>
            </Snackbar>

            <div>
                {/* <form className={classes.form} noValidate autoComplete="off"> */}
                <form noValidate autoComplete="off">
                    {/* <Typography variant="h6" className={classes.title}>
                        Zone Declaration
                    </Typography> */}
                    <Grid container className={classes.parent}>
                        <Grid item xs={12} sm={6} className={classes.child}>
                            <TextField
                                label="Proposed name"
                                name="proposed_name"
                                error={!!error.proposed_name}
                                id={!error.proposed_name ? "outlined-basic" : "outlined-error-helper-text"}
                                required
                                className={classes.text}
                                helperText={error.proposed_name ? error.proposed_name : ""}
                                // margin="normal"
                                type="text"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="Short name"
                                name="short_name"
                                error={!!error.short_name}
                                id={!error.short_name ? "outlined-basic" : "outlined-error-helper-text"}
                                required
                                className={classes.text}
                                helperText={error.short_name ? error.short_name : ""}
                                type="text"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="Address"
                                name="address"
                                error={!!error.address}
                                id={!error.address ? "outlined-basic" : "outlined-error-helper-text"}
                                required
                                className={classes.text}
                                helperText={error.address ? error.address : ""}
                                type="text"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            />
                            <TextField
                                label="Zone Type"
                                name="zone_type_id"
                                select
                                error={!!error.zone_type_id}
                                id={!error.zone_type_id ? "outlined-basic" : "outlined-error-helper-text"}
                                required
                                className={classes.text}
                                helperText={error.zone_type_id ? error.zone_type_id : ""}
                                type="text"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            >
                                <MenuItem value="1">
                                    Public
                                </MenuItem>
                                <MenuItem value="2">
                                    Private
                                </MenuItem>
                                <MenuItem value="3">
                                    Public-Private Partnership
                                </MenuItem>

                            </TextField>

                        </Grid>
                        <Grid item xs={12} sm={6} className={classes.child}>
                            <TextField
                                label="Specialty"
                                name="specialty"
                                select
                                error={!!error.specialty}
                                id={!error.specialty ? "outlined-basic" : "outlined-error-helper-text"}
                                required
                                className={classes.text}
                                helperText={error.specialty ? error.specialty : ""}
                                // margin="normal"
                                type="text"
                                variant="outlined"
                                onChange={(e) => handleChange(e)}
                            >
                                {specialties.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.name}
                                    </MenuItem>
                                ))}
                            </TextField>

                            <TextField
                                id="outlined-error-helper-text"
                                select
                                className={classes.text}
                                variant="outlined"
                                label="State"
                                name="stateId"
                                value={stateId}
                                onChange={(e) => selectState(e)}
                                disabled={loading}
                            >
                                {states.map((option) => (
                                    <MenuItem key={option.state_id} value={option.state_id}>
                                        {option.state_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {selected &&
                                <TextField
                                    id="outlined-error-helper-text"
                                    select
                                    className={classes.text}
                                    variant="outlined"
                                    label="LGA"
                                    name="lga"
                                    value={lga}
                                    disabled={loading}
                                    onChange={(e) => selectSubCategory(e)}
                                >
                                    {lgaList.map((option) => (
                                        <MenuItem key={option.lga_id} value={option.lga_id}>
                                            {option.lga}
                                        </MenuItem>
                                    ))}
                                </TextField>}
                        </Grid>
                        <Grid item xs={12} className={classes.btnContainer}>
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleSubmit}
                                className={classes.button}
                                size="large"
                                disabled={loading}
                            >
                                Continue
                            </Button>
                        </Grid>
                    </Grid>


                </form>
                {loading && (
                    <div className={classes.spinner}>
                        <CircularProgress />
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="initial"
                        >
                            Submiting...
                </Typography>
                    </div>
                )}
            </div>
        </div>
    );
}
