import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import { Link, useHistory, useLocation } from "react-router-dom";
import DashboardIcon from "../../images/dashboardIcon.svg";
import LocationIcon from "../../images/location.svg";
import CubesIcon from "../../images/cubes.svg";
import caseIcon from "../../images/briefcase.svg";
import activityIcon from "../../images/Activity.svg";
import boxIcon from "../../images/box.svg";
import walletIcon from "../../images/empty-wallet-change.svg";
import ViewListIcon from "@material-ui/icons/ViewList";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import HistoryIcon from "@material-ui/icons/History";
import BusinessIcon from "@material-ui/icons/Business";
import RoomIcon from "@material-ui/icons/Room";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import LocationCityIcon from "@material-ui/icons/LocationCity";
import PublicIcon from "@material-ui/icons/Public";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import FormatIndentIncreaseIcon from "@material-ui/icons/FormatIndentIncrease";
import {
  Collapse,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import {
  Menuitems,
  ZoneAdminMenuItems,
  FreeZoneManagerPublic,
  FreeZoneManagerPrivate,
} from "pages/Admin/AdminMenu";

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "10px",
    color: theme.palette.text.secondary,

    "&:hover > $content": {
      backgroundColor: theme.palette.action.hover,
    },
    "&:focus > $content, &$selected > $content": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: "var(--tree-view-color)",
    },
    "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
      {
        backgroundColor: "transparent",
      },
  },
  content: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(1),
    borderBottomRightRadius: theme.spacing(1),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "$expanded > &": {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    "& $content": {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: "inherit",
    color: "inherit",
  },
  labelRoot: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: "bold",
    flexGrow: 1,
  },
}));

const useStyles = makeStyles((theme) => ({
  root: {
    height: 450,
    flexGrow: 1,
    maxWidth: 400,
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  menuLink: {
    position: "relative",
    display: "block",
    // padding:' 0 3em'
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: "#47BB78",
    color: "white",
  },
  logo: {
    maxWidth: "80%",
  },
  icons: {
    color: "red !important",
  },
  input: {
    color: "#000",
    padding: "1em",
    border: "1px solid rgba(176, 174, 174, 1)",
    borderRadius: "2em",
    fontSize: "14px",
    width: "480px",
  },
}));

function StyledTreeItem(props) {
  const classes = useStyles();
  const [openDD, setOpenDD] = useState(false);
  const router = useLocation();
  const {
    labelText,
    labelIcon: LabelIcon,
    labelInfo,
    color,
    bgColor,
    group,
    link,
    ...other
  } = props;

  if (group.length) {
    return (
      <>
        <ListItem
          button
          className={classes.menuItem}
          style={{ color, backgroundColor: bgColor }}
          onClick={() => {
            setOpenDD(!openDD);
          }}
        >
          <ListItemIcon
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "20px",
            }}
          >
            {/* {<route.icon className={classes.menuIcon} />} */}

            <img src={LabelIcon} alt="icon" className={classes.icons} />
          </ListItemIcon>
          <ListItemText primary={labelText} />
        </ListItem>
        <Collapse in={openDD} timeout="auto" unmountOnExit>
          {group.map((item) => {
            return (
              <Link to={item.href}>
                <ListItem
                  button
                  className={classes.menuItem}
                  style={{
                    color: router.pathname === item.href ? "white" : "black",
                    backgroundColor:
                      router.pathname === item.href ? "#47BB78" : null,
                  }}
                >
                  <ListItemIcon
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {/* {<route.icon className={classes.menuIcon} />} */}
                  </ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </Link>
            );
          })}
        </Collapse>
      </>
    );
  }

  return (
    <Link to={link}>
      <ListItem
        button
        className={classes.menuItem}
        style={{ color, backgroundColor: bgColor }}
      >
        <ListItemIcon
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: "20px",
          }}
        >
          {/* {<route.icon className={classes.menuIcon} />} */}

          <img src={LabelIcon} alt="icon" className={classes.icons} />
        </ListItemIcon>
        <ListItemText primary={labelText} />
      </ListItem>
    </Link>
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

export default function SettingsMenu() {
  const classes = useStyles();

  const router = useLocation();

  const getUserRole = () => {
    const userDetails = localStorage.getItem("userDetails");
    const user = JSON.parse(userDetails);
    if (user) {
      return user.data.role_id;
    }
    return null;
  };

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
    >
      {Menuitems.map((menuItem, index) => {
        if (menuItem?.id?.includes(getUserRole())) {
          return (
            <div key={index}>
              <StyledTreeItem
                nodeId={index}
                color={router.pathname === menuItem.href ? "white" : "black"}
                bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
                labelText={menuItem.title}
                labelIcon={menuItem.icon}
                group={menuItem.group ? menuItem.group : []}
                link={menuItem.href}
              />
            </div>
          );
        }
      })}

      {ZoneAdminMenuItems.map((menuItem, index) => {
        if (menuItem?.id?.includes(getUserRole())) {
          return (
            <div key={index}>
              <StyledTreeItem
                nodeId={index}
                color={router.pathname === menuItem.href ? "white" : "black"}
                bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
                labelText={menuItem.title}
                labelIcon={menuItem.icon}
                group={menuItem.group ? menuItem.group : []}
                link={menuItem.href}
              />
            </div>
          );
        }
      })}

      {FreeZoneManagerPublic.map((menuItem, index) => {
        if (menuItem?.id?.includes(getUserRole())) {
          return (
            <div key={index}>
              <StyledTreeItem
                nodeId={index}
                color={router.pathname === menuItem.href ? "white" : "black"}
                bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
                labelText={menuItem.title}
                labelIcon={menuItem.icon}
                group={menuItem.group ? menuItem.group : []}
                link={menuItem.href}
              />
            </div>
          );
        }
      })}

      {FreeZoneManagerPrivate.map((menuItem, index) => {
        if (menuItem?.id?.includes(getUserRole())) {
          return (
            <div key={index}>
              <StyledTreeItem
                nodeId={index}
                color={router.pathname === menuItem.href ? "white" : "black"}
                bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
                labelText={menuItem.title}
                labelIcon={menuItem.icon}
                group={menuItem.group ? menuItem.group : []}
                link={menuItem.href}
                key={index}
              />
            </div>
          );
        }
      })}

      {/* <Link to="/admin-account/history">
        <StyledTreeItem
          nodeId="3"
          color={
            router.pathname === "/admin-account/history" ? "white" : "black"
          }
          bgColor={
            router.pathname === "/admin-account/history" ? "#47BB78" : null
          }
          labelText="Applications"
          labelIcon={CubesIcon}
        />
      </Link>
      <Link to="/admin-account/admin-zones">
        <StyledTreeItem
          nodeId="2"
          color={
            router.pathname === "/admin-account/admin-zones" ? "white" : "black"
          }
          bgColor={
            router.pathname === "/admin-account/admin-zones" ? "#47BB78" : null
          }
          labelText="Zones"
          labelIcon={LocationIcon}
        />
      </Link>
      <Link to="/admin-account">
        <StyledTreeItem
          nodeId="2"
          color={router.pathname === "/admin-account" ? "white" : "black"}
          bgColor={router.pathname === "/admin-account" ? "#47BB78" : null}
          labelText="Free Zone Companies"
          labelIcon={caseIcon}
        />
      </Link>
      <Link to="/admin-account">
        <StyledTreeItem
          nodeId="2"
          color={
            router.pathname === "/admin-account/nepza-admin"
              ? "#48BB78"
              : "black"
          }
          bgColor={
            router.pathname === "/admin-account/nepza-admin" ? "white" : null
          }
          labelText="Zone Management Companies(ZMCs)"
          labelIcon={activityIcon}
        />
      </Link> */}
      {/* <Link to="/admin-account/admin-enterprises">
        <StyledTreeItem
          nodeId="2"
          color={
            router.pathname === "/admin-account/admin-enterprises"
              ? "white"
              : "black"
          }
          bgColor={
            router.pathname === "/admin-account/admin-enterprises"
              ? "#47BB78"
              : null
          }
          labelText="Enterprises"
          labelIcon={boxIcon}
        />
      </Link>
      <Link to="/admin-account">
        <StyledTreeItem
          nodeId="2"
          color={router.pathname === "/admin-account" ? "white" : "black"}
          bgColor={router.pathname === "/admin-account" ? "#47BB78" : null}
          labelText="Revenues"
          labelIcon={walletIcon}
        />
      </Link> */}

      {/* {getUserRole() === 7 && (
        <Link to="/admin-account/admin-zones">
          <StyledTreeItem
            nodeId="27"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Zones"
            labelIcon={RoomIcon}
          />
        </Link>
      )} */}

      {/* {getUserRole() === 7 && (
        <Link to="/admin-account/admin-promoters">
          <StyledTreeItem
            nodeId="28"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Promoters"
            labelIcon={BusinessIcon}
          />
        </Link>
      )} */}

      {/* {getUserRole() === 7 && (
        <Link to="/admin-account/zmc-applications">
          <StyledTreeItem
            nodeId="29"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="ZMC"
            labelIcon={LocationCityIcon}
          />
        </Link>
      )} */}

      {/* {getUserRole() === 7 && (
        <Link to="/admin-account/admin-enterprises">
          <StyledTreeItem
            nodeId="30"
            color="#48BB78"
            bgColor="#F0FFF4"
            labelText="Enterprise"
            labelIcon={BusinessCenterIcon}
          />
        </Link>
      )} */}
      {/* {getUserRole() === 6 && (
        <Link to="/admin-account/exchange-rate">
          <StyledTreeItem
            nodeId="19"
            labelText="Exchange Rate"
            labelIcon={MonetizationOnIcon}
            color="#48BB78"
            bgColor="#F0FFF4"
          />
        </Link>
      )} */}
      {/* {getUserRole() === 9 && (
        <StyledTreeItem
          nodeId="4"
          labelText="User Management"
          labelIcon={SupervisorAccountIcon}
          color="#48BB78"
          bgColor="#F0FFF4"
        >
          <Link to="/admin-account/create-user">
            <StyledTreeItem
              nodeId={index}
              color={router.pathname === menuItem.href ? "white" : "black"}
              bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
              labelText={menuItem.title}
              labelIcon={menuItem.icon}
              group={menuItem.group ? menuItem.group : []}
              link={menuItem.href}
            />
          </>
        ))}

      {getUserRole() === 7 &&
        Menuitems.map((menuItem, index) => (
          <>
            <StyledTreeItem
              nodeId={index}
              color={router.pathname === menuItem.href ? "white" : "black"}
              bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
              labelText={menuItem.title}
              labelIcon={menuItem.icon}
              group={menuItem.group ? menuItem.group : []}
              link={menuItem.href}
            />
          </>
        ))}

            <Link to="/admin-account/process-flow">
              <StyledTreeItem
                nodeId="11"
                labelText="Process Flow"
                labelIcon={LocalOfferIcon}
                color="#48BB78"
                bgColor="#F0FFF4"
              />
            </Link>
          </StyledTreeItem>
        </>
      )}
      {getUserRole() === 9 && (
        <StyledTreeItem
          nodeId="12"
          labelText="SetUp"
          labelIcon={SettingsApplicationsIcon}
        >
          <Link to="/admin-account/zones">
            <StyledTreeItem
              nodeId={index}
              color={router.pathname === menuItem.href ? "white" : "black"}
              bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
              labelText={menuItem.title}
              labelIcon={menuItem.icon}
              group={menuItem.group ? menuItem.group : []}
              link={menuItem.href}
            />
          </>
        ))}

      {getUserRole() === 4 &&
        FreeZoneManagerPrivate.map((menuItem, index) => (
          <>
            <StyledTreeItem
              nodeId={index}
              color={router.pathname === menuItem.href ? "white" : "black"}
              bgColor={router.pathname === menuItem.href ? "#47BB78" : null}
              labelText={menuItem.title}
              labelIcon={menuItem.icon}
              group={menuItem.group ? menuItem.group : []}
              link={menuItem.href}
            />
          </Link>

          <Link to="/admin-account/view-categories">
            <StyledTreeItem
              nodeId="16"
              labelText="Categories"
              labelIcon={ForumIcon}
              color="#48BB78"
              bgColor="#F0FFF4"
            />
          </Link>
        </StyledTreeItem>
      )} */}
    </TreeView>
  );
}
