/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { MenuItem, InputAdornment, Container } from "@material-ui/core";
import { AppConfig } from "../app-config";
import Header from "./SigninHeader";
import { useHistory } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import Popover from "@material-ui/core/Popover";
import InfoIcon from "@material-ui/icons/Info";
import Tab from "components/Tabs/Tab";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    marginTop: "0.5em",
  },
  // paper: {
  //   minHeight: "45vh",
  //   height: "auto",
  //   width: "70%",
  //   minWidth: "300px",
  //   margin: "1rem",
  //   // verticalAlign: "middle",
  // },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    // alignItems: "center",
    // justifyContent: "center",
    padding: "2rem 86px",
    flexDirection: "column",
  },
  loginLink: {
    color: "gray",
    marginTop: "20px",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "1rem",
    // borderRadius: "5px",
    // background: "rgba(72,187,120,1)",
    // color: "#FEFEFE",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    boxShadow: "none",
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    // marginBottom: "1rem",
    height: "100%",
  },
  textDiv: {
    width: "90%",
    // marginBottom: "1.4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    width: "48%",
  },
  textFieldLift: {
    width: "48%",
    position: "relative",
    bottom: 4,
  },
  adornment: {
    color: "grey",
    position: "relative",
    left: -45,
    bottom: -5,
  },
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
    display: "flex",
    flexGrow: "1",
  },
  info: {
    position: "relative",
    top: -20,
    left: -20,
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function CreateEnterprise() {
  const [Company, setCompany] = React.useState({
    name: "",
    address: "",
    username: "",
    password: "",
  });

  const [info, setInfo] = React.useState({});
  const [error, setError] = React.useState({
    name: "",
    address: "",
    username: "",
    password: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [alert, showAlert] = React.useState(false);
  const [zones, setZones] = React.useState([]);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [entityName, setEntityName] = React.useState("");
  const [showTag, setShowTag] = React.useState(false);
  const [step, setStep] = React.useState(0);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handleZoneChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    if (event.target.value) setIsDisabled(false);
    if (event.target.value === 3) {
      setShowTag(true);
    } else setShowTag(false);
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handleNameChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setEntityName(`${event.target.value}`);
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };
  const handlePhoneChange = (value) => {
    setError({ ...error, phone: "" });
    setCompany({
      ...Company,
      phone: value,
    });
  };

  const history = useHistory();

  const handleBlurField = () => {
    if (Company.name) {
      let rawName = Company.name;
      let parsedName = rawName.replace(/\s/g, "").toLocaleLowerCase();
      if (
        parsedName[parsedName.length - 1] === "c" &&
        parsedName[parsedName.length - 2] === "z" &&
        parsedName[parsedName.length - 3] === "f"
      ) {
      } else {
        setCompany({
          ...Company,
          name: `${Company.name} FZC`,
        });
        // return setError({ ...error, name: "Add FZC suffix to your company name" });
      }
    }
  };

  const handleSubmit = async () => {
    const { name, address, username, password, zone_id } = Company;
    if (!address) {
      return setError({ ...error, address: "Please provide a Address" });
    }
    // if (!rc_no) {
    //   return setError({ ...error, rc_no: "Please provide a Rc no" });
    // }
    // if (!username) {
    //   return setError({ ...error, username: "Please provide a username" });
    // }
    if (!password) {
      return setError({ ...error, password: "Please provide a password" });
    }

    // if (phone.length > 11) {
    //   return setError({ ...error, phone: "Phone number must be 11 digits" });
    // }
    let body = {};
    body = {
      address,
      username,
      password,
      zone_id,
      ...info,
      name: info.search,
      first_name: info.user_names.split(" ")[0],
      last_name: info.user_names.split(" ")[1],
      email: info.user_email,
      phone: info.user_phone,
      entity_type_id: 2,
    };
    try {
      setLoading(true);

      let reqMethod = "POST";
      let url = AppConfig.URL + "/company/register";

      const { status, message } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage["token"],
        },
        body: JSON.stringify(body),
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        showAlert(true);
        // setCompany({
        //   name: "",
        //   address: "",
        // });
        Swal.fire(
          "Registration has been completed",
          "please log in to access your dashboard",
          "success"
        );

        setTimeout(() => {
          history.push("/login");
          closeAlert();
        }, 2000);
        // }
        // else if (!window.navigator.online) {
        //   Swal.fire("Internet", "Check your connection", "error");
      } else {
        Swal.fire("", message, "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to register company", "info");
      setLoading(false);
    }
  };

  const handleValidation = async () => {
    // if (phone.length > 11) {
    //   return setError({ ...error, phone: "Phone number must be 11 digits" });
    // }

    Swal.fire({
      title: "validating payment",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const rrr = params.get("rrr");
    try {
      Swal.showLoading();
      let reqMethod = "POST";
      let url = AppConfig.URL + "/search/payment/verify?rrr=" + rrr;

      const { status, message, data } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage["token"],
          body: JSON.stringify({}),
        },
      }).then((res) => res.json());
      const { payment_status, search } = data;
      const diligence = search.reason == "due_diligence";
      if (payment_status === "successful" && !diligence) {
        setInfo({ ...search });
        Swal.close();
        // else if (!window.navigator.online) {
        //   Swal.fire("Internet", "Check your connection", "error");
      } else if (payment_status === "successful" && diligence) {
        Swal.update({
          title:
            "Payment has been validated and an email will be sent to you shortly",
          icon: "success",
          text: "",
        });
      } else {
        Swal.update({
          title: "Payment  Could not be validated",
          icon: "error",
          text: "Please refresh the page",
        });
      }
    } catch (err) {
      Swal.update({
        title: "Payment  Could not be validated",
        icon: "error",
        text: "Please refresh the page",
      });
    }
  };

  const getZones = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/active-zones`, {
        method: "GET",
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setZones(data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire(
        "Something went wrong",
        "Unable to get available zones",
        "error"
      );
    }
  };

  React.useEffect(() => {
    handleValidation();
    getZones();
  }, []);

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showAlert(false);
  };

  const changeStep = () => setStep(step + 1);

  React.useEffect(() => {}, []);

  const handleRegistration = () => {
    if (step === 3 && Company.email && Company.password) {
      return false;
    }
    return true;
  };

  const steps = [
    "Company information",
    "Personal information",
    "Login credentials",
  ];

  return (
    <div>
      <Header />
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          Company Created Successfully!
        </Alert>
      </Snackbar>

      <Container className={classes.container}>
        <div>
          <img
            src="/search.png"
            alt="search"
            style={{
              height: "85vh",
              objectFit: "cover",
              objectPosition: "center",
              width: "100%",
            }}
          />
        </div>
        <Paper elevation={0} className={classes.paper}>
          <form className={classes.form} noValidate autoComplete="off">
            {step ? (
              <div
                style={{ marginBottom: "40px", cursor: "pointer" }}
                onClick={() => setStep(step - 1)}
              >
                <img src="/ArrowLeft.png" alt="arrow-left" />
              </div>
            ) : null}
            <Typography
              variant="h4"
              style={{ fontSize: "35px", fontWeight: "700" }}
            >
              {!step ? "Enterprise registration" : "Register"}
            </Typography>
            <h3 style={{ marginTop: "15px", marginBottom: "54px" }}>
              To get started with OPMS, please enter the following details.
            </h3>

            {step ? (
              <div className={classes.textDiv}>
                <Tab activeStep={step + 1} steps={steps} />
              </div>
            ) : null}

            {step === 0 && (
              <>
                <div className={classes.textDiv}>
                  <TextField
                    // label="Entity Name"
                    name="name"
                    fullWidth
                    error={!!error.name}
                    id={
                      !error.name
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    helperText={error.name ? error.name : ""}
                    margin="normal"
                    type="text"
                    className={classes.textField}
                    variant="outlined"
                    disabled
                    value={info?.search}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       {showTag ? "FZC" : null}
                    //     </InputAdornment>
                    //   ),
                    // }}
                    onBlur={(e) => handleBlurField(e)}
                    onChange={(e) => handleNameChange(e)}
                  />
                  <TextField
                    label="FZE number"
                    name="fze_number"
                    fullWidth
                    error={!!error.name}
                    id={
                      !error.name
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    helperText={error.name ? error.name : ""}
                    margin="normal"
                    type="text"
                    className={classes.textField}
                    variant="outlined"
                    disabled
                    // value={info?.search}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       {showTag ? "FZC" : null}
                    //     </InputAdornment>
                    //   ),
                    // }}
                    onBlur={(e) => handleBlurField(e)}
                    onChange={(e) => handleChange(e)}
                  />
                  {/* <TextField
                label="Address"
                name="address"
                error={!!error.address}
                id={
                  !error.address
                    ? "outlined-basic"
                    : "outlined-error-helper-text"
                }
                required
                // className={classes.textField}
                helperText={error.address ? error.address : ""}
                margin="normal"
                type="text"
                variant="outlined"
                onChange={(e) => handleChange(e)}
              /> */}
                  {/* <TextField
                    label="RC number"
                    name="rc_no"
                    error={!!error.rc_no}
                    id={
                      !error.rc_no
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    className={classes.textField}
                    helperText={error.rc_no ? error.rc_no : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  /> */}
                </div>
                <div className={classes.textDiv}>
                  <TextField
                    id={
                      !error.zones
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    error={!!error.zones}
                    select
                    fullWidth
                    variant="outlined"
                    label="Available zones"
                    name="zone_id"
                    value={Company.zone_id}
                    onChange={(e) => handleZoneChange(e)}
                    helperText={error.zones ? error.zones : ""}
                  >
                    {zones.map((option) => (
                      <MenuItem key={option.zone_id} value={option.zone_id}>
                        {option.zone_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </>
            )}

            {step == 1 && (
              <>
                <div className={classes.textDiv}>
                  <TextField
                    // label="First name"
                    name="first_name"
                    error={!!error.username}
                    id={
                      !error.username
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    disabled
                    className={classes.textField}
                    fullWidth
                    value={info?.user_names.split(" ")[0]}
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    // label="Username"
                    name="last_name"
                    error={!!error.username}
                    id={
                      !error.username
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    disabled
                    value={info?.user_names.split(" ")[1]}
                    className={classes.textField}
                    fullWidth
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div>

                <div className={classes.textDiv}>
                  <TextField
                    // label="First name"
                    name="email"
                    error={!!error.username}
                    id={
                      !error.username
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    disabled
                    value={info?.user_email}
                    className={classes.textField}
                    fullWidth
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    // label="Username"
                    name="phone"
                    error={!!error.username}
                    id={
                      !error.username
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    disabled
                    value={info?.user_phone}
                    className={classes.textField}
                    fullWidth
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </>
            )}

            {step == 2 && (
              <>
                <div className={classes.textDiv}>
                  <TextField
                    label="Username"
                    name="username"
                    error={!!error.username}
                    id={
                      !error.username
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    // className={classes.textField}
                    fullWidth
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    value={Company.username}
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className={classes.textDiv}>
                  <TextField
                    label="Password"
                    name="password"
                    error={!!error.password}
                    id={
                      !error.password
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    value={Company.password}
                    fullWidth
                    helperText={error.password ? error.password : ""}
                    margin="normal"
                    type="password"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className={classes.textDiv}>
                  <TextField
                    label="address"
                    name="address"
                    error={!!error.password}
                    id={
                      !error.password
                        ? "outlined-basic"
                        : "outlined-error-helper-text"
                    }
                    required
                    value={Company.address}
                    fullWidth
                    helperText={error.password ? error.password : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </>
            )}

            <div className={classes.textDiv}>
              {step !== 2 ? (
                <Button
                  fullWidth
                  // autoFocus
                  variant="contained"
                  color="primary"
                  onClick={changeStep}
                  disabled={step === 0 && !Company?.zone_id}
                  className={classes.button}
                  size="large"
                >
                  Continue
                </Button>
              ) : (
                <Button
                  fullWidth
                  // autoFocus
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  className={classes.button}
                  size="large"
                  disabled={!Company.password || !Company.address}
                >
                  {loading ? (
                    <div className={classes.spinner}>
                      <CircularProgress
                        style={{ height: "30px", width: "30px", margin: 0 }}
                      />
                    </div>
                  ) : step === 2 ? (
                    "Register"
                  ) : null}
                </Button>
              )}
            </div>
            <p
              className={classes.loginLink}
              tw="mt-8 text-sm text-gray-400 text-center"
            >
              Already have an account?{" "}
              <a href={"/login"} tw="border-b border-gray-500 border-dotted">
                Sign In
              </a>
            </p>
          </form>
          {/* {loading && (
            <div className={classes.spinner}>
              <CircularProgress />
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="initial"
              >
                Submiting Company...
              </Typography>
            </div>
          )} */}
        </Paper>
      </Container>
    </div>
  );
}
