import React, { Suspense, lazy } from "react";
import PropTypes from "prop-types";
import {
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import Logout from "../../images/logout.svg";
import { Switch, Route } from "react-router-dom";
import { useHistory, NavLink } from "react-router-dom";
import AccountCircle from "@material-ui/icons/AccountCircle";
import logo from "images/Nepzaimage.png";
import SettingsMenu from "./SettingsMenu";

const DataTable = React.lazy(() => import("pages/Account/DataTable"));
const EnterpriseApplicationDetails = lazy(() =>
  import("./Tables/EnterpriseApplicationDetails"),
);
const ZmcApplicationDetails = lazy(() =>
  import("./Tables/ZmcApplicationDetails"),
);
const ZMCApplicationTable = lazy(() => import("./Tables/ZMCApplicationTable"));
const FinanceApplicationTable = lazy(() =>
  import("./Tables/FinanceApplicationTable"),
);
const FinanceApplicationDetails = lazy(() =>
  import("./Tables/FinanceApplicationDetails"),
);
const UserProfile = lazy(() => import("pages/UserProfile"));
const ZoneManagementInventoryDetails = lazy(() =>
  import(
    "pages/FZMC/Public/ZoneManagementRequestsTabs/ZoneManagementInventoryDetails"
  ),
);
const ZoneManagementApplicationDetails = lazy(() =>
  import(
    "pages/FZMC/Public/ZoneManagementRequestsTabs/ZoneManagementApplicationDetails"
  ),
);
const RequestInventoryDetails = lazy(() =>
  import(
    "pages/FZMC/Public/FreeZoneRequets/RequestsInventory/RequestInventoryDetails"
  ),
);
const CheckList = lazy(() => import("pages/checklist/CheckList"));
const Mdinfo = lazy(() => import("./Tables/Mdinfo"));
const EnterpriseInventory = lazy(() =>
  import("./Inventory/EnterpriseInventory"),
);
const FreeZonesInventory = lazy(() => import("./Inventory/FreeZonesInventory"));
const ZmcInventory = lazy(() => import("./Inventory/ZmcInventory"));
const Exchange = lazy(() => import("./Exchange"));
const FinanceRevenue = lazy(() => import("./Tables/FinanceRevenue"));
const FinanceER = lazy(() => import("./Tables/FinanceER"));
const FinanceFZR = lazy(() => import("./Tables/FinanceFZR"));
const FinanceAdmin = lazy(() => import("./FinanceAdmin"));
const Revenues = lazy(() => import("./Tables/Revenues"));
const ZoneManagementDashboard = lazy(() =>
  import("./MDDashboard/ZoneManagementDashboard"),
);
const ZoneDashboard = lazy(() => import("./MDDashboard/ZoneDashboard"));
const EnterpriseDashboard = lazy(() =>
  import("./MDDashboard/EnterpriseDashboard"),
);
const FreeZoneApplications = lazy(() =>
  import("./Applications/FreeZoneApplications"),
);
const EnterpriseApplications = lazy(() =>
  import("./Applications/EnterpriseApplications"),
);
const ZoneRequest = lazy(() => import("./Tables/ZoneRequest"));
const FreeZoneRequest = lazy(() => import("./Tables/FreeZoneRequest"));
const EnterpriseRequest = lazy(() => import("./Tables/EnterpriseRequest"));
const ZmcDashboard = lazy(() => import("pages/Admin/ZmcDashboard"));
const AdminEnterprises = lazy(() => import("pages/Account/AdminEnterprises"));
const AdminPromoters = lazy(() => import("pages/Account/AdminPromoters"));
const AdminZones = lazy(() => import("pages/Account/AdminZones"));
const AdminViewDetails = lazy(() =>
  import("../CompanyRequest/AdminViewDetails"),
);
const FinalApproval = lazy(() => import("../../Approvals/FinalApproval"));
const EditCreateProcessFlow = lazy(() => import("./UpdateProcessFlow"));
const ProcessFlow = lazy(() => import("./ProcessFlow"));
const PromoterDetails = lazy(() => import("pages/PromoterDetails"));
const ZMCApplicationDetails = lazy(() =>
  import("pages/ZMCApplications/ZMCApplicationDetails"),
);
const ZmcApplications = lazy(() =>
  import("pages/ZMCApplications/ZMCApplications"),
);
const EditProcessModule = lazy(() =>
  import("pages/Account/ProcessModules/EditProcessModule"),
);
const CreateProcessModule = lazy(() =>
  import("pages/Account/ProcessModules/CreateProcessModule"),
);
const ProcessModules = lazy(() =>
  import("pages/Account/ProcessModules/ViewProcessModules"),
);
const CategoryServices = lazy(() =>
  import("pages/Account/Categories/CategoryServices"),
);
const AllCategories = lazy(() =>
  import("pages/Account/Categories/AllCategories"),
);
const EditRole = lazy(() => import("pages/Account/RoleManagement/EditRole"));
const CreateRole = lazy(() =>
  import("pages/Account/RoleManagement/CreateRole"),
);
const ViewRoles = lazy(() => import("pages/Account/RoleManagement/ViewRoles"));
const SingleZoneAdminEnterprise = lazy(() =>
  import("./ZoneAdminRequetsTabs/SingleZoneAdminEnterprise"),
);
const SingleFreeZoneApplicationPrivate = lazy(() =>
  import("../FZMC/Private/FreeZoneRequests/SingleApplication"),
);
const FreeZoneRequestsPrivateApplication = lazy(() =>
  import("../FZMC/Private/FreeZoneRequests/Application"),
);
const FreeZoneRequestsPrivateDashboard = lazy(() =>
  import("../FZMC/Private/FreeZoneRequests/Dashboard"),
);
const SingleZoneApplicationPrivate = lazy(() =>
  import("../FZMC/Private/ZoneManagement/SingleApplication"),
);
const ZoneManagementPrivateInventory = lazy(() =>
  import("../FZMC/Private/ZoneManagement/RequestInventory"),
);
const ZoneManagamentPrivateApplication = lazy(() =>
  import("../FZMC/Private/ZoneManagement/Application"),
);
const ZoneManagementPrivateDashboard = lazy(() =>
  import("../FZMC/Private/ZoneManagement/Dashboard"),
);
const PrivateAdminDashboard = lazy(() => import("../FZMC/Private/Dashboard"));
const PublicRevenues = lazy(() =>
  import("pages/FZMC/Public/Revenue/PublicRevenues"),
);
const FreeZoneInventory = lazy(() =>
  import(
    "pages/FZMC/Public/FreeZoneRequets/RequestsInventory/FreeZoneInventory"
  ),
);
const FreeZoneApplicationDetail = lazy(() =>
  import(
    "pages/FZMC/Public/FreeZoneRequets/Applications/FreeZoneApplicationDetail"
  ),
);
const FreeZoneApplication = lazy(() =>
  import("pages/FZMC/Public/FreeZoneRequets/Applications/FreeZoneApplication"),
);
const ZoneManagementInventory = lazy(() =>
  import("pages/FZMC/Public/ZoneManagementInventory"),
);
const GeneratedRevenue = lazy(() =>
  import("pages/FZMC/Public/GeneratedRevenus"),
);
const ZMCTable = lazy(() => import("pages/FZMC/Public/ZMCTable"));
const ZoneApplicationDetail = lazy(() =>
  import("pages/FZMC/Public/ZoneApplicationDetail"),
);
const ZoneManagementApplication = lazy(() =>
  import("../FZMC/Public/ZoneManagementApplication"),
);
const FreeZoneRequestsDashboard = lazy(() =>
  import("pages/FZMC/Public/FreeZoneRequestsDashboard"),
);
const PublicDashboard = lazy(() =>
  import("pages/FZMC/Public/FreeZoneRequestsDashboard"),
);
const ZoneAdminDashboard = lazy(() => import("./ZoneAdminDashboard"));
const Revenue = lazy(() => import("./Revenue"));
const ZoneAdminApplication = lazy(() => import("./ZoneAdminApplication"));
const ZoneAdminEnterprises = lazy(() => import("./ZoneAdminEnterprises"));
const EditAdminUser = lazy(() =>
  import("pages/Account/UserManagement/EditAdminUser"),
);
const AdminUsers = lazy(() =>
  import("pages/Account/UserManagement/AdminUsers"),
);
const EditCreateServices = lazy(() =>
  import("pages/Account/EditCreateService"),
);
const EditCreateZones = lazy(() => import("pages/Account/EditCreate"));
const CreateUser = lazy(() =>
  import("pages/Account/UserManagement/CreateUser"),
);
const AdminHistory = lazy(() => import("pages/Account/History/AdminHistory"));
const ExchangeRate = lazy(() => import("pages/Account/ExchangeRate"));
const EditCreateDocument = lazy(() =>
  import("pages/Account/EditCreateDocument"),
);
const Document = lazy(() => import("pages/Account/Document"));
const Services = lazy(() => import("pages/Account/Services"));
const Zones = lazy(() => import("pages/Account/Zones"));
const ActionSubmitted = lazy(() => import("pages/Account/ActionSubmitted"));
const AdminRequestAction = lazy(() =>
  import("pages/Account/AdminRequestAction"),
);
const ZoneAdminDataTable = lazy(() =>
  import("pages/Account/ZoneAdminRequetsTabs/ZoneAdminDataTable"),
);
const ZoneAdminApplicationDetail = lazy(() =>
  import("./ZoneAdminRequetsTabs/ZoneAdminApplicationDetail"),
);
const ZoneAdminRequestAction = lazy(() =>
  import("pages/Account/ZoneAdminRequetsTabs/ZoneAdminRequestAction"),
);

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    padding: "1em 0",
    backgroundColor: "white",
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    [theme.breakpoints.down("md")]: {
      padding: 0,
    },
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  menuLink: {
    position: "relative",
    display: "block",
    // padding:' 0 3em'
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: "#47BB78",
    color: "white",
  },
  logo: {
    maxWidth: "80%",
  },
  icons: {
    color: "red !important",
  },
  input: {
    color: "#000",
    padding: "1em",
    border: "1px solid rgba(176, 174, 174, 1)",
    borderRadius: "2em",
    fontSize: "14px",
    width: "480px",
  },
  justifyDrawer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100%",
  },
}));

function ResponsiveDrawer(props) {
  const history = useHistory();
  const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const getAdmin = () => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      return JSON.parse(userDetails);
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    history.push("/");
  };

  const drawer = (
    <div className={classes.justifyDrawer}>
      {/* <div className={classes.toolbar} /> */}
      <div>
        <div
          style={{
            marginBottom: "4rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "1.5rem",
          }}
        >
          <img src={logo} className={classes.logo} alt="logo" />
        </div>

        {/* <Divider /> */}
        <List className={classes.listMenu}>
          <SettingsMenu />
        </List>
        <Divider />
      </div>

      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon color="#E72E2E">
            <img src={Logout} alt="logout" />
          </ListItemIcon>
          <ListItemText primary={"Logout"} style={{ color: "red" }} />
        </ListItem>
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar} elevation={0}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            <input
              placeholder="Search something here"
              className={classes.input}
            />
          </Typography>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            // onClick={handleMenu}
            style={{ color: "gray", marginRight: "2px" }}
          >
            <NavLink
              to="/admin-account/profile"
              exact
              // className={classes.menuLink}
              // activeClassName={classes.menuActive}
            >
              <AccountCircle />
            </NavLink>
          </IconButton>
          <Typography
            variant="h6"
            style={{ color: "black", fontSize: "18px", marginRight: "30px" }}
          >
            {getAdmin()?.data?.fullname}
          </Typography>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route exact path="/admin-account">
              <DataTable />
            </Route>
            <Route path="/admin-account/history-request-details/:rowId">
              <AdminViewDetails />
            </Route>
            <Route path="/admin-account/process-flow/:rowId">
              <EditCreateProcessFlow />
            </Route>
            <Route path="/admin-account/request-action/:rowId">
              <AdminRequestAction />
            </Route>

            <Route path="/admin-account/action-request/:rowId">
              <ZoneAdminRequestAction />
              {/* <RequestAction /> */}
            </Route>

            <Route path="/admin-account/action-submitted">
              <ActionSubmitted />
            </Route>
            <Route path="/admin-account/zmc-applications">
              <ZmcApplications />
            </Route>

            {/*Check list path */}
            <Route path="/admin-accont/checklist">
              <CheckList />
            </Route>
            <Route path="/admin-account/admin-zones">
              <AdminZones />
            </Route>
            <Route path="/admin-account/admin-promoters">
              <AdminPromoters />
            </Route>
            <Route path="/admin-account/admin-enterprises">
              <AdminEnterprises />
            </Route>
            <Route path="/admin-account/admin-freezone-dashboard">
              <FreeZoneRequestsDashboard />
            </Route>

            <Route exact path="/admin-account/free-zone-applications">
              <FreeZoneApplication />
            </Route>

            <Route exact path="/admin-account/free-zone-applications/:rowId">
              <FreeZoneApplicationDetail />
            </Route>

            <Route path="/admin-account/admin-zone-dashboard">
              <ZoneManagementDashboard />
            </Route>

            <Route path="/admin-account/zone-admin-enterprises">
              <ZoneAdminEnterprises />
              {/* <DataTable /> */}
            </Route>

            <Route exact path="/admin-account/zone-admin-ent">
              <ZoneAdminDataTable />
            </Route>

            <Route path="/admin-account/zone-admin-enterprises/:entId">
              <SingleZoneAdminEnterprise />
            </Route>
            <Route exact path="/admin-account/zone-request-inventory">
              <ZoneManagementInventory />
              {/* <NepzaAdminDashboard /> */}
            </Route>

            <Route exact path="/admin-account/zone-request-inventory/:rowId">
              <ZoneManagementInventoryDetails />
              {/* <NepzaAdminDashboard /> */}
            </Route>

            <Route path="/admin-account/zmc-request-detail/:rowId">
              <ZMCApplicationDetails />
            </Route>
            <Route path="/admin-account/promoter-detail/:rowId">
              <PromoterDetails />
            </Route>
            <Route path="/admin-account/enterprise-request">
              <EnterpriseRequest />
            </Route>
            <Route path="/admin-account/free-zone-request">
              <FreeZoneRequest />
            </Route>
            <Route path="/admin-account/zone-request">
              <ZoneRequest />
            </Route>
            <Route path="/admin-account/final-approval">
              <FinalApproval />
            </Route>
            <Route path="/admin-account/nepza-admin">
              {/* <NepzaAdminDashboard /> */}
              <ZmcDashboard />
            </Route>

            <Route exact path="/admin-account/zone-admin-applications">
              <ZoneAdminApplication />
            </Route>
            <Route exact path="/admin-account/profile">
              <UserProfile />
            </Route>

            <Route exact path="/admin-account/zone-admin-applications/:rowId">
              <ZoneAdminApplicationDetail />
            </Route>

            <Route
              exact
              path="/admin-account/zone-management-requests-applications"
            >
              <ZoneManagementApplication />
            </Route>

            <Route
              exact
              path="/admin-account/zone-management-requests-applications/:rowId"
            >
              <ZoneManagementApplicationDetails />
            </Route>

            <Route path="/admin-account/zonemanagement-application-private/:rowId">
              <SingleZoneApplicationPrivate />
            </Route>

            <Route path="/admin-account/freezone-application-private/:id">
              <SingleFreeZoneApplicationPrivate />
            </Route>

            <Route exact path="/admin-account/zone-admin-dashboard">
              <ZoneAdminDashboard />
            </Route>

            <Route path="/admin-account/zone-admin-revenue">
              <Revenue />
            </Route>

            <Route path="/admin-account/fzm-public">
              <PublicDashboard />
            </Route>

            <Route exact path="/admin-account/fzm-private">
              <PrivateAdminDashboard />
            </Route>

            <Route exact path="/admin-account/free-zone-request-inventory">
              <FreeZoneInventory />
            </Route>

            <Route
              exact
              path="/admin-account/free-zone-request-inventory/:rowId"
            >
              <RequestInventoryDetails />
            </Route>

            <Route path="/admin-account/free-zone-revenues">
              <PublicRevenues />
            </Route>

            <Route path="/admin-account/zonemanagement-dashboard-private">
              <ZoneManagementPrivateDashboard />
            </Route>

            <Route path="/admin-account/freezone-dashboard-private">
              <FreeZoneRequestsPrivateDashboard />
            </Route>

            <Route path="/admin-account/freezone-application-private">
              <FreeZoneRequestsPrivateApplication />
            </Route>

            <Route path="/admin-account/zone-manager-table">
              <ZMCTable />
            </Route>

            <Route path="/admin-account/generated-revenue">
              <GeneratedRevenue />
            </Route>

            <Route
              exact
              path="/admin-account/zonemanagement-application-private"
            >
              <ZoneManagamentPrivateApplication />
            </Route>

            <Route path="/admin-account/zone-management-requests-applications/:id">
              <ZoneApplicationDetail />
            </Route>

            <Route path="/admin-account/zonemanagement-request-private">
              <ZoneManagementPrivateInventory />
            </Route>

            <Route path="/admin-account/zones/edit-create/:rowId">
              <EditCreateZones />
            </Route>
            <Route path="/admin-account/services/edit-create/:rowId">
              <EditCreateServices />
            </Route>
            <Route path="/admin-account/services">
              <Services />
            </Route>
            <Route path="/admin-account/document/edit-create/:rowId">
              <EditCreateDocument />
            </Route>
            <Route path="/admin-account/document">
              <Document />
            </Route>
            <Route path="/admin-account/exchange-rate">
              <ExchangeRate />
            </Route>
            <Route path="/admin-account/view-users">
              <AdminUsers />
            </Route>
            <Route path="/admin-account/view-categories">
              <AllCategories />
            </Route>
            <Route path="/admin-account/category-services">
              <CategoryServices />
            </Route>
            <Route path="/admin-account/history">
              <AdminHistory />
            </Route>
            <Route path="/admin-account/zones">
              <Zones />
            </Route>
            <Route path="/admin-account/create-user">
              <CreateUser />
            </Route>
            <Route path="/admin-account/view-roles">
              <ViewRoles />
            </Route>
            <Route path="/admin-account/create-role">
              <CreateRole />
            </Route>
            <Route path="/admin-account/view-process-modules">
              <ProcessModules />
            </Route>
            <Route path="/admin-account/create-process-module">
              <CreateProcessModule />
            </Route>
            <Route path="/admin-account/edit-process-module/:rowId">
              <EditProcessModule />
            </Route>
            <Route path="/admin-account/edit-admin/:rowId">
              <EditAdminUser />
            </Route>
            <Route path="/admin-account/edit-role/:rowId">
              <EditRole />
            </Route>
            <Route path="/admin-account/process-flow">
              <ProcessFlow />
            </Route>
            <Route exact path="/admin-account/enterprise-applications">
              <EnterpriseApplications />
            </Route>
            <Route exact path="/admin-account/enterprise-applications/:id">
              <EnterpriseApplicationDetails />
            </Route>
            <Route path="/admin-account/free-zone-applications-new">
              <FreeZoneApplications />
            </Route>
            <Route exact path="/admin-account/zone-applications">
              <ZMCApplicationTable />
            </Route>
            <Route exact path="/admin-account/zone-applications/:id">
              <ZmcApplicationDetails />
            </Route>
            <Route path="/admin-account/enterprise-dashboard">
              <EnterpriseDashboard />
            </Route>
            <Route path="/admin-account/free-zone-dashboard">
              <ZoneManagementDashboard />
            </Route>
            <Route path="/admin-account/revenues">
              <Revenues />
            </Route>
            <Route path="/admin-account/zone-dashboard">
              <ZoneDashboard />
            </Route>
            <Route path="/admin-account/finance-md">
              <FinanceAdmin />
            </Route>
            <Route path="/admin-account/finance-fzr">
              <FinanceFZR />
            </Route>
            <Route exact path="/admin-account/finance-applications">
              <FinanceApplicationTable />
            </Route>
            <Route exact path="/admin-account/finance-applications/:id">
              <FinanceApplicationDetails />
            </Route>
            <Route path="/admin-account/finance-er">
              <FinanceER />
            </Route>
            <Route path="/admin-account/finance-revenue">
              <FinanceRevenue />
            </Route>
            <Route path="/admin-account/rate-calculate">
              <Exchange />
            </Route>
            <Route path="/admin-account/zminventory">
              <ZmcInventory />
            </Route>
            <Route path="/admin-account/fzinventory">
              <FreeZonesInventory />
            </Route>
            <Route path="/admin-account/enterpriseinventory">
              <EnterpriseInventory />
            </Route>
            <Route path="/admin-account/company-info">
              <Mdinfo />
            </Route>
            {/* <Route path="/admin-account/rate-calculate">
            <Exchange />
          </Route> */}
            {/* <Route path="/admin-account">
              <DataTable />
            </Route> */}
          </Switch>
        </Suspense>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
