import React, { useState } from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import { Container as ContainerBase } from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import illustration from "images/NEPZA.jpg";
import logo from "images/NEPZA.jpg";
import googleIconImageSrc from "images/google-icon.png";
import twitterIconImageSrc from "images/twitter-icon.png";
import { ReactComponent as LoginIcon } from "feather-icons/dist/icons/log-in.svg";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import Header from "./SigninHeader";
import { Box } from "@material-ui/core";
import { AppConfig } from "app-config";

const Container = tw(
  ContainerBase,
)` text-white font-medium flex justify-center -mx-8`;
const Content = tw.div`max-w-screen-xl m-0 sm:mx-20 bg-white text-gray-900  sm:rounded-lg flex justify-center flex-1 mt-4`;
const MainContainer = tw.div`w-full  rounded flex`;
const LogoLink = tw.a``;
const LogoImage = tw.img`h-12 mx-auto`;
const MainContent = tw.div`mt-24 flex flex-col flex-1 px-20`;
const Heading = tw.h1`text-2xl xl:text-3xl font-bold`;
const FormContainer = tw.div`w-full flex-1 mt-8`;

const ImageCont = tw.div`h-full md:block hidden`;

const greenImg = tw.div`h-full w-full object-cover`;

const SocialButtonsContainer = tw.div`flex flex-col items-center`;
const SocialButton = styled.a`
  ${tw`w-full max-w-xs font-semibold rounded-lg py-3 border text-gray-900 bg-gray-100 hocus:bg-gray-200 hocus:border-gray-400 flex items-center justify-center transition-all duration-300 focus:outline-none focus:shadow-outline text-sm mt-5 first:mt-0`}
  .iconContainer {
    ${tw`bg-white p-2 rounded-full`}
  }
  .icon {
    ${tw`w-4`}
  }
  .text {
    ${tw`ml-4`}
  }
`;

const DividerTextContainer = tw.div`my-12 border-b text-center relative`;
const DividerText = tw.div`leading-none px-2 inline-block text-sm text-gray-600 tracking-wide font-medium bg-white transform -translate-y-1/2 absolute inset-x-0 top-1/2 bg-transparent`;

const Form = tw.form`max-w-md`;
const Input = tw.input`w-full px-8 py-4 rounded-lg font-medium bg-green-100 border border-gray-200 placeholder-gray-500 text-sm focus:outline-none focus:border-gray-400 focus:bg-white first:mt-0  max-w-md`;
const SubmitButton = styled.button`
  ${tw`mt-5  font-semibold bg-green-500 text-gray-100 w-full py-4 rounded-lg hover:bg-green-600 transition-all duration-300 ease-in-out flex items-center justify-center focus:shadow-outline focus:outline-none`}
  .icon {
    ${tw`w-6 h-6 -ml-2`}
  }
  .text {
    ${tw`ml-3`}
  }
`;
const IllustrationContainer = tw.div`sm:rounded-r-lg flex-1 bg-purple-100 text-center hidden lg:flex justify-center`;
const IllustrationImage = styled.div`
  ${(props) => `background-image: url("${props.imageSrc}");`}
  ${tw`m-12 xl:m-16 w-full max-w-sm bg-contain bg-center bg-no-repeat`}
`;

export default ({
  logoLinkUrl = "#",
  illustrationImageSrc = illustration,
  headingText = "Login",
  submitButtonText = "Login",
  SubmitButtonIcon = LoginIcon,
  forgotPasswordUrl = "/forgot-password",
  signupUrl = "/register",
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // const realLoginEndpoint = "https://opms-back-demo.synergynode.com/api/v1/users/login"

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post(`${AppConfig.URL}/users/login`, {
        email,
        password,
      })
      .then(function (response) {
        if (response.status === 200) {
          console.log("RESPONSE", response);
          localStorage.setItem(
            "userPermission",
            response.data.data.role.role_name,
          );
          localStorage.setItem("userDetails", JSON.stringify(response.data));
          const role = response?.data?.data?.role_id;
          if (role === 8 || role === 10 || role === 14) {
            console.log(response);
            if (response?.data?.data?.company?.entity_type_id == 3) {
              return history.push("/me/zmc-dashboard");
            }
            return history.push("/me");
          } else if (role === 2) {
            return history.push("/admin-account/zone-admin-dashboard");
          } else if (role === 3) {
            return history.push("/admin-account/fzm-public");
          } else if (role === 4) {
            return history.push("/admin-account/fzm-private");
          } else if (role === 6) {
            return history.push("/admin-account/finance-md");
          } else {
            history.push("/admin-account/nepza-admin");
          }
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message);
        setSnackbarMessage(error?.response?.data?.message);
        setOpenSnackbar(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <AnimationRevealPage>
      <Header />
      <Container>
        <Content>
          <MainContainer>
            {/* <LogoLink href={logoLinkUrl}>
              <LogoImage src={logo} />
            </LogoLink> */}
            <ImageCont>
              <img
                src="/login.png"
                style={{
                  height: "85vh",
                  objectFit: "cover",
                  objectPosition: "center",
                  width: "100%",
                }}
                alt=""
              />
            </ImageCont>
            <MainContent>
              {/* <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={e=>setOpenSnackbar(false)}>
        <Alert onClose={e=>setOpenSnackbar(false)} severity="success">
          This is a success message!
        </Alert>
      </Snackbar> */}

              <Snackbar
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={(e) => setOpenSnackbar(false)}
                message={snackbarMessage}
                action={
                  <React.Fragment>
                    <IconButton
                      size="small"
                      aria-label="close"
                      color="inherit"
                      onClick={(e) => setOpenSnackbar(false)}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </React.Fragment>
                }
              />

              <Heading>{headingText}</Heading>
              <FormContainer>
                <Form>
                  <p tw="mt-8 text-center  text-sm mb-2 flex justify-between">
                    Email
                  </p>
                  <Input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="email"
                  />
                  <p tw="mt-8 text-center  text-sm mb-2 flex justify-between">
                    Password
                    <a href={forgotPasswordUrl} tw="text-green-500">
                      Forgot Password ?
                    </a>
                  </p>
                  <Input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Password"
                  />
                  <SubmitButton type="submit" onClick={handleSubmit}>
                    {/* <SubmitButtonIcon className="icon" /> */}

                    <span className="text">
                      {loading ? "Please wait..." : submitButtonText}
                    </span>
                  </SubmitButton>

                  <p tw="mt-8 text-sm text-gray-600">
                    Dont have an account?
                    <a href={signupUrl} tw=" text-green-500">
                      Sign Up
                    </a>
                  </p>
                </Form>
                {/* <p tw="mt-6 text-xs text-gray-600 text-center">
                  <a
                    href={forgotPasswordUrl}
                    tw="border-b border-gray-500 border-dotted"
                  >
                    Forgot Password ?
                  </a>
                </p> */}
              </FormContainer>
            </MainContent>
          </MainContainer>
        </Content>
      </Container>
    </AnimationRevealPage>
  );
};
