import {FormControl, Grid, TextField, MenuItem} from "@material-ui/core"
import axiosInstance from "Api/Api"
import React, {useState, useEffect} from "react"

const ZDLspecialty = () => {
  const [specialties, setSpecialties] = useState([])
  const [loading, setLoading] = useState(false)
  const [specialty, setSpecialty] = useState("")

  const getSpecialties = () => {
    setLoading(true)
    axiosInstance
      .get("/specialty/list")
      .then(function (response) {
        if (response.status === 200) {
          setSpecialties(response.data.data.data)
        } else {
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log("error", error)
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    getSpecialties()
  }, [])

  return (
    <>
      <Grid container style={{display: "flex", justifyContent: "center"}}>
        <Grid item xs={12} sm={6}>
          <FormControl
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              marginTop: "3em",
            }}
          >
            <label>Select Specialty</label>
            <TextField
              label="Specialty"
              name="specialty"
              select
              required
              style={{width: "100%"}}
              // margin="normal"
              type="text"
              variant="outlined"
              value={specialty}
              onChange={e => setSpecialty(e.target.value)}
            >
              {specialties.map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

export default ZDLspecialty
