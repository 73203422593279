import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import {
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import { blue, green, purple, red } from "@material-ui/core/colors";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import TotalRequestIcon from "../../../images/totalRequestIcon.svg";
import TotalRequestAmountIcon from "../../../images/totalRequestAmountIcon.svg";
import PendingRequestIcon from "../../../images/pendingRequestIcon.svg";
import ApprovedRequestIcon from "../../../images/approvedRequestIcon.svg";
import DeclinedRequestIcon from "../../../images/declinedRequestsIcon.svg";
import { Link } from "react-router-dom";
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
  CommonSeriesSettings,
} from "devextreme-react/chart";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    // minHeight: "150px",

    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "3em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const icons = [
  TotalRequestIcon,
  ApprovedRequestIcon,
  DeclinedRequestIcon,
  PendingRequestIcon,
  TotalRequestAmountIcon,
];

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

const ZMCEnterpriseDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});

  const user = localStorage.getItem("userDetails");
  const [charts, setCharts] = useState([]);

  const userData = JSON.parse(user);
  const classes = useStyles();
  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/zmc/dashboard/enterprise`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      console.log(data.chart);
      if (status === true) {
        setStat(data);
        setCharts(data.chart);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const formatMoney = (money) => {
    return `$ ${money.toLocaleString()}`;
  };

  React.useEffect(() => {
    getCategories();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );
  let cumulativeCount = 0;

  const dataArray = charts.map((item) => {
    cumulativeCount += item.val;
    return {
      month: item.month,
      count: item.val,
      // cumulativePercentage: Math.round((cumulativeCount * 100) / totalCount),
    };
  });
  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat.cards
          ? stat.cards.map((item, index) => (
              <Link
                to={{
                  pathname: "/me/enterprise-request",
                  state: {
                    activeTab: item,
                  },
                }}
              >
                <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                  <Paper className={classes.paper} elevation={0}>
                    <div className={classes.cards}>
                      <img src={icons[index]} />
                      <div>
                        <Typography
                          variant="h5"
                          style={{ marginBottom: "1em" }}
                        >
                          {item.isMoney ? formatMoney(item.data) : item.data}
                        </Typography>

                        <Typography style={{ color: "rgba(176, 174, 174, 1)" }}>
                          {item.title}
                        </Typography>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Link>
            ))
          : null}
      </Grid>

      <Chart id="chart" dataSource={dataArray} title="Enterprise Statistics">
        <CommonSeriesSettings argumentField="month" />
        <Series
          name=""
          valueField="count"
          axis="frequency"
          type="bar"
          color="#fac29a"
        />
      </Chart>

      {/* <Grid container className={classes.bottomData} spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography>Upcoming Renewal</Typography>
            <Tables list={stat?.upcoming_renewals} renewal={true} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>Request Amount</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>Last Treated Request</Paper>
        </Grid>
      </Grid> */}
    </div>
  );
};
export default ZMCEnterpriseDashboard;
