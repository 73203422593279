export const data = [
  {
    name: "Particulars of Directors",
    url: "directors-particulars",
  },
  {
    name: "Allotment Form",
    url: "allotment-form",
  },
  {
    name: "Declaration of compliance",
    url: "declaration-of-compliance",
  },
  {
    name: "Address of registered office in the free zone",
    url: "registered-office",
  },
];
