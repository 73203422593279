import React, {useEffect, useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import MuiAlert from "@material-ui/lab/Alert"
import Paper from "@material-ui/core/Paper"
import Swal from "sweetalert2"
import {Stepper, Step, StepLabel, Button} from "@material-ui/core"
// import {AppConfig} from "../../app-config"
import UploadDocuments from "../../UploadDocuments"
import Payment from "../../InitiatePayment"
import Proccesses from "../../Processes"
import OplPrincipalOfficers from "../OplPrincipalOfficers"
import CreateNepzaService from "./CreateNepzaServices"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  Form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}))

export default function NepzaServices() {
  const [steps, setSteps] = useState([
    "Nepza Services",
    "Select specialities",
    ,
    "Select requirement",
    "Make Payments",
  ])
  const [activeStep, setActiveStep] = useState(3)
  const [serviceId, setServiceId] = useState("")
  const [process, setProcess] = useState({name: ""})
  const [request, setRequest] = useState({})
  const [paymentData, setPaymentData] = useState({})
  const [applicationId, setApplicationId] = useState("")
  const [category, setCategory] = useState({name: ""})
  const user = localStorage.getItem("userDetails")
  const [loading, setLoading] = useState(false)

  const userData = JSON.parse(user)
  const classes = useStyles()
  // const threeStepsProcess = ['Zone OPL', 'Enterprise Registration & CTC of Documents', 'Zone OPL Renewal Fees', 'Registry Search', 'Change of Ownership', 'Change of Name of Enterprise']

  return (
    <div>
      <AppBar elevation={0} className={classes.appBar}>
        <Stepper
          className={classes.root}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </AppBar>

      <div className={classes.container}>
        <Paper elevation={1} className={classes.paper}>
          {activeStep === 3 && (
            <CreateNepzaService
              setApplicationId={setApplicationId}
              currentStep={setActiveStep}
              serviceId={serviceId}
              setServiceId={setServiceId}
              setProcess={setProcess}
              processCat={setCategory}
              setRequest={setRequest}
              reqBody={request}
            />
          )}
          {/* {activeStep === 1 && (
            <Proccesses
              user={userData}
              applicationId={applicationId}
              currentStep={setActiveStep}
              setRequest={setRequest}
              process={process}
            />
          )}
          {activeStep === 2 && (
            <OplPrincipalOfficers
              applicationId={applicationId}
              process={process}
              serviceId={serviceId}
              setRequest={setRequest}
              reqBody={request}
              setRequest={setRequest}
              currentStep={setActiveStep}
            />
          )}
          {activeStep === 3 && (
            <UploadDocuments
              user={userData}
              applicationId={applicationId}
              process={process}
              serviceId={serviceId}
              reqBody={request}
              currentStep={setActiveStep}
              setPaymentData={setPaymentData}
            />
          )} */}
          {activeStep === 4 && (
            <Payment
              user={userData}
              applicationId={applicationId}
              serviceId={serviceId}
              reqBody={request}
              paymentData={paymentData}
            />
          )}
        </Paper>
      </div>
    </div>
  )
}
