import React from "react";

const Tab = ({ steps, activeStep }) => {
  return (
    <ul
      style={{
        listStyleType: "none",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: "47px",
        marginLeft: "1em",
      }}
    >
      {steps.map((step, index) => (
        <li
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "120%",
              height: "8px",
              background: index < activeStep ? "#47BB78" : "#C4C4C4",
              borderRadius: "6px",
            }}
          ></div>
          <p>{step}</p>
        </li>
      ))}
    </ul>
  );
};

export default Tab;
