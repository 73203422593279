import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import FormControl from "@material-ui/core/FormControl"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import CircularProgress from "@material-ui/core/CircularProgress"
import Swal from "sweetalert2"
import {Stepper, Step, StepLabel, MenuItem} from "@material-ui/core"
import {AppConfig} from "../../../app-config"
import axiosInstance from "Api/Api"
// import ZoneDeclaration from "./ZoneDeclaration"
// import UploadDocuments from "./UploadDocuments"

const useStyles = makeStyles(theme => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",

    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
    textTransform: "capitalize",
    width: "60%",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  formControl1: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  label: {
    color: "#000",
    marginBottom: "5px",
    fontSize: 16,
  },
}))

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CreateNepzaService({
  setApplicationId,
  processCat,
  setProcess,
  currentStep,
  setServiceId,
  serviceId,
}) {
  const [loading, setLoading] = useState(false)
  const [alert, showAlert] = useState(false)
  const [subSelected, isSubSelected] = useState(false)
  const [categories, setCategories] = useState([])
  const [category, setCategory] = useState("")
  const [cat, setCat] = useState({})
  const [subCategories, setSubCategories] = useState([])
  //   const [subCategory, setSubCategory] = useState('')
  const [selectedSubCat, setSelectedSub] = useState({})
  const [payBefore, setPayBefore] = useState("")
  const [assetValue, setAssetValue] = useState("")
  const [moneyValue, setMoneyValue] = useState("")
  const [renewalTimeline, setRenewalTimeline] = useState("Annual Renewal")
  const [specialties, setSpecialties] = useState([])
  const [specialty, setSpecialty] = useState("")
  const user = localStorage.getItem("userDetails")

  const userData = JSON.parse(user)
  const classes = useStyles()

  console.log(specialty)
  const selectSubCategory = e => {
    setServiceId(e.target.value)
    // setSubCategory(e.target.value);
    const sub = subCategories.find(element => element.id === e.target.value)
    setProcess(sub)
    // isSubSelected(true)
  }
  const formatAsMoney = fee => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee)
  }

  const selectCategory = async e => {
    setServiceId(e.target.value)
    setCategory(e.target.value)
    const cat = categories.find(element => element.id === e.target.value)
    setCat(cat)
    setProcess(cat)
    processCat(cat)
  }
  const getCategories = async () => {
    setLoading(true)

    try {
      const response = await fetch(`${AppConfig.URL}/start-reg/zmc-opl`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      })
      const {status, data, message} = await response.json()
      if (status === true) {
        setCategory(data.service)
      } else {
        Swal.fire("", message, "info")
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      if (!window.navigator.onLine) {
        Swal.fire("Internet", "Check your connection", "error")
      } else {
        Swal.fire("Something went wrong", "Unable to get record", "error")
      }
    }
  }

  React.useEffect(() => {
    getCategories()
  }, [])

  const handleSubmit = async () => {
    try {
      setLoading(true)
      let info
      let reqMethod = "POST"
      let url = AppConfig.URL + "/application/add"
      info = {
        company_id: userData["data"].company_id,
        service_id: category?.id,
        amount: String(specialty?.amount),
      }

      const {status, data} = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userData.access_token,
        },
        body: JSON.stringify(info),
      }).then(res => res.json())
      if (status) {
        setLoading(false)
        setApplicationId(data.id)
        showAlert(true)
        // currentStep((prevActiveStep) => prevActiveStep + 1);
        currentStep(prevActiveStep => {
          if (
            localStorage.getItem("name") === "Equitable" ||
            localStorage.getItem("name") === "Legal"
          ) {
            return prevActiveStep + 2
          } else if (localStorage.getItem("name") === "Registry Search") {
            return prevActiveStep + 3
          } else if (cat?.name === "Operating License (OPL)") {
            return prevActiveStep + 3
          } else {
            return prevActiveStep + 1
          }
        })
      } else if (!window.navigator.online) {
        Swal.fire("Internet", "Check your connection", "error")
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info")
        setLoading(false)
      }
    } catch (err) {
      console.log(err)
      Swal.fire("Something went wrong", "Unable to process request", "info")
      setLoading(false)
    } finally {
      setLoading(false)
    }
  }

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") return
    showAlert(false)
  }

  const handleValueChange = e => {
    let value = e.target.value
    const parsedValue = value.replace(/[^0-9]/g, "")
    setAssetValue(parsedValue)
    let toMoney = new Number(parsedValue).toLocaleString("en-US")
    setMoneyValue(toMoney)
  }

  const getSpecialties = () => {
    setLoading(true)
    axiosInstance
      .get("/speciality/zone")
      .then(function (response) {
        if (response.status === 200) {
          setSpecialties(response.data.data.speciality)
        } else {
          console.log(response)
        }
      })
      .catch(function (error) {
        console.log("error", error)
        // console.log(error.response.data.message);
      })
      .finally(() => setLoading(false))
  }

  React.useEffect(() => {
    getSpecialties()
  }, [])
  console.log(category)

  const handleSpecialty = e => {
    setSpecialty(e.target.value)
  }
  return (
    <div>
      <div>
        <form className={classes.form}>
          <div className={classes.textfields}>
            <Grid container>
              <Grid item xs={12}>
                <FormControl className={classes.formControl1}>
                  <label className={classes.label}>Services Type</label>
                  <TextField
                    id="outlined-error-helper-text"
                    className={classes.text}
                    variant="outlined"
                    name="serviceId"
                    value={category?.name}
                    disabled
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl1}>
                  <label className={classes.label}>Select Specialty</label>
                  <TextField
                    name="specialty"
                    select
                    required
                    style={{width: "100%"}}
                    // margin="normal"
                    type="text"
                    variant="outlined"
                    value={specialty.name}
                    onChange={handleSpecialty}
                  >
                    {specialties.map(option => (
                      <MenuItem key={option.id} value={option}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl1}>
                  <label className={classes.label}>Payment Required</label>
                  <TextField
                    variant="outlined"
                    value={renewalTimeline}
                    onChange={e => setRenewalTimeline(e.target.value)}
                    disabled
                    className={classes.text}
                    style={{width: "95%"}}
                  />
                </FormControl>
              </Grid> */}
              <Grid item xs={12} sm={6}>
                <FormControl className={classes.formControl1}>
                  <label className={classes.label}>Service Fee</label>
                  <TextField
                    name="service_fee"
                    required
                    style={{width: "100%"}}
                    // margin="normal"
                    type="text"
                    variant="outlined"
                    value={formatAsMoney(
                      specialty?.amount ? specialty.amount : ""
                    )}
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </div>
          <Button
            // autoFocus
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            className={classes.button}
            size="large"
            disabled={loading}
          >
            Submit Application
          </Button>
        </form>

        {loading && (
          <div className={classes.spinner}>
            <CircularProgress />
          </div>
        )}
      </div>
    </div>
  )
}
