import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
} from "@material-ui/core";
// import Tables from "./Dashboard/Table";
import AddTeam from "./ZmcDashboard/AddTeam"
import TabContext from '@material-ui/lab/TabContext';


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
}));

export default () => {
    const [loading, setLoading] =
        useState(false);
    const classes = useStyles();
    const [value, setValue] = React.useState('team');
    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };




    return (
        <div className={classes.root}>
            <div className={classes.tabs}>
                <TabContext value={value}>
                    <AddTeam />
                </TabContext>
            </div>
        </div>
    );
};
