import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined";
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        height: '500px',
        flexDirection: 'column',
        justifyContent: 'space-around'
    },
    containter: {
        minHeight: '100%'
    },
    success: {
        display: "flex",
        justify: "center"
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: "center",
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.background.default,
    },
    message: {
        paddingBottom: "20px",
    },
}));

export default function ZmcActionSubmitted() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Grid
                direction="column"
                justify="space-between"
                alignItems="center"
                container
                spacing={2}
                className={classes.container}
            >
                <Grid item xs={10}>
                    <Paper elevation={0} className={classes.paper}>
                        <CheckCircleOutlinedIcon
                            style={{ fontSize: 200, color: "#48BB78" }}
                        ></CheckCircleOutlinedIcon>
                    </Paper>
                </Grid>
                <Grid item xs={10} className={classes.message}>
                    <Typography variant="h5" className={classes.success} >
                        Your action and remarks have been successfully submitted
          </Typography>
                </Grid>
                <Grid item sm={10}>
                    <Link to="/me/zmc-dashboard">
                        {/* <Link to="/me/profile"> */}
                        <Button variant="contained" color="primary" component="span">
                            Back to Dashboard
            </Button>
                    </Link>
                </Grid>
            </Grid>
        </div>
    );
}
