import React, { useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Button, Box } from "@material-ui/core";
import CloudUploadSharpIcon from "@material-ui/icons/CloudUploadSharp";
import "./upload.css";
import axios from "axios";
import { AppConfig } from "../../app-config";
import { ErrorOutlineOutlined, ErrorOutlineSharp } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "4rem 0",
    flexDirection: "column",
  },
}));

const UploadDocs = ({ files, setFiles, removeFile, doc }) => {
  const url = AppConfig.URL;
  console.log(url);
  const uploadHandler = (e) => {
    const file = e.target.files[0];
    file.isUploading = true;
    setFiles([...files, file]);

    //upload file
    const formData = new FormData();
    formData.append(file.name, file, file.name);

    axios
      .post("ur/", formData)
      .then((res) => {
        file.isUploading = false;
        setFiles([...files, file]);
      })
      .catch((err) => {
        console.error(err);
        removeFile(file.name);
      });
  };

  const classes = useStyles();
  const theme = useTheme();

  return (
    <>
      <div className="file__card">
        <div style={{ paddingLeft: "20px" }} className="file__inputs">
          <input type="file" onChange={uploadHandler} />

          <br />
          {/* <Button className="btn">
          <CloudUploadSharpIcon className="icon" />
        </Button> */}
          {/* Upload */}
        </div>
        <p>Upload Documents</p>

        <a
          style={{
            fontWeight: "bold",
          }}
          href={doc}
          download
        >
          Download Document Form
        </a>
      </div>
      {/* <div
        style={{
          display: "flex",
          alignSelf: "flex-start",
          marginLeft: "0.8rem",
        }}
      >
        <Box>
          <a
            style={{
              fontWeight: "bold",
            }}
            href={doc}
            download
          >
            Download Document Form
          </a>
        </Box>
      </div> */}
    </>
  );
};

export default UploadDocs;
