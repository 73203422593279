import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { AppConfig } from "../app-config";

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    marginBottom: 8,
    color: "grey",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
    margin: "8rem 0",
  },
  text: {
    width: "40%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectZmc({
  user,
  applicationId,
  currentStep,
  setRequest,
}) {
  const [zoneZmcPair, setZoneZmcPair] = useState({
    zone: "",
    zmc: "",
  });

  const [loading, setLoading] = useState(false);
  const [zmcs, setZmcs] = useState([]);
  const [alert, showAlert] = useState(false);
  const [zones, setZones] = useState([]);
  const userData = JSON.parse(localStorage.getItem("userDetails"));

  const classes = useStyles();
  const options = zmcs?.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
  const [optionValue, setOptionValue] = React.useState(options[0]);
  const handleChange = (event) => {
    setZoneZmcPair({
      ...zoneZmcPair,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const { zone, zmc } = zoneZmcPair;

    const body = {
      application_id: applicationId,
      company_id: user["data"].company_id,
      zone: zone,
      zmc: zmc,
    };

    setRequest(body);

    setZoneZmcPair({
      zone: "",
      zmc: "",
      application_zonescol: "",
      applicaiton_id: "",
      user_id: "",
    });
    currentStep((prevActiveStep) => prevActiveStep + 1);

    showAlert(true);
  };

  const getZones = () => {
    setZones(userData.data.company.zones);
  };

  const getZmcs = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/zmc/list-approved`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setZmcs(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to load states", "error");
      setLoading(false);
    }
  };

  React.useEffect(() => {
    getZones();
    getZmcs();
  }, []);

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    showAlert(false);
  };

  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          ZMC selected successfully!
        </Alert>
      </Snackbar>

      <div>
        <form className={classes.form} noValidate autoComplete="off">
          <Typography variant="h6" className={classes.title}>
            Select Zone
          </Typography>
          <TextField
            label="Zone"
            name="zone"
            select
            required
            className={classes.text}
            type="text"
            variant="outlined"
            onChange={(e) => handleChange(e)}
          >
            {zones?.map((option) => (
              <MenuItem key={option.zone_id} value={option.zone_id}>
                {option.zone_name}
              </MenuItem>
            ))}
          </TextField>
          <Typography variant="h6" className={classes.title}>
            Select ZMC
          </Typography>
          {/* <TextField
                        label="ZMC"
                        name="zmc"
                        select
                        required
                        className="dropdown-toggle js-example-basic-single"
                        type="text"
                        variant="outlined"
                        onChange={(e) => handleChange(e)}
                        style={{
                            width: "40%",
                            marginBottom: "1.4rem"
                        }}
                    >
                        {zmcs.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.name}
                            </MenuItem>
                        ))}
                    </TextField> */}
          <Autocomplete
            value={optionValue}
            onChange={(event, newValue) => {
              setZoneZmcPair({
                ...zoneZmcPair,
                zmc: newValue.id,
              });
            }}
            id="grouped-demo"
            options={options.sort(
              (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
            )}
            getOptionLabel={(option) => option.name}
            style={{ width: "40%" }}
            renderInput={(params) => (
              <TextField {...params} label="Select ZMC" variant="outlined" />
            )}
          />

          {
            <Button
              variant="outlined"
              color="primary"
              onClick={handleSubmit}
              className={classes.button}
              size="large"
              // disabled={loading || !zoneZmcPair.zone || !zoneZmcPair.zmc}
            >
              Continue
            </Button>
          }
        </form>
        {loading && (
          <div className={classes.spinner}>
            <CircularProgress />
            <Typography
              component="span"
              variant="body2"
              className={classes.inline}
              color="initial"
            >
              Submiting...
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
}
