import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React, { useEffect } from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "pages/Header.js";
import tw from "twin.macro";
import LoginPage from "pages/Login.js";
import SignupPage from "pages/Signup.js";
import { createTheme } from "@material-ui/core/styles";
import purple from "@material-ui/core/colors/purple";
import green from "@material-ui/core/colors/green";

/*
 * This is the entry point component of this project. You can change the below exported default App component to any of
 * the prebuilt landing page components by uncommenting their import and export lines respectively.
 * See one of the landing page components to better understand how to import and render different components (Always
 * make sure if you are building your own page, the root component should be the AnimationRevealPage component. You can
 * disable the animation by using the disabled prop.
 *
 * The App component below is using React router to render the landing page that you see on the live demo website
 * and the component previews.
 *
 */

/* Use AnimationRevealPage as a wrapper component for your pages if you are custom building it */
// import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import LandingPage from "LandingPage.js";

import ComponentRenderer from "ComponentRenderer.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import CreateCompany from "pages/Createcompany";
import Account from "./pages/Account/Account";
import AdminAccount from "./pages/Account/AdminAccount";
import axios from "axios";
import CompletePayment from "pages/CompletePayment.js";
import { ThemeProvider } from "@material-ui/core/styles";
import ErrorHandler from "../src/pages/ErrorHandler/ErrorHandler";
import ForgotPassword from "pages/ForgotPassword.js";
import ResetPassword from "pages/ResetPassword.js";
import InitiatePayment from "pages/InitiatePayment.js";
import MockRemittaPage from "pages/MockPayment/MockRemittaPage";
import CompanyRequestDetials from "pages/CompanyRequest/CompanyRequestDetails";
import Search from "pages/Search.js";
import ParticularsOfDirectors from "pages/EnterpriseDashboard/ParticularsOfDirectors";
// import AllotmentForm from "pages/EnterpriseDashboard/AllotmentForm.js";
import DeclarationOfCompliance from "pages/EnterpriseDashboard/DeclarationOfCompliance.js";
import RegisteredOffice from "pages/EnterpriseDashboard/RegisteredOffice.js";
import CreateEnterprise from "pages/CreateEnterprise.js";
import EnterpriseDetails from "pages/ZMC/EnterpriseDetails";
import UserProfile from "pages/UserProfile";

window.axios = axios;

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;

const theme = createTheme({
  palette: {
    primary: {
      main: "#47BB78",
      contrastText: "#fff",
    },
    secondary: {
      main: green[100],
    },
    contrastText: "#fff",
    text: {
      // primary: "#fafafa",
    },
  },
  typography: {
    fontFamily: "Jakarta",
  },
});

export default function App() {
  // return <AnimationRevealPage disabled></AnimationRevealPage>;
  return (
    <ErrorHandler>
      <ThemeProvider theme={theme}>
        <Router>
          {/* <PrimaryBackgroundContainer>
        <Header />
      </PrimaryBackgroundContainer> */}

          <Switch>
            {/* <Route path="/me/history-request-details">
              <AdminAccount />
            </Route> */}
            <Route path="/me">
              <Account />
            </Route>
            <Route path="/admin-account">
              <AdminAccount />
            </Route>
            <Route path="/finalize">
              <CompletePayment />
            </Route>
            <Route path="/mock-remitta-payment">
              <MockRemittaPage />
            </Route>
            <Route path="/login">
              <LoginPage />
            </Route>
            <Route path="/register-enterprise">
              <CreateEnterprise />
            </Route>
            <Route path="/forgot-password">
              <ForgotPassword />
            </Route>
            <Route path="/reset-password">
              <ResetPassword />
            </Route>
            <Route path="/register">
              <CreateCompany />
            </Route>
            <Route path="/search">
              <Search />
            </Route>

            <Route path="/profile">
              <UserProfile />
            </Route>

            {/* <Route path="/allotment-form">
              <AllotmentForm />
            </Route> */}

            <Route path="/directors-particulars">
              <ParticularsOfDirectors />
            </Route>

            <Route path="/declaration-of-compliance">
              <DeclarationOfCompliance />
            </Route>

            <Route path="/registered-office">
              <RegisteredOffice />
            </Route>

            <Route path="/make-payment">
              <InitiatePayment />
            </Route>
            <Route path="/">
              <LandingPage />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </ErrorHandler>
  );
}
