
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";
import Swal from "sweetalert2";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { deepPurple, deepOrange } from "@material-ui/core/colors";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { AppConfig } from "app-config";
import CreateUsers from "./CreateUsers";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "2rem auto"
    },
    root: {
        width: "80%",
        maxWidth: "80vw",
        backgroundColor: theme.palette.background.paper,
        margin: "1rem auto 0"
    },
    spinner: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%"
    },
    mobileRoot: {
        width: "100%",
        maxWidth: "91vw",
        backgroundColor: theme.palette.background.paper,
        margin: "3rem auto 0"
    },
    inline: {
        display: "inline-block",
        marginTop: "2rem",
        paddingRight: "2rem",
        wordBreak: "break-word"
    },
    large: {
        width: theme.spacing(9),
        height: theme.spacing(9),
        marginRight: "1rem"
    },
    createBtn: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 5rem",
        marginBottom: '2rem'
    },
    createBtnMobile: {
        display: "flex",
        justifyContent: "flex-end",
        padding: "0 1.5rem"
    }
}));

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function Users() {
    const matches = useMediaQuery("(min-width:768px)");
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [modalType, setModalType] = React.useState("");

    const [alert, showAlert] = React.useState(false);
    const [user, setUser] = React.useState({});
    const [users, setUsers] = React.useState([]);
    const [createModal, showCreateModal] = React.useState(false);

    const userDetails = localStorage.getItem('userDetails');

    const userData = JSON.parse(userDetails)

    const userPermission = localStorage.getItem("userPermission")

    const handleCreateModalOpen = () => {
        setModalType("create");
        showCreateModal(true);
    };

    const handleCreateModalClose = () => {
        showCreateModal(false);
    };

    const handleUpdateModalOpen = (id) => {
        setModalType("edit");
        const filteredUsers = users.filter((user) => user.id === id);
        setUser(filteredUsers[0]);
        showCreateModal(true);
        console.log(filteredUsers);
    };

    React.useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        setLoading(true);

        try {
            const url = AppConfig.URL +
                "/company/get_users";
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${userData.access_token}`
                }
            });
            const { data, message, status } = await response.json();
            if (status) {
                console.log(data)
                setUsers(data.data);
                setLoading(false);
            } else {
                setUsers([]);
                setLoading(false);
                Swal.fire('', message, 'info');
            }
        } catch (error) {
            setLoading(false);
            Swal.fire('Something went wrong', 'Unable to get users', 'error');
        }
    };

    const deleteUsers = (id) => {
        Swal.fire({
            title: "Are you sure?",
            text: "This action cannot be undone!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Delete"
        }).then(async (result) => {
            if (result.value) {
                setLoading(true);
                try {
                    const url = AppConfig.URL + `/users/${id}/delete`;

                    const { status, message } = await fetch(url, {
                        method: "DELETE",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + sessionStorage["token"]
                        }
                    }).then((res) => res.json());
                    if (status) {
                        getUsers();
                        showAlert(true);
                    } else {
                        Swal.fire({
                            icon: "error",
                            title: "Something went wrong",
                            text: "Could not delete Users, Please try again!"
                        });
                    }
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    Swal.fire({
                        icon: "error",
                        title: "Something went wrong",
                        text: "Unable to delete record"
                    });
                }
            }
        });
    };

    const closeAlert = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        showAlert(false);
    };

    if (loading) {
        return (
            <div className={classes.spinner}>
                <CircularProgress />
            </div>
        );
    }

    return (
        <div className={classes.container}>
            <Snackbar
                open={alert}
                autoHideDuration={2000}
                onClose={closeAlert}
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
                <Alert onClose={closeAlert} severity="success">
                    User Deleted Successfully!
        </Alert>
            </Snackbar>
            <CreateUsers
                handleClose={handleCreateModalClose}
                open={createModal}
                data={users}
                type={modalType}
                getUsers={getUsers}
                userData={user}
            />
            {console.log(users)}

            {
                userPermission === "company-admin" ?

                    <div className={matches ? classes.createBtn : classes.createBtnMobile}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={handleCreateModalOpen}
                        >
                            Create User
                    </Button>
                    </div> : null
            }
            {/* {
                userPermission === "company-admin" ? */}
            <Paper className="container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>S/N</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users && users.map((user, index) => (
                            <TableRow key={user.id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{user.fullname}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                {
                                    userPermission === "company-admin" ?
                                        <TableCell>
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => handleUpdateModalOpen(user.id)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            {matches ? "\u00A0\u00A0\u00A0\u00A0\u00A0" : "\u00A0"}

                                            {matches ? "\u00A0\u00A0\u00A0\u00A0\u00A0" : "\u00A0"}
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => deleteUsers(user.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell> : null
                                }
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
            {/* : null} */}
        </div>
    );
}



