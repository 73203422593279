import DashboardIcon from "../../images/dashboardIcon.svg";
import statusUp from "../../images/status-up.svg";
import location from "../../images/location.svg";
import boxIcon from "../../images/box.svg";
import walletIcon from "../../images/empty-wallet-change.svg";
import EnterpriseIcon from "../../images/myEnterprise.svg";
import UserProfile from "pages/UserProfile";

const ZoneAdminMenuItems = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    href: "/admin-account/zone-admin-dashboard",
    id: [2],
  },

  {
    title: "Applications",
    icon: boxIcon,
    href: "/admin-account/zone-admin-applications",
    id: [2],
  },
  {
    // title: "Test Enterprises",
    // icon: EnterpriseIcon,
    // href: "/admin-account/zone-admin-enterprises/profile",
    // id: [2],
  },
  {
    title: "My Enterprises",
    icon: EnterpriseIcon,
    href: "/admin-account/zone-admin-ent",
    id: [2],
  },
  {
    title: "Revenues",
    icon: walletIcon,
    href: "/admin-account/zone-admin-revenue",
    id: [2],
  },
];

const Menuitems = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    href: "/admin-account/nepza-admin",
    id: [5, 7],
  },
  {
    title: "Zone management requests",
    icon: statusUp,
    href: "/admin/zmRequests",
    id: [5, 7],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/zone-dashboard",
      },
      {
        title: "Applications",
        href: "/admin-account/zone-applications",
      },
      {
        title: "Request inventory",
        href: "/admin-account/zminventory",
      },
    ],
  },
  {
    title: "Free Zone requests",
    icon: location,
    href: "/donation-types",
    id: [5, 7],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/free-zone-dashboard",
      },
      {
        title: "Applications",
        href: "/admin-account/free-zone-applications-new",
      },
      {
        title: "Request inventory",
        href: "/admin-account/fzinventory",
      },
    ],
  },
  {
    title: "Enterprise",
    icon: boxIcon,
    id: [5, 7],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/enterprise-dashboard",
      },
      {
        title: "Applications",
        href: "/admin-account/enterprise-applications",
      },
      {
        title: "Request inventory",
        href: "/admin-account/enterpriseinventory",
      },
    ],
    href: "/admin/gift-aid",
  },
  {
    title: "Revenues",
    icon: walletIcon,
    id: [5, 7],
    href: "/admin-account/revenues",
  },
  {
    title: "Dashboard",
    icon: DashboardIcon,
    href: "/admin-account/finance-md",
    id: [6],
  },
  {
    title: "Application",
    icon: walletIcon,
    href: "/admin-account/finance-applications",
    id: [6],
  },
  {
    title: "Zone management Revenue",
    icon: statusUp,
    href: "/admin-account/finance-revenue",
    id: [6],
  },
  {
    title: "FreeZone Revenue",
    icon: location,
    href: "/admin-account/finance-fzr",
    id: [6],
  },
  {
    title: "Enterprise Revenue",
    icon: boxIcon,
    href: "/admin-account/finance-er",
    id: [6],
  },
  {
    title: "Rate Calculator",
    icon: walletIcon,
    href: "/admin-account/rate-calculate",
    id: [6],
  },
];

const FreeZoneManagerPublic = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    href: "/admin-account/fzm-public",
    id: [3],
  },
  {
    title: "Zone management requests",
    icon: statusUp,
    href: "/admin/zmRequests",
    id: [3],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/admin-zone-dashboard",
      },
      {
        title: "Applications",
        href: "/admin-account/zone-management-requests-applications",
      },
      {
        title: "Request Inventory",
        href: "/admin-account/zone-request-inventory",
      },
    ],
  },
  {
    title: "Free Zone requests",
    icon: location,
    href: "/donation-types",
    id: [3],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/admin-freezone-dashboard",
      },
      {
        title: "Applications",
        href: "/admin-account/free-zone-applications",
      },
      {
        title: "Request inventory",
        href: "/admin-account/free-zone-request-inventory",
      },
    ],
  },

  {
    title: "Revenues",
    icon: walletIcon,
    href: "/admin-account/free-zone-revenues",
    id: [3],
  },
];

const FreeZoneManagerPrivate = [
  {
    title: "Dashboard",
    icon: DashboardIcon,
    href: "/admin-account/fzm-public",
    id: [4],
  },
  {
    title: "Zone management requests",
    icon: statusUp,
    href: "/admin/zmRequests",
    id: [4],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/zonemanagement-dashboard-private",
      },
      {
        title: "Applications",
        href: "/admin-account/zonemanagement-application-private",
      },
      {
        title: "Request Inventory",
        href: "/admin-account/zonemanagement-request-private",
      },
    ],
  },
  {
    title: "Free Zone requests",
    icon: location,
    href: "/donation-types",
    id: [4],
    group: [
      {
        title: "Dashboard",
        href: "/admin-account/freezone-dashboard-private",
      },
      {
        title: "Applications",
        href: "/admin-account/freezone-application-private",
      },
      {
        title: "Request inventory",
        href: "/admin-account/free-zone-request-inventory",
      },
    ],
  },

  {
    title: "Revenues",
    icon: walletIcon,
    href: "/admin-account/free-zone-revenues",
    id: [4],
  },
];

export {
  Menuitems,
  ZoneAdminMenuItems,
  FreeZoneManagerPublic,
  FreeZoneManagerPrivate,
};
