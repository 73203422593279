import React from "react";
import "./checklist.css";
import form1A from "../../assets/form1A.pdf";
import form1B from "../../assets/form1B.pdf";
import form2A from "../../assets/form2A.pdf";
import form2B from "../../assets/form2B.pdf";
import form3 from "../../assets/form3.pdf";
import form5 from "../../assets/form5.pdf";
import form4A from "../../assets/form4A.pdf";
import form4B from "../../assets/form4b.pdf";
import { Paper } from "@material-ui/core";

const CheckList = () => {
  return (
    <div className="checklist">
      <Paper style={{ padding: "3em" }}>
        <h1>Requirements for the registration of an enterprise</h1>
        <ul>
          <li>
            Application letter for registration from the Enterprise through the
            Zone Administrator
          </li>

          <li>
            Particulars of Directors ( Download{" "}
            <span>
              {" "}
              <a href={form1A} download>
                FZR Form 1A
              </a>
            </span>
            <span>and </span>
            <span>
              <a href={form1B} download>
                FZR Form 1B
              </a>
            </span>
            )
          </li>
          <li>
            Return of allotment form/statement of share capital ( Download{" "}
            <span>
              {" "}
              <a href={form2A} download>
                FZR Form 2A
              </a>
            </span>
            <span>and </span>
            <span>
              <a href={form2B} download>
                FZR Form 2B
              </a>
            </span>
            )
          </li>
          <li>
            Declaration of compliance with requirements of the NEPZA Act (
            Download{" "}
            <span>
              {" "}
              <a href={form3} download>
                FZR Form 3
              </a>
            </span>
            )
          </li>
          <li>
            Address of Registered Office in the Free Zone ( Download{" "}
            <span>
              {" "}
              <a href={form5} download>
                FZR Form 5
              </a>
            </span>
            )
          </li>
          <li>Board resolution appointing the directors of the Enterprise</li>
          <li>
            Board resolution of the Enterprise approving the registration of the
            Enterprise in the Zone
          </li>
          <li>Memorandum and Articles of Association of the Enterprise</li>
          <li>Passport photographs of the directors</li>
          <li>Data page of international passport of the directors</li>
          <li>Evidence of payment for Application processing</li>
          <li>
            Annual Returns ( Download{" "}
            <span>
              {" "}
              <a href={form4A} download>
                FZR Form 4A
              </a>
            </span>
            <span>and </span>
            <span>
              <a href={form4B} download>
                FZR Form 4B
              </a>
            </span>
            )
          </li>
        </ul>
      </Paper>
    </div>
  );
};

export default CheckList;
