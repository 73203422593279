import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Dialog } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import { AppConfig } from "../../app-config";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  form: {
    // width: "100%",
    display: "flex",
    // height: "50px",
    alignItems: "center",
    justifyContent: "center",
    // padding: "8rem 0",
    marginRight: "15px",
    flexDirection: "column",
    margin: "1rem",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  textField: {
    marginRight: "15px",
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditTeam({
  handleClose,
  open,
  getTeams,
  data,
  type,
  userData,
}) {
  const [team, setTeam] = React.useState({
    firstname: "",
    surname: "",
    middlename: "",
    nationality: "",
    address: "",
    designation: "",
    dob: "",
    year_appointed: "",
    status: "",
    passport: "",
  });
  const [error, setError] = React.useState({
    firstname: "",
    surname: "",
    middlename: "",
    nationality: "",
    address: "",
    designation: "",
    dob: "",
    year_appointed: "",
    status: "",
    passport: "",
  });

  const [loading, setLoading] = React.useState(false);

  const [alert, showAlert] = React.useState(false);

  const classes = useStyles();
  const userDetails = localStorage.getItem("userDetails");
  const userToken = JSON.parse(userDetails);

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setTeam({
      ...team,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    // var body = {
    //     firstname: team.firstname ? team.firstname : userData.firstname,
    //     surname: team.surname ? team.surname : userData.surname,
    //     middlename: team.middlename ? team.middlename : userData.middlename,
    //     nationality: team.nationality ? team.nationality : userData.nationality,
    //     address: team.address ? team.address : userData.address,
    //     designation: team.designation ? team.designation : userData.designation,
    //     dob: team.dob ? team.dob : userData.dob,
    //     year_appointed: team.year_appointed ? team.year_appointed : userData.year_appointed,
    //     status: team.status ? team.status : userData.status,
    //     passport: team.passport ? team.passport : userData.passport,
    //     id: userData.id
    // }
    const formdata = new FormData();
    formdata.append(`id`, userData.id);
    formdata.append(
      `firstname`,
      team.firstname ? team.firstname : userData.firstname
    );
    formdata.append(`surname`, team.surname ? team.surname : userData.surname);
    formdata.append(
      `middlename`,
      team.middlename ? team.middlename : userData.middlename
    );
    formdata.append(`address`, team.address ? team.address : userData.address);
    formdata.append(
      `nationality`,
      team.nationality ? team.nationality : userData.nationality
    );
    formdata.append(
      `designation`,
      team.designation ? team.designation : userData.designation
    );
    formdata.append(`dob`, team.dob ? team.dob : userData.dob);
    formdata.append(`status`, team.status ? team.status : userData.status);
    formdata.append(
      `year_appointed`,
      team.year_appointed ? team.year_appointed : userData.year_appointed
    );
    if (team.passport) {
      formdata.append(`passport`, team.passport);
    }

    var userToken = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails")).access_token
      : null;
    try {
      setLoading(true);
      const { status } = await fetch(`${AppConfig.URL}/zmc/team/update`, {
        method: "POST",
        headers: {
          Accept: "application/json*",
          Authorization: "Bearer " + userToken,
        },
        body: formdata,
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        setTeam({
          firstname: "",
          surname: "",
          middlename: "",
          nationality: "",
          address: "",
          designation: "",
          dob: "",
          year_appointed: "",
          status: "",
          passport: "",
          id: "",
        });
        showAlert(true);
        setTimeout(() => {
          handleClose();
          getTeams();
        }, 1000);
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  };

  const closeModal = () => {
    setTeam({
      firstname: "",
      surname: "",
      middlename: "",
      nationality: "",
      address: "",
      designation: "",
      dob: "",
      year_appointed: "",
      status: "",
      passport: "",
      id: "",
    });
    setError({
      firstname: "",
      surname: "",
      middlename: "",
      nationality: "",
      address: "",
      designation: "",
      dob: "",
      year_appointed: "",
      status: "",
      passport: "",
    });
    handleClose();
  };

  const updateTeam = () => {
    setTeam(data);
  };

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showAlert(false);
  };
  const handleFileChange = (e) => {
    console.log(e.target.files);
    setError({ ...error, [e.target.name]: "" });
    setTeam({
      ...team,
      [e.target.name]: e.target.files[0],
    });
  };
  return (
    <div>
      <Snackbar
        open={alert}
        autoHideDuration={2000}
        onClose={closeAlert}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert onClose={closeAlert} severity="success">
          "Director updated successfully!"
        </Alert>
      </Snackbar>
      <Dialog open={open} onClose={closeModal} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <Typography variant="h6" className={classes.title}>
              Edit Director
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              disabled={loading}
              onClick={closeModal}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className={classes.container}>
          <Paper elevation={3} className={classes.paper}>
            <form className={classes.form} noValidate autoComplete="off">
              <div className={classes.textDiv}>
                <TextField
                  label="First name"
                  name="firstname"
                  error={!!error.firstname}
                  required
                  className={classes.textField}
                  helperText={error.firstname ? error.firstname : ""}
                  margin="normal"
                  type="text"
                  variant="outlined"
                  defaultValue={userData.firstname}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  label="Surname"
                  name="surname"
                  error={!!error.surname}
                  required
                  className={classes.textField}
                  helperText={error.surname ? error.surname : ""}
                  margin="normal"
                  type="text"
                  variant="outlined"
                  defaultValue={userData.surname}
                  onChange={(e) => handleChange(e)}
                />
              </div>
              <div className={classes.textDiv}>
                <TextField
                  label="Nationality"
                  name="nationality"
                  error={!!error.nationality}
                  required
                  className={classes.textField}
                  helperText={error.nationality ? error.nationality : ""}
                  margin="normal"
                  type="text"
                  variant="outlined"
                  defaultValue={userData.nationality}
                  onChange={(e) => handleChange(e)}
                />
                <TextField
                  error={!!error.status}
                  helperText={error.status ? error.status : ""}
                  margin="normal"
                  select
                  // className={classes.select}
                  className={classes.textField}
                  variant="outlined"
                  style={{ width: "220px" }}
                  label="Select a Status"
                  name="status"
                  defaultValue={userData.status}
                  onChange={(e) => handleChange(e)}
                >
                  <MenuItem value="appointed">Appointed</MenuItem>
                  <MenuItem value="removed">Removed</MenuItem>
                  <MenuItem value="reappointed">Reappointed</MenuItem>
                  <MenuItem value="resigned">Resigned</MenuItem>
                </TextField>
              </div>
              <div className={classes.textDiv}>
                <TextField
                  label="DOB"
                  name="dob"
                  error={!!error.dob}
                  required
                  className={classes.textField}
                  helperText={error.dob ? error.dob : ""}
                  margin="normal"
                  type="date"
                  variant="outlined"
                  defaultValue={userData.dob}
                  onChange={(e) => handleChange(e)}
                  style={{ marginLeft: "9px" }}
                />
                <TextField
                  label="Year of appointment"
                  name="year_appointed"
                  error={!!error.year_appointed}
                  required
                  className={classes.textField}
                  helperText={error.year_appointed ? error.year_appointed : ""}
                  margin="normal"
                  type="text"
                  variant="outlined"
                  defaultValue={userData.year_appointed}
                  onChange={(e) => handleChange(e)}
                />
              </div>

              <div className={classes.textDiv}>
                <TextField
                  // label="Passport"
                  name="passport"
                  error={!!error.passport}
                  required
                  className={classes.textField}
                  helperText={error.passport ? error.passport : ""}
                  margin="normal"
                  style={{ width: "460px" }}
                  type="file"
                  variant="outlined"
                  // value={Company.designation}
                  onChange={handleFileChange}
                />
              </div>
              <div className={classes.textDiv}></div>

              <Button
                variant="outlined"
                color="primary"
                onClick={handleSubmit}
                className={classes.button}
                size="large"
                disabled={loading}
              >
                Submit
              </Button>
            </form>
            {loading && (
              <div className={classes.spinner}>
                <CircularProgress />
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="initial"
                >
                  Submiting User...
                </Typography>
              </div>
            )}
          </Paper>
        </div>
      </Dialog>
    </div>
  );
}
