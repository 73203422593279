import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Button,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { ClearAll } from "@material-ui/icons";
import Payment from "../InitiatePayment";

import UploadEnterpriseDocuments from "./UploadEnterpriseDocuments";
import RequestForm from "./RequestForm";
import EnterpriseRegistration from "./EnterpriseRegistration";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  root: {
    width: "100%",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    minWidth: "300px",
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "50px",
    alignItems: "center",
    justifyContent: "center",
    padding: "8rem 0",
    flexDirection: "column",
  },
  textfields: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    width: "70%",
    marginBottom: "1.4rem",
  },
  select: {
    width: "30%",
    margin: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  warningText: {
    textAlign: "center",
  },
}));

const StartEnterpriseRequest = () => {
  const [steps, setSteps] = useState([
    "Start Application",
    "Fill Request Form",
    "Upload Documents",
    "Make Payments",
  ]);
  const [activeStep, setActiveStep] = useState(0);
  const [category, setCategory] = useState({ name: "" });
  const [serviceId, setServiceId] = useState("");
  const [request, setRequest] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [applicationId, setApplicationId] = useState("");
  const [process, setProcess] = useState({ name: "" });
  const [appName, setAppName] = useState("");

  //use defined styles
  const classes = useStyles();

  //get user data from local storage
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  console.log("CHECK!!!", userData);

  const handleReset = () => {
    setActiveStep(0);
    setSteps([
      "Start Application",
      "Fill Request Form",
      "Upload Documents",
      "Make Payment",
    ]);
    window.location.reload();
  };

  const threeStepsProcess = [
    "Operating License (OPL)",
    "Registration of Instrument",
    "Registration of Asset Valuation",
    "Certified True Copies",
    "Operational Charges",
    "Immigration Services",
    "POST REGISTRATION/OPL RENEWAL SERVICES",
    "Others",
  ];

  const certificateProcess = ["Certificate of Registration"];

  useEffect(() => {
    if (threeStepsProcess.includes(category.name)) {
      setSteps(["Start Application", "Upload Documents", "Make Payment"]);
    }
  }, [category.name]);

  useEffect(() => {
    console.log("PROCESS:", process.name);
    const pay = localStorage.getItem("paybefore");
    const name = localStorage.getItem("name");
  }, []);

  useEffect(() => {}, []);

  return (
    <div>
      <AppBar elevation={0} className={classes.appBar}>
        {/* <Button
            style={{ fontWeight: 700, color: "#ffffff" }}
            startIcon={<ClearAll />}
            onClick={handleReset}
          >
            Reset
          </Button> */}
        {activeStep >= 0 ? (
          <Stepper
            className={classes.root}
            activeStep={activeStep}
            alternativeLabel
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        ) : null}
      </AppBar>
      {!threeStepsProcess.includes(category.name) && (
        <>
          <div className={classes.container}>
            <Paper elevation={1} className={classes.paper}>
              {activeStep === 0 && (
                <>
                  <EnterpriseRegistration
                    setApplicationId={setApplicationId}
                    serviceId={serviceId}
                    setServiceId={setServiceId}
                    setProcess={setProcess}
                    currentStep={setActiveStep}
                  />
                </>
              )}

              {activeStep === 1 && (
                <RequestForm
                  setRequest={setRequest}
                  changeStep={() => {
                    setActiveStep(activeStep + 1);
                  }}
                />
              )}

              {activeStep === 2 && (
                <UploadEnterpriseDocuments
                  user={userData}
                  applicationId={applicationId}
                  process={process}
                  serviceId={serviceId}
                  reqBody={request}
                  currentStep={setActiveStep}
                  setPaymentData={setPaymentData}
                  appName={appName}
                />
              )}
              {activeStep === 3 && (
                <Payment
                  user={userData}
                  applicationId={applicationId}
                  serviceId={serviceId}
                  reqBody={request}
                  paymentData={paymentData}
                />
              )}
              {/* {activeStep === 2 && <UploadDocuments />} */}
            </Paper>
          </div>
          <div className={classes.warningText}>
            {activeStep === 0 && (
              <Typography style={{ color: "#E72E2E", marginTop: "8rem" }}>
                <strong>Please note</strong>: In other to apply for your
                enterprise Operating License and have access to Nepza services,{" "}
                <br /> you need an approved certificate of registration. Click
                the start application button to apply for one.
              </Typography>
            )}
          </div>
        </>
      )}
      {/* {threeStepsProcess.includes(category.name) ? (
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            {activeStep === 0 && (
              <CreateApplication
                setApplicationId={setApplicationId}
                currentStep={setActiveStep}
                serviceId={serviceId}
                setServiceId={setServiceId}
                setProcess={setProcess}
                processCat={setCategory}
              />
            )}
            {activeStep === 2 && localStorage.getItem("payBefore") === "1" ? (
              <Payment
                user={userData}
                applicationId={applicationId}
                serviceId={serviceId}
                reqBody={request}
                paymentData={paymentData}
              />
            ) : (
              activeStep === 2 && <Finish />
            )}
          </Paper>
        </div>
      ) : (
        <div className={classes.container}>
          <Paper elevation={1} className={classes.paper}>
            {activeStep === 0 && (
              <CreateApplication
                setApplicationId={setApplicationId}
                currentStep={setActiveStep}
                serviceId={serviceId}
                setServiceId={setServiceId}
                setProcess={setProcess}
                processCat={setCategory}
              />
            )}
            {activeStep === 1 && (
              <Proccesses
                user={userData}
                applicationId={applicationId}
                currentStep={setActiveStep}
                setRequest={setRequest}
                process={process}
              />
            )}
            {activeStep === 1 && localStorage.getItem("payBefore") === "1" && (
              <RequestForm
                changeStep={() => {
                  setActiveStep(activeStep + 1);
                }}
              />
            )}
            {activeStep === 2 && (
              <UploadDocuments
                user={userData}
                applicationId={applicationId}
                process={process}
                serviceId={serviceId}
                reqBody={request}
                currentStep={setActiveStep}
                setPaymentData={setPaymentData}
              />
            )}
            {activeStep === 3 &&
            (localStorage.getItem("payBefore") === "1" ||
              localStorage.getItem("name") === "Free Zone" ||
              localStorage.getItem("name") === "Industrial Park") ? (
              <Payment
                user={userData}
                applicationId={applicationId}
                serviceId={serviceId}
                reqBody={request}
                paymentData={paymentData}
              />
            ) : (
              activeStep === 3 && <Finish />
            )}
          </Paper>
        </div>
      )} */}
    </div>
  );
};

export default StartEnterpriseRequest;
