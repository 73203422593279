import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { AppConfig } from "app-config";
import { useHistory } from "react-router-dom";

import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 0",
    background: "#fff",
    padding: "2em",
  },
  btnGroup: {
    margin: "2em 0",
  },
  btn: {
    textTransform: "capitalize",
    fontSize: "16px",
    outline: "none",
    marginRight: "20px",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    fontSize: "16px",
    color: "rgba(176, 174, 174, 1)",
    padding: "1em 0",
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableRow: {
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableData: {
    color: "rgba(31, 31, 31, 1)",
    fontWeight: "500",
    fontSize: "17px",
    padding: "1em 0",
  },
  tableData1: {
    color: "rgba(239, 163, 75, 1)",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "1em 0",
  },
}));
const EnterpriseApplications = () => {
  const classes = useStyles();
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const formatDate = (date) => {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(date);
    return data.toLocaleDateString("en-US", options);
  };

  const getInventory = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/zmc/enterprise/request`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log(data.data.data);
        setInventory(data?.data?.data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    getInventory();
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.btnGroup}>
            {/* <Button
              disableElevation
              variant="contained"
              color="primary"
              className={classes.btn}
            >
              Total Request
            </Button>
            <Button
              variant="contained"
              disableElevation
              className={classes.btn}
            >
              Pending Request
            </Button>
            <Button
              variant="contained"
              disableElevation
              className={classes.btn}
            >
              Declined Request
            </Button>
            <Button
              variant="contained"
              disableElevation
              className={classes.btn}
            >
              Approved Request
            </Button> */}
          </div>
          <div></div>
        </div>
        <div style={{ overflowX: "auto" }}>
          <table className={classes.table}>
            <tr style={{ textAlign: "left" }}>
              <th className={classes.tableHead}>Enterprise Name</th>
              <th className={classes.tableHead}>Application Type</th>
              <th className={classes.tableHead}>Amount Paid</th>
              <th className={classes.tableHead}>Date of Request</th>
              <th className={classes.tableHead}>Actions</th>
            </tr>
            {loading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            )}
            {!loading && inventory?.length > 0
              ? inventory.map((items) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tableData}>{items.name}</td>
                    <td className={classes.tableData1}>
                      {items.application_type}
                    </td>
                    <td className={classes.tableData}>${items.fee}</td>
                    <td className={classes.tableData}>
                      {formatDate(items.application_date)}
                    </td>
                    <td className={classes.tableData}>
                      <Button
                        className={classes.btn}
                        variant="outlined"
                        color="primary"
                        disableElevation
                        style={{ fontSize: "14px" }}
                        onClick={() =>
                          history.push(
                            `/me/enterprise-det/${items.application_id}`,
                          )
                        }
                      >
                        View Request
                      </Button>
                    </td>
                  </tr>
                ))
              : "No Data Available"}
          </table>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseApplications;
