import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { AppConfig } from "app-config";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 0",
    background: "#fff",
    padding: "2em",
  },
  btnGroup: {
    margin: "2em 0",
  },
  btn: {
    textTransform: "capitalize",
    fontSize: "16px",
    outline: "none",
    marginRight: "20px",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    fontSize: "16px",
    color: "rgba(176, 174, 174, 1)",
    padding: "1em 0",
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableRow: {
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableData: {
    color: "rgba(31, 31, 31, 1)",
    fontWeight: "500",
    fontSize: "17px",
    padding: "1em 0",
  },
  tableData1: {
    color: "rgba(239, 163, 75, 1)",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "1em 0",
  },
}));
const EnterpriseInventories = () => {
  const history = useHistory();
  const {
    state: { activeTab },
  } = useLocation();
  const classes = useStyles();
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const formatDate = (date) => {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(date);
    return data.toLocaleDateString("en-US", options);
  };

  const getInventory = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/users/${userData.data.id}/enterprises`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      console.log(data, "data");
      if (status === true) {
        setInventory(data);
        setFiltered(data.data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getInventory();
  }, []);

  // useEffect(() => {
  //   setFiltered(inventory);
  // }, [inventory]);

  const btnList = [
    {
      title: "Total Requests",
      name: "total",
    },
    {
      title: "Pending Requests",
      name: "pending",
    },
    {
      title: "Declined Requests",
      name: "declined",
    },
    {
      title: "Approved Requests",
      name: "approved",
    },
    {
      title: "Certified Requests",
      name: "certified",
    },
  ];

  const handleClick = (name) => {
    const filtered = inventory.filter((item) => {
      if (name === "total") {
        return item;
      } else if (item.status === name) {
        return item;
      }
    });
    setFiltered(filtered);
  };

  useEffect(() => {}, []);
  // if (title === "Total Enterprise") {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.btnGroup}>
            <h3 className={classes.title}>Enterprises</h3>
          </div>
          <div></div>
        </div>
        {loading && (
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div style={{ overflowX: "auto" }}>
          <table className={classes.table}>
            <tr style={{ textAlign: "left" }}>
              <th className={classes.tableHead}>Enterprise name</th>
              <th className={classes.tableHead}>Enterprise email</th>
              <th className={classes.tableHead}>RCI number</th>
              <th className={classes.tableHead}>Phone</th>
              <th className={classes.tableHead}>Actions</th>
            </tr>

            {!!inventory.length &&
              inventory.map((item) => (
                <tr className={classes.tableRow}>
                  <td className={classes.tableData}>{item.name}</td>
                  <td className={classes.tableData}>{item.email}</td>
                  <td className={classes.tableData}>240294YRT73</td>
                  <td className={classes.tableData}>{item.phone}</td>
                  {/* <td className={classes.tableData}>
                      <Button
                        className={classes.btn}
                        variant="contained"
                        color="primary"
                        disableElevation
                        style={{
                          fontSize: "14px",
                          borderRadius: "25px",
                          background: "rgba(239, 163, 75, 0.3)",
                          color: "rgba(239, 163, 75, 1)",
                        }}
                      >
                        {item.stage}
                      </Button>
                    </td> */}
                  <td className={classes.tableData}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{ fontSize: "14px" }}
                      onClick={() =>
                        history.push(`/me/enterprise-det/${item.id}`)
                      }
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
  // }
  // return (
  //   <div className={classes.wrapper}>
  //     <div className={classes.container}>
  //       <div className={classes.header}>
  //         <div className={classes.btnGroup}>
  //           {btnList.map((item) => (
  //             <Button
  //               disableElevation
  //               variant="contained"
  //               color="primary"
  //               className={classes.btn}
  //               onClick={() => handleClick(item.name)}
  //             >
  //               {item.title}
  //             </Button>
  //           ))}
  //         </div>
  //         <div></div>
  //       </div>
  //       <div style={{ overflowX: "auto" }}>
  //         <table className={classes.table}>
  //           <tr style={{ textAlign: "left" }}>
  //             <th className={classes.tableHead}>Enterprise name </th>
  //             <th className={classes.tableHead}>Amount</th>
  //             <th className={classes.tableHead}>Remarks</th>
  //             <th className={classes.tableHead}>Date of Request</th>
  //             <th className={classes.tableHead}>Actions</th>
  //           </tr>

  //           {!!filtered &&
  //             filtered.map((items) => (
  //               <tr className={classes.tableRow}>
  //                 <td className={classes.tableData}>{items.name}</td>
  //                 <td className={classes.tableData}>
  //                   ${items.service.actual_fee}
  //                 </td>
  //                 <td className={classes.tableData1}>{items.status}</td>
  //                 <td className={classes.tableData}>
  //                   {formatDate(items.application_date)}
  //                 </td>
  //                 <td className={classes.tableData}>
  //                   <Button
  //                     className={classes.btn}
  //                     variant="outlined"
  //                     color="primary"
  //                     disableElevation
  //                     style={{ fontSize: "14px" }}
  //                   >
  //                     View
  //                   </Button>
  //                   <Button
  //                     className={classes.btn}
  //                     variant="contained"
  //                     disableElevation
  //                     color="primary"
  //                     style={{
  //                       fontSize: "14px",
  //                     }}
  //                   >
  //                     Print
  //                   </Button>
  //                 </td>
  //               </tr>
  //             ))}
  //         </table>
  //       </div>
  //     </div>
  //   </div>
  // );
};

export default EnterpriseInventories;
