import React from "react"
import ZoneDeclaration from "./ZoneDeclaration"
import SelectZmc from "./SelectZmc"
import RequestFormCor from "./FZC/ZmcCertificateOfApplication/RequestFormCOR"
import ZDLspecialty from "./FZC/ZDLspecialty"
import OPLSpecialty from "./ZMC/OPLSpecialty"

const renderProcess = (
  process,
  user,
  applicationId,
  currentStep,
  setRequest
) => {
  switch (process.name) {
    case "Free Zone":
    case "Renewal":
    case "Industrial Park":
      return (
        <ZoneDeclaration
          user={user}
          applicationId={applicationId}
          currentStep={currentStep}
          setRequest={setRequest}
          process={process}
        />
      )
    case "Development Companies":
      return (
        <ZDLspecialty
          user={user}
          applicationId={applicationId}
          currentStep={currentStep}
          setRequest={setRequest}
          process={process}
        />
      )
    case "Certificate of Registration":
      return (
        <RequestFormCor
          user={user}
          applicationId={applicationId}
          currentStep={currentStep}
          setRequest={setRequest}
          process={process}
        />
      )
    case "ZMC OPL":
      return (
        <OPLSpecialty
          user={user}
          applicationId={applicationId}
          currentStep={currentStep}
          setRequest={setRequest}
          process={process}
        />
      )
    case "Zone OPL":
    case "Zone OPL Renewal Fees":
    case "Enterprise Registration & CTC of Documents":
    case "Registry Search":
    case "Change of Ownership":
    case "Change of Name of Enterprise":
      return (
        <SelectZmc
          user={user}
          applicationId={applicationId}
          currentStep={currentStep}
          setRequest={setRequest}
          process={process}
        />
      )
    default:
      break
  }
}

export default function Proccesses({
  process,
  user,
  applicationId,
  currentStep,
  setRequest,
}) {
  return (
    <div>
      {renderProcess(process, user, applicationId, currentStep, setRequest)}
    </div>
  )
}
