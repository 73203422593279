import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Modal,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
// import CallBackCloseButton from "./CallBackCloseButton";
import Success from "./Success";
import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import { AppConfig } from "app-config";

const useStyles = makeStyles({
  submitBtn: {
    marginTop: "2rem",
    background: "#1976D2",
    color: "white",
    "&:hover": { background: "#1976D2", focus: "black" },
    padding: {
      xs: "10px 100px",
      sm: "10px 150px",
    },
    fontFamily: "Avenir Roman",
    borderRadius: "20px",
    textTransform: "capitalize",
  },
  modalTitle: {
    fontSize: {
      xs: "1.125rem",
      md: "1.4rem",
    },
    fontWeight: 400,
    paddingBottom: "1rem",
  },
  btn: {
    marginLeft: "90%",
  },
  profileImage: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "50%",
    cursor: "pointer",
    marginBottom: "3rem",
  },
  label: {
    marginRight: "20rem",
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "20px",
};

export default function NestedModal({ updateProfile, userId, profile }) {
  const inputRef = useRef(null);

  const classes = useStyles();

  const [formValues, setFormValues] = useState({});
  const [profile_image, setProfileImage] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [open, setOpen] = useState(false);
  const [loggedUser, setLoggedUser] = useState(null);

  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [fullname, setFullname] = useState("");

  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);

  const id = userData.data.id;

  const handleOpen = () => {
    setOpen(true);
    // setFormValues(entUser?.data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setProfileImage(file);
  };

  console.log("USER PROFILE DATA", profile?.data?.fullname);

  useEffect(() => {
    if (profile) {
      setFullname(profile?.data?.fullname);
      setEmail(profile?.data?.email);
      setPhone(profile?.data?.company?.phone);
      setAddress(profile?.data?.company?.address);
    }
  }, [profile]);

  const handleSubmit = (e) => {
    // e.preventDefault();
    updateProfile(userId, { fullname, email, phone, address, profile_image });
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpen}
        className={classes.btn}
      >
        Edit Profile
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box
          sx={{
            ...style,
            width: {
              xs: 300,
              sm: 450,
            },
            textAlign: "center",
            fontFamily: "Avenir Roman",
            fontSize: "1.2rem",
          }}
        >
          {/* <Box
            sx={{
              position: "relative",
              float: "right",
              cursor: "pointer",
            }}
          >
            <CallBackCloseButton onClose={handleClose} />
          </Box> */}
          <Typography component="h2" className={classes.modalTitle}>
            Edit your Profile
          </Typography>
          <form onSubmit={handleSubmit}>
            <label htmlFor="file">
              <img
                src={
                  profile_image
                    ? profile_image
                    : "https://icon-library.com/images/no-image-icon/no-image-icon-0.jpg"
                }
                alt=""
                className={classes.profileImage}
              />
              {/* <input
                type="file"
                ref={inputRef}
                accept="image/*"
                name="profileImage"
              /> */}
              <input type="file" onChange={handleImageChange} />
            </label>
            <Grid
              container
              spacing={4}
              sx={{ marginBottom: { xs: "0.75rem", sm: "1.5rem" } }}
            >
              <Grid item xs={12}>
                <label className={classes.label}>Name</label>
                <TextField
                  fullWidth
                  required
                  variant="outlined"
                  placeholder="Full Name"
                  // value={`${userData.data.company.first_name} ${userData.data.company.last_name}`}
                  value={fullname}
                  type="text"
                  name="fullname"
                  onChange={(e) => setFullname(e.target.value)}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      marginTop: "1rem",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label className={classes.label}>Email</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  name="email"
                  placeholder="Email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label
                  className={classes.label}
                  style={{ marginRight: "15rem" }}
                >
                  Phone Number
                </label>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Phone Number"
                  type="text"
                  value={phone}
                  name="phone"
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ marginTop: "-10px" }}>
                <label className={classes.label}>Address</label>
                <TextField
                  fullWidth
                  variant="outlined"
                  placeholder="Address"
                  type="text"
                  value={address}
                  name="address"
                  onChange={(e) => setAddress(e.target.value)}
                  sx={{
                    "& .MuiGrid-item": {
                      paddingTop: "16px",
                    },
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "20px",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                        md: "inherit",
                      },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              type="submit"
              fullWidth
              className={classes.submitBtn}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Modal>
      {isSuccessful && <Success />}
    </div>
  );
}
