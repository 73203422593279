import React from "react";
import RequestsTabs from "./RequestsTabs";


const RequestInventory = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        background: "white",
        borderRadius: "5px",
        marginTop: "2rem",
      }}
    >
      <RequestsTabs />
      {/* <div>Date</div> */}
    </div>
  );
};

export default RequestInventory;
