import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { AppConfig } from "app-config";
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { formatAsMoney } from "utils/money";
import Pagination from "@material-ui/lab/Pagination";
// import Stack from "@material-ui/core/Stack";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 0",
    background: "#fff",
    padding: "2em",
  },
  btnGroup: {
    margin: "2em 0",
  },
  title: {
    fontWeight: "bold",
  },
  btn: {
    textTransform: "capitalize",
    fontSize: "16px",
    outline: "none",
    marginRight: "20px",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    fontSize: "16px",
    color: "rgba(176, 174, 174, 1)",
    padding: "1em 0",
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableRow: {
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableData: {
    color: "rgba(31, 31, 31, 1)",
    fontWeight: "500",
    fontSize: "17px",
    padding: "1em 0",
  },
  tableData1: {
    color: "rgba(239, 163, 75, 1)",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "1em 0",
  },
}));
const GeneratedRevenue = () => {
  const history = useHistory();
  const {
    state: { activeTab },
  } = useLocation();
  const classes = useStyles();
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState([]);
  const [page, setPage] = useState(1);
  const formatDate = (date) => {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(date);
    return data.toLocaleDateString("en-US", options);
  };

  const getInventory = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/application/zmc/enterprise/success-request?page=${page}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );
      const {
        status,
        data: { data },
        message,
      } = await response.json();
      console.log(data);
      if (status === true) {
        setInventory(data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };
  console.log(inventory, "data");
  useEffect(() => {
    getInventory();
  }, [page]);
  // useEffect(() => {
  //   setFiltered(inventory);
  // }, [inventory]);
  const btnList = [
    {
      title: "Total Requests",
      name: "total",
    },
    {
      title: "Pending Requests",
      name: "pending",
    },
    {
      title: "Declined Requests",
      name: "declined",
    },
    {
      title: "Approved Requests",
      name: "approved",
    },
    {
      title: "Certified Requests",
      name: "certified",
    },
  ];

  const handleClick = (name) => {
    const filtered = inventory.filter((item) => {
      if (name === "total") {
        return item;
      } else if (item.status === name) {
        return item;
      }
    });
    setFiltered(filtered);
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {}, []);
  // if (title === "Total Enterprise") {
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.btnGroup}>
            <h3 className={classes.title}>Total Generated Revenue</h3>
          </div>
          <div></div>
        </div>
        {loading && (
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div style={{ overflowX: "auto" }}>
          <table className={classes.table}>
            <tr style={{ textAlign: "left" }}>
              <th className={classes.tableHead}>Enterprise name</th>
              <th className={classes.tableHead}>Enterprise email</th>
              <th className={classes.tableHead}>Remitta code</th>
              <th className={classes.tableHead}>Phone</th>
              <th className={classes.tableHead}>Amount</th>
              <th className={classes.tableHead}>Payment Status</th>
            </tr>

            {inventory?.data?.length > 0
              ? inventory?.data?.map((item) => (
                  <tr className={classes.tableRow}>
                    <td className={classes.tableData}>{item.company.name}</td>
                    <td className={classes.tableData}>{item.company.email}</td>
                    <td className={classes.tableData}>{item.remitta_code}</td>
                    <td className={classes.tableData}>{item.company.phone}</td>
                    <td className={classes.tableData}>
                      {formatAsMoney(item.real_amount)}
                    </td>
                    <td className={classes.tableData}>{item.status}</td>
                  </tr>
                ))
              : "No Data available"}
          </table>
          {/* <Stack spacing={2}> */}
          <Pagination
            page={page}
            count={
              inventory?.data?.length > 0
                ? Math.ceil(inventory.total / inventory.per_page)
                : 1
            }
            color="primary"
            onChange={handleChange}
          />
          {/* </Stack> */}
        </div>
      </div>
    </div>
  );
};
export default GeneratedRevenue;
