import React from "react";
import {
    Card,
    Container,
    Typography,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        maxWidth: 350
    },
    card: {
        display: "flex",
        flexDirection: "column",
        margin: "0 auto",
        width: "100%",
        marginTop: 5,
        padding: 5,
        paddingLeft: 20,
        background: "whitesmoke"
    },
    bold: {
        fontWeight: "bold"
    }
});

function Finish() {
    const classes = useStyles();

    return (
        <>
            <Container className={classes.container}>
                <Card className={classes.card}>
                    <Typography variant="h5" color="primary">
                        Application Successful
                    </Typography>
                    <Typography variant="body1">
                        Your application for <span className={classes.bold}>{`${localStorage.getItem("name")}`}</span> has been successfully submitted.
                        You will be contacted with more details regarding your application
                        as soon as it’s available.
                    </Typography>
                </Card>
            </Container>
        </>
    );
}
export default Finish;
