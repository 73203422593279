import React from "react";
import FileItem from "./FileItem";
import "./upload.css";
import axios from "axios";
import { AppConfig } from "../../app-config";

const FileList = ({ files, removeFile }) => {
  const url = AppConfig.URL;

  const deleteFileHandler = ({ files, removeFile }) => {
    axios.delete(url);
  };

  return (
    // <ul className="file__list">
    //   {files &&
    //     files.map((f) => (
    //       <FileItem key={f.name} file={f} deleteFile={deleteFileHandler} />
    //     ))}
    // </ul>
    <></>
  );
};

export default FileList;
