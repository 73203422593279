import React from "react";

const OplSuccessModal = () => {
  return (
    <div>
      <h1>Submission was received</h1>
    </div>
  );
};

export default OplSuccessModal;
