import { AppConfig } from "app-config";
import React, { useState } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import MuiAlert from "@material-ui/lab/Alert";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  colors,
  capitalize,
  Menu,
  MenuItem,
  Paper,
  AppBar,
  Grid,
  Tab,
  Button,
  Snackbar,
  TextField,
  CircularProgress,
  LinearProgress,
  Typography,
  Box,
} from "@material-ui/core";
import { blue, green, purple, red } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  preview: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "4rem 0",
    flexDirection: "column",
  },

  table: {
    width: "95%",
  },

  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    // alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "88%",
    // minWidth: "100%",
    margin: "4rem",
    verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "4rem 0",
    flexDirection: "column",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },

  textDiv: {
    width: "91%",
    // marginBottom: "1.4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textField: {
    width: "100%",
    marginRight: "8px",
    marginLeft: "8px",
  },
  remove: {
    color: "red",
    fontSize: "22px",
  },
  select: {
    width: " 104%",
    marginBottom: " -0.6rem",
  },
  formHead: {
    fontSize: "1.2rem",
  },
}));
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: green[200],
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      // backgroundColor: grey[50]
    },
  },
}))(TableRow);

const TotalRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: blue[500] },
}))(LinearProgress);
const ProcessedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: green[500] },
}))(LinearProgress);
const InProgreeRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: purple[500] },
}))(LinearProgress);
const DeclinedRequestBar = withStyles((theme) => ({
  root: { height: 5, borderRadius: 6 },
  bar: { backgroundColor: red[500] },
}))(LinearProgress);

export default ({ doc }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = React.useState({
    firstname: "",
    surname: "",
    middlename: "",
    nationality: "",
    address: "",
    designation: "",
    dob: "",
    year_appointed: "",
    status: "",
    passport: "",
  });
  const [team, setTeam] = React.useState({
    firstname: "",
    surname: "",
    middlename: "",
    nationality: "",
    address: "",
    designation: "",
    dob: "",
    year_appointed: "",
    status: "",
    passport: "",
  });
  const [alert, showAlert] = React.useState(false);
  const user = localStorage.getItem("userDetails");
  const [teams, setTeams] = useState([]);
  const userData = JSON.parse(user);
  const classes = useStyles();
  const [value, setValue] = React.useState("team");
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const userDetails = localStorage.getItem("userDetails");
  const userToken = JSON.parse(userDetails);

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setTeam({
      ...team,
      [event.target.name]: event.target.value,
      id: teams.length + 1,
    });
  };
  const handleFileChange = (e) => {
    setError({ ...error, [e.target.name]: "" });
    setTeam({
      ...team,
      [e.target.name]: e.target.files[0],
      id: teams.length + 1,
    });
  };
  const removeTeamMember = (e, teamId) => {
    e.preventDefault();
    setTeams(teams.filter((c) => c.id !== teamId));
  };

  const addTeam = () => {
    // setTeam({ ...team, })
    setTeams([...teams, team]);
    // setTeams([...teams, team])
    document.getElementById("teams").reset();
    setTeam({
      firstname: "",
      surname: "",
      middlename: "",
      address: "",
      nationality: "",
      designation: "",
      dob: "",
      year_appointed: "",
      status: "",
      passport: "",
    });
  };

  const handleSubmit = async () => {
    if (teams.length === 0) {
      Swal.fire("Validation error", "Kindly add a director", "info");
      return;
    }

    const formdata = new FormData();
    teams.forEach((element, index) => {
      formdata.append(
        `management_team[${index}][firstname]`,
        element.firstname
      );
      formdata.append(`management_team[${index}][surname]`, element.surname);
      formdata.append(
        `management_team[${index}][middlename]`,
        element.middlename
      );
      formdata.append(`management_team[${index}][address]`, element.address);
      formdata.append(
        `management_team[${index}][nationality]`,
        element.nationality
      );
      formdata.append(
        `management_team[${index}][designation]`,
        element.designation
      );
      formdata.append(`management_team[${index}][dob]`, element.dob);
      formdata.append(`management_team[${index}][status]`, element.status);
      formdata.append(
        `management_team[${index}][year_appointed]`,
        element.year_appointed
      );
      formdata.append(`management_team[${index}][passport]`, element.passport);
    });
    try {
      setLoading(true);

      let url = AppConfig.URL + "/zmc/team/add";
      const { status } = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json*",
          Authorization: "Bearer " + userToken.access_token,
        },
        body: formdata,
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        showAlert(true);
        setTimeout(() => {
          // handleClose();
          // getUsers();
        }, 1000);
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to process request", "info");
      setLoading(false);
    }
  };

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    showAlert(false);
  };
  const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  return (
    <TabPanel value="team">
      <div>
        <Snackbar
          open={alert}
          autoHideDuration={2000}
          onClose={closeAlert}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
          <Alert onClose={closeAlert} severity="success">
            Director created successfully!
          </Alert>
        </Snackbar>

        <div>Download and Upload then Required Document</div>

        <div className={classes.container}>
          <Grid item xs={7} className={classes.colform}>
            <Paper elevation={3} className={classes.paper}>
              <form
                className={classes.form}
                noValidate
                autoComplete="off"
                id="teams"
                encType="multipart/formdata"
              >
                <Typography variant="h4" className={classes.formHead}>
                  Add Particulars of Directors (FZR Form 1)
                </Typography>
                {/* <div className={classes.textDiv}>
                  <TextField
                    label="First name"
                    name="firstname"
                    error={!!error.firstname}
                    required
                    className={classes.textField}
                    helperText={error.firstname ? error.firstname : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    // value={Company.first_name}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    label="SurName"
                    name="surname"
                    error={!!error.surname}
                    required
                    className={classes.textField}
                    helperText={error.surname ? error.surname : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    // value={Company.surname}
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}

                {/* <div className={classes.textDiv}>
                  <TextField
                    id="outlined-error-helper-text"
                    select
                    className={classes.select}
                    variant="outlined"
                    label="Select a Status"
                    name="status"
                    value={team.status}
                    onChange={(e) => handleChange(e)}
                    disabled={loading}
                  >
                    <MenuItem value="appointed">Appointed</MenuItem>
                    <MenuItem value="removed">Removed</MenuItem>
                    <MenuItem value="reappointed">Reappointed</MenuItem>
                    <MenuItem value="resigned">Resigned</MenuItem>
                  </TextField>
                  <TextField
                    label="Nationality"
                    name="nationality"
                    error={!!error.nationality}
                    required
                    className={classes.textField}
                    helperText={error.nationality ? error.nationality : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    // value={Company.nationality}
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}

                {/* <div className={classes.textDiv}>
                  <TextField
                    // label="DOB"
                    name="dob"
                    error={!!error.dob}
                    required
                    className={classes.textField}
                    helperText={error.dob ? error.ob : ""}
                    margin="normal"
                    type="date"
                    variant="outlined"
                    // value={Company.address}
                    onChange={(e) => handleChange(e)}
                  />
                  <TextField
                    label="Year of Appointment"
                    name="year_appointed"
                    error={!!error.year_appointed}
                    required
                    className={classes.textField}
                    helperText={
                      error.year_appointed ? error.year_appointed : ""
                    }
                    margin="normal"
                    type="number"
                    variant="outlined"
                    onChange={(e) => handleChange(e)}
                  />
                </div> */}

                <div className={classes.textDiv}>
                  <TextField
                    // label="Passport"
                    name="passport"
                    error={!!error.passport}
                    required
                    className={classes.textField}
                    helperText={error.passport ? error.passport : ""}
                    margin="normal"
                    type="file"
                    variant="outlined"
                    // value={Company.designation}
                    onChange={handleFileChange}
                  />
                </div>
                <div className={classes.textDiv}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    size="large"
                    disabled={loading}
                  >
                    Submits
                  </Button>
                </div>
                <div
                  style={{
                    marginTop: "1rem",
                    display: "flex",
                    alignSelf: "flex-start",
                    marginLeft: "0.8rem",
                  }}
                >
                  <Box>
                    <a
                      style={{
                        fontWeight: "bold",
                      }}
                      href={doc}
                      download
                    >
                      Download Document Form
                    </a>
                  </Box>
                </div>
              </form>
              {loading && (
                <div className={classes.spinner}>
                  <CircularProgress />
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="initial"
                  >
                    Submiting Team...
                  </Typography>
                </div>
              )}
            </Paper>
          </Grid>
          <Grid item xs={5} className={classes.colpreview}>
            <Paper elevation={3} className={classes.paper}>
              <div className={classes.preview}>
                <Typography>Preview Document</Typography>
              </div>
              {loading && (
                <div className={classes.spinner}>
                  <CircularProgress />
                  <Typography
                    component="span"
                    variant="body2"
                    className={classes.inline}
                    color="initial"
                  >
                    Submiting Team...
                  </Typography>
                </div>
              )}
            </Paper>
          </Grid>
        </div>
      </div>
    </TabPanel>
  );
};
