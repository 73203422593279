export const ApprovedResponse = {
  text: "There are no approved requests",
};

export const CertifiedResponse = {
  text: "There are no certified requests",
};

export const PendingResponse = {
  text: "There are no pending requests",
};

export const DeclinedRequest = {
  text: "There are no decline requests",
};

export const TotalRequests = {
  text: "There no requests",
};
