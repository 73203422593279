import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Tabs, Tab, Typography, Box } from "@material-ui/core";
import TotalRequestsTab from "./TotalRequestsTab";
import PendingRequestsTab from "./PendingRequestsTab";
import DeclinedRequestsTab from "./DeclinedRequestsTab";
import ApprovedRequestsTab from "./ApprovedRequests";
import { useLocation } from "react-router-dom";

const tabNumberMap = {
  "Total Requests": 0,
  "Pending Requests": 1,
  "Declined Requests": 2,
  "Processed Requests": 3,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `nav-tab-${index}`,
    "aria-controls": `nav-tabpanel-${index}`,
  };
}

function LinkTab(props) {
  return (
    <Tab
      component="a"
      onClick={(event) => {
        event.preventDefault();
      }}
      {...props}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 2,
  },
  label: {
    background: "rgba(245, 244, 244, 0.8)",
    textTransform: "capitalize",
    margin: "18px",
    "& .makeStyles-label-3": {
      marginRight: 0,
    },
  },
  tabePanel: {
    "&.MuiTab-textColorInherit.Mui-selected": {
      width: "1%",
    },
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
}));

const tabTitles = [
  "Total requests",
  "Pending requests",
  "Declined requests",
  "Approved request",
];

const RequestsTabs = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [active, setActive] = useState(0);

  const location = useLocation();

  console.log("LOCATION!!!", location);

  const activeTabFromHomepage = location?.state;

  console.log("ACTIVETABFROMPAGE", activeTabFromHomepage);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClick = (e) => {
    setActive(e);
  };

  useEffect(() => {
    if (!activeTabFromHomepage) return;

    setActive(tabNumberMap[activeTabFromHomepage?.activeTab?.title] || 0);
  }, [activeTabFromHomepage]);

  console.log("ACTIVE!!", active);

  return (
    <div className={classes.root}>
      <Tabs
        variant="fullWidth"
        onChange={handleChange}
        aria-label="nav tabs example"
        TabIndicatorProps={{
          style: {
            display: "none",
          },
        }}
      >
        {tabTitles.map((tabs, index) => (
          <LinkTab
            key={index}
            label={tabs}
            className={classes.label}
            {...a11yProps(0)}
            style={{
              color: active === index ? "white" : "black",
              backgroundColor:
                active === index ? "#47BB78" : "rgba(245, 244, 244, 0.8)",
              // width: "1%",
            }}
            onClick={() => handleClick(index)}
          />
        ))}
      </Tabs>

      <TabPanel value={value} index={0}>
        <TotalRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <PendingRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DeclinedRequestsTab />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <ApprovedRequestsTab />
      </TabPanel>
    </div>
  );
};

export default RequestsTabs;
