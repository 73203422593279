import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  editButton: {
    border: "1px solid #47BB78",
    marginRight: "5px",
    textTransform: "capitalize",
  },
  deleteButton: {
    border: "1px solid #EB4B4B",
    background: "#EB4B4B",
    color: "white",
    textTransform: "capitalize",
    "&:hover": {
      background: "#EB4B4B",
      color: "white",
    },
  },
});

const ActionButtons = () => {
  const classes = useStyles();
  return (
    <div>
      <Button className={classes.editButton}>Edit</Button>
      <Button className={classes.deleteButton}>Delete</Button>
    </div>
  );
};

export default ActionButtons;
