import React from "react"
import {Paper} from "@material-ui/core"
import form1A from "../assets/form1A.pdf"
import form1B from "../assets/form1B.pdf"
import form2A from "../assets/form2A.pdf"
import form2B from "../assets/form2B.pdf"
import form3 from "../assets/form3.pdf"
import form5 from "../assets/form5.pdf"
import zmcApplicationForm from "../assets/nepzaApplication.pdf"

const ZoneManagementChecklist = () => {
  const requirements = [
    "ZMC Application Form",
    "Application letter for registration of the Management Company",
    "Board resolution appointing the Directors of the management company",
    "Board resolution approving the registration of the company in the Zone",
    "Memorandum and articles of Association of the Company",
    "Particulars of Directors or any Change therein (FZR FORM 1)",
    "Statement of Share Capital and Return of allotment form (FZR FORM 2)",
    "Declaration of Compliance with the requirements of the NEPZA ACT (FZR FORM 3)",
    "Notice of situation/Change of Registered Address (FZR FORM 5)",
    "Environemental Impact Assessment",
  ]

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingTop: "2em",
      }}
    >
      <Paper style={{padding: "2em"}}>
        <h1 style={{fontSize: "2em", fontWeight: "bold", marginBottom: "1em"}}>
          Requirement List
        </h1>
        <ol type="1">
          {requirements.map((requirement, i) => (
            <li
              style={{marginBottom: "12px", fontSize: "16px"}}
              key={requirement}
            >
              {i + 1}. {requirement}
              {requirement.includes("1") ? (
                <>
                  <a
                    href={form1A}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Download Form 1A
                  </a>
                  {""} and
                  <a
                    href={form1B}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Form 1B
                  </a>
                </>
              ) : requirement.includes("2") ? (
                <>
                  <a
                    href={form2A}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Download Form 2A
                  </a>{" "}
                  and
                  <a
                    href={form2B}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Form 2B
                  </a>
                </>
              ) : requirement.includes("3") ? (
                <>
                  <a
                    href={form3}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Download Form 3
                  </a>
                </>
              ) : requirement.includes("5") ? (
                <>
                  <a
                    href={form5}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Download Form 5
                  </a>
                </>
              ) : requirement.includes("ZMC") ? (
                <>
                  <a
                    href={zmcApplicationForm}
                    download
                    style={{color: "green", textDecoration: "underline"}}
                  >
                    {" "}
                    Download Form here
                  </a>
                </>
              ) : (
                ""
              )}
            </li>
          ))}
        </ol>
      </Paper>
    </div>
  )
}

export default ZoneManagementChecklist
