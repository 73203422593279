import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useState, useEffect } from "react";
import AddSpecialty from "./AddSpecialty";
import axios from "axios";
import { AppConfig } from "app-config";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 0",
    height: "100%",
    // display: "flex",
    // alignItems: "start",
    width: "100%",
    background: "rgba(250, 250, 251, 1)",
  },
  wrapper: {
    width: "100%",
    display: "flex",
    margin: "2em",
    padding: "0 2em",
    gap: "2em",
  },
  formFields: {
    padding: "1em 0",
  },
  title: {
    margin: "10px 0",
    fontSize: "16px",
  },
  left: {
    flex: 1,
    background: "#fff",
    padding: " 1em 2em",
    width: "100%",
    margin: "1em 0",
  },
  right: {
    flex: 1,
    background: "#fff",
    width: "100%",
    margin: "1em 0",
    justifyContent: "space-between",
  },
  title: {
    margin: "10px 0",
    fontSize: "16px",
  },
  formGroup: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "2em",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    margin: "1em 0",
    marginTop: "4em",
  },
  btnCancel: {
    background: "rgba(231, 46, 46, 1)",
    padding: "1em 4em",
    borderRadius: "5px",
    color: "#fff",
  },
  btnContinue: {
    border: "1px solid rgba(71, 187, 120, 1)",
    padding: "1em 4em",
    borderRadius: "5px",
    color: "rgba(71, 187, 120, 1)",
  },
  btnContinue1: {
    border: "1px solid rgba(71, 187, 120, 1)",
    padding: "1em 4em",
    borderRadius: "5px",
    color: "rgba(71, 187, 120, 1)",
    width: "100%",
  },
  rightTitle: {
    border: "3px solid rgba(71, 187, 120, 1)",
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    lineHeight: "2.3em",
    padding: "0 8px",
    fontSize: "16px",
    color: "rgba(71, 187, 120, 1)",
  },
  rightContent: {
    padding: "1.5em",
  },
  contentTitle: {
    color: "rgba(176, 174, 174, 1)",
    fontSize: "16px",
  },
  subTitle: {
    color: "rgba(31, 31, 31, 1)",
    fontSize: "14px",
    marginBottom: "1em",
  },
}));
const OplPrincipalOfficers = ({ currentStep, setRequest, reqBody }) => {
  const classes = useStyles();
  const [principalOfficers, setPrincipalOfficers] = useState([]);
  const [valid, setValid] = useState(true);
  const [zoneId, setZoneId] = useState("");
  const [formValues, setFormValues] = useState({
    name: "",
    address: "",
    email: "",
    nationality: "",
    phone: "",
  });
  const user = localStorage.getItem("userDetails");

  const userData = JSON.parse(user);

  const id = userData.data.id;
  const getUserZone = async (e) => {
    try {
      const response = await axios.get(`${AppConfig.URL}/users/${id}/zone`, {
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, message, data } = response;

      if (status) {
        const { zone_id } = data.data;
        console.log(zone_id);
        setZoneId(zone_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getUserZone();
  }, []);

  const handleChange = (e) =>
    setFormValues({ ...formValues, [e.target.name]: e.target.value });

  const handleClick = () => {
    if (
      formValues.name === "" ||
      formValues.address === "" ||
      formValues.nationality === "" ||
      formValues.phone === ""
    ) {
      setValid(false);
    } else {
      setValid(true);
      setPrincipalOfficers([...principalOfficers, formValues]);

      setFormValues({
        name: "",
        address: "",
        position: "",
        email: "",
        nationality: "",
        phone: "",
      });
    }
  };

  const handleSubmit = () => {
    if (principalOfficers.length < 1) {
      setValid(false);
    }
    setValid(true);
    setRequest({ ...reqBody, principalOfficers, zoneId });
    currentStep((prevActiveStep) => prevActiveStep + 1);
  };
  return (
    <>
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classes.left}>
            <div className={classes.formFields}>
              <p className={classes.title}>Name of Director</p>
              <TextField
                variant="outlined"
                fullWidth
                value={formValues.name}
                name="name"
                onChange={handleChange}
              />
              <p className={classes.title}>Contact Address</p>
              <TextField
                variant="outlined"
                fullWidth
                multiline
                rows="4"
                value={formValues.address}
                name="address"
                onChange={handleChange}
              />
              <div>
                <div>
                  <p className={classes.title}>Position Held</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={formValues.position}
                    name="position"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className={classes.title}> Email</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={formValues.email}
                    name="email"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className={classes.formGroup}>
                <div>
                  <p className={classes.title}>Nationality</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={formValues.nationality}
                    name="nationality"
                    onChange={handleChange}
                  />
                </div>
                <div>
                  <p className={classes.title}> Phone Number</p>
                  <TextField
                    variant="outlined"
                    fullWidth
                    value={formValues.phone}
                    name="phone"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className={classes.btnGroup}>
                <button
                  className={classes.btnCancel}
                  onClick={() =>
                    currentStep((prevActiveStep) => prevActiveStep - 1)
                  }
                >
                  Cancel
                </button>
                <button
                  className={classes.btnContinue}
                  onClick={handleClick}
                  // disabled={!valid}
                >
                  Add Director
                </button>
              </div>
              {!valid && (
                <span style={{ color: "red" }}>
                  {" "}
                  Please enter all fields to proceed
                </span>
              )}
            </div>
          </div>
          <div className={classes.right}>
            {!!principalOfficers.length > 0 ? (
              <>
                {principalOfficers.map((item, index) => (
                  <div>
                    <p className={classes.rightTitle}>
                      Director's Information {index + 1}
                    </p>
                    <div className={classes.rightContent}>
                      <p className={classes.contentTitle}>Directors Name</p>
                      <h3 className={classes.subTitle}>{item.name}</h3>
                      <p className={classes.contentTitle}>Contact Address</p>
                      <h3 className={classes.subTitle}>{item.address}</h3>
                      <p className={classes.contentTitle}>Email</p>
                      <h3 className={classes.subTitle}>{item.email}</h3>
                      <p className={classes.contentTitle}>Nationality</p>
                      <h3 className={classes.subTitle}>{item.nationality}</h3>
                      <p className={classes.contentTitle}>Phone Number</p>
                      <h3 className={classes.subTitle}>{item.phone}</h3>
                    </div>
                  </div>
                ))}
                <button className={classes.btnContinue1} onClick={handleSubmit}>
                  Proceed
                </button>
              </>
            ) : (
              <h1 style={{ textAlign: "center", padding: "2em" }}>
                No Director Added yet!
              </h1>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default OplPrincipalOfficers;
