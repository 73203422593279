import React, {useState} from "react"
import PropTypes from "prop-types"
import {
  AppBar,
  Collapse,
  CssBaseline,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  Box,
} from "@material-ui/core"
import MenuIcon from "@material-ui/icons/Menu"
import {PowerSettingsNew} from "@material-ui/icons"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import {makeStyles, useTheme} from "@material-ui/core/styles"
import AccountCircle from "@material-ui/icons/AccountCircle"
import TabContext from "@material-ui/lab/TabContext"
import {
  Switch,
  Route,
  Link,
  NavLink,
  useHistory,
  Redirect,
} from "react-router-dom"
import routes, {fzcItems, partnerItems} from "routes"
import logo from "images/Nepzaimage.png"
import CompanyRequestDetials from "../CompanyRequest/CompanyRequestDetails"
import ZmcActionSubmitted from "../Account/ZmcActionSubmitted"
import AddParticulars from "./AddParticulars"
import {data} from "./EnterpriseList"

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
  `,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // backgroundColor: theme.palette.action.hover
  },
  title: {
    flexGrow: 1,
  },
  menuLink: {
    position: "relative",
    display: "block",
  },
  menuItem: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: theme.spacing(1) * 1.5,
    paddingBottom: theme.spacing(1) * 1.5,
  },
  menuIcon: {
    marginLeft: theme.spacing(1) * 2,
    marginRight: theme.spacing(1) * 2,
  },
  menuActive: {
    backgroundColor: theme.palette.action.hover,
  },
  logo: {
    maxWidth: "80%",
  },
}))

function RegisteredOffice(props) {
  const {window} = props
  const classes = useStyles()
  const theme = useTheme()
  const [mobileOpen, setMobileOpen] = React.useState(false)
  const [openCheckList, setOpenCheckList] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [value, setValue] = useState("team")

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  let id = 1

  const history = useHistory()

  const getUser = () => {
    const userDetails = localStorage.getItem("userDetails")
    if (userDetails) {
      return JSON.parse(userDetails)
    }
  }

  const getUserDetails = () => {
    return JSON.parse(localStorage.getItem("userDetails"))
  }

  const handleCheckList = () => {
    setOpenCheckList(!openCheckList)
  }

  const handleLogout = () => {
    localStorage.clear()
    history.push("/")
  }

  const drawer = () => (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <img src={logo} className={classes.logo} alt="logo" />
      <Divider />
      {(getUserDetails()?.data?.company?.entity_type_id === 3
        ? fzcItems
        : partnerItems
      ).map((route, index) =>
        route.hidden ? (
          ""
        ) : (
          <>
            <NavLink
              to={route.path}
              exact
              className={classes.menuLink}
              activeClassName={classes.menuActive}
              key={index}
            >
              <ListItem button className={classes.menuItem}>
                <ListItemIcon>
                  {<route.icon className={classes.menuIcon} />}
                </ListItemIcon>
                <ListItemText primary={route.title} />
              </ListItem>
            </NavLink>
          </>
        )
      )}

      <List>
        <ListItem key={id} button onClick={handleCheckList}>
          <ListItemIcon>
            {openCheckList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItemIcon>
          <ListItemText primary={"Check List"} />
        </ListItem>

        <Collapse in={openCheckList} timeout="auto" unmountOnExit>
          {data.map((list, index) => {
            const {name, url} = list
            return (
              <List key={index}>
                <ListItem
                  button
                  onClick={() => {
                    history.push(`/${url}`)
                  }}
                >
                  <ListItemText sx={{color: "black"}} primary={name} />
                </ListItem>
              </List>
            )
          })}

          {/* <List key="2" component="div" disablePadding>
            {data.map((list, index) => {
              const { name, url } = list;
              return (
                <ListItem
                  button
                  key={index}
                  onClick={() => {
                    history.push(`/${url}`);
                  }}
                >
                  <ListItemText sx={{ color: "black" }} primary={name} />
                </ListItem>
              );
            })}
          </List> */}
        </Collapse>
      </List>
      <Divider />
      <List>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </List>
    </div>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined

  return (
    <div className={classes.root}>
      <div>
        <CssBaseline />
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap className={classes.title}>
              {getUser()?.data?.company?.name}
            </Typography>
            <Typography variant="h6">{getUser()?.data?.fullname}</Typography>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-haspopup="true"
              // onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Toolbar>
        </AppBar>

        <div style={{display: "flex"}}>
          <nav className={classes.drawer} aria-label="mailbox folders">
            <Hidden smUp implementation="css">
              <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === "rtl" ? "right" : "left"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true,
                }}
              >
                {drawer()}
              </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
              <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
              >
                {drawer()}
              </Drawer>
            </Hidden>
          </nav>
          <div style={{marginTop: "80px"}}>
            <TabContext value={value}>
              <AddParticulars doc="../../../public/assets/form4b.pdf" />
            </TabContext>
          </div>
        </div>
      </div>
    </div>
  )
}

RegisteredOffice.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
}

export default RegisteredOffice
