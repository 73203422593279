/* eslint-disable no-unused-vars */
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import {
  Typography,
  Tooltip,
  Snackbar,
  Paper,
  TextField,
  IconButton,
  CircularProgress,
  MenuItem,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import Swal from "sweetalert2";
import { AppConfig } from "../app-config";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import RequestInfoDialog from "./RequestInfoDialog";

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  container: {
    display: "flex",
    height: "100%",
    // alignItems: "center",
    // justifyContent: "center"
  },
  paper: {
    minHeight: "45vh",
    height: "auto",
    width: "100%",
    // minWidth: "370px",
    // margin: "4rem"
    // verticalAlign: "middle",
  },
  form: {
    width: "100%",
    display: "flex",
    height: "auto",
    alignItems: "center",
    justifyContent: "center",
    padding: "4rem 0",
    flexDirection: "column",
  },
  text: {
    width: "100%",
    marginBottom: "1.4rem",
  },
  button: {
    marginTop: "1rem",
  },
  inline: {
    marginLeft: "1rem",
  },
  large: {
    width: theme.spacing(12),
    height: theme.spacing(12),
    marginRight: "1rem",
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    height: "100%",
  },
  textDiv: {
    width: "90%",
    // marginBottom: "1.4rem",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  username: {
    width: "90%",
  },
  textField: {
    width: "48%",
  },

  root: {
    flexGrow: 1,
  },
  dataContainer: {
    maxWidth: "100%",
    marginRight: "0",
  },
  demo: {
    backgroundColor: theme.palette.background.default,
  },
  title: {
    // margin: theme.spacing(4, 0, 2),
  },
  primary: {
    color: "gray",
  },
  secondary: {
    color: "rgba(0, 0, 0, 0.87)",

    fontSize: "18px",
  },
  addButton: {
    background: "#48BB78",
    color: "white",
    marginLeft: 8,
    "&:hover": {
      background: "#48BB78",
    },
  },
  fileTitle: {
    border: "1px solid gray",
    borderRadius: "10px",
    padding: "10px",
    textAlign: "center",
    maxWidth: "150px",
  },
  truncate: {
    width: "120px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  box: {
    width: "100%",
  },
  innerbox: {
    maxWidth: "100%",
  },
  listFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    // borderBottom: '1px solid lightgrey'
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function UpdateProfile() {
  const [Company, setCompany] = React.useState({});
  const [error, setError] = React.useState({
    name: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    address: "",
    rc_no: "",
    entity_type_id: "",
    username: "",
    password: "",
  });

  const [loading, setLoading] = React.useState(false);
  const [alert, showAlert] = React.useState(false);
  const [entities, setEntities] = React.useState([]);
  const [dense, setDense] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [approvalId, setApprovalId] = React.useState("");
  const [docId, setDocId] = React.useState("");
  // const []= React.useState('')

  const classes = useStyles();
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const userApprovals = userData.data.company.zmc_profile;

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const addRequestInfo = (id, docId) => {
    setApprovalId(id);
    setDocId(docId);
    setOpenDialog(true);
  };

  const handleChange = (event) => {
    setError({ ...error, [event.target.name]: "" });
    setCompany({
      ...Company,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    const {
      name,
      first_name,
      last_name,
      email,
      phone,
      address,
      rc_no,
      entity_type_id,
      username,
    } = Company;

    if (!name) {
      return setError({ ...error, name: "Please provide a company name" });
    }
    if (!first_name) {
      return setError({ ...error, first_name: "Please provide a First name" });
    }
    if (!last_name) {
      return setError({ ...error, last_name: "Please provide a Last name" });
    }
    if (!email) {
      return setError({ ...error, email: "Please provide a Email" });
    }
    if (!phone) {
      return setError({ ...error, phone: "Please provide a Phone" });
    }
    if (!address) {
      return setError({ ...error, address: "Please provide a Address" });
    }
    if (!rc_no) {
      return setError({ ...error, rc_no: "Please provide a Rc no" });
    }
    if (!entity_type_id) {
      return setError({
        ...error,
        entity_type_id: "Please provide a Entity type id",
      });
    }

    if (phone.length > 11) {
      return setError({ ...error, phone: "Phone number must be 11 digits" });
    }

    const body = {
      name,
      first_name,
      last_name,
      email,
      phone,
      address,
      rc_no,
      entity_type_id,
      username,
      company_id: userData.data.company_id,
    };

    try {
      setLoading(true);

      let reqMethod = "PUT";
      let url = AppConfig.URL + "/company/update";

      const { status } = await fetch(url, {
        method: reqMethod,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + sessionStorage["token"],
        },
        body: JSON.stringify(body),
      }).then((res) => res.json());
      if (status) {
        setLoading(false);
        setCompany({
          name: "",
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          address: "",
          rc_no: "",
          entity_type_id: "",
        });
        showAlert(true);
        setTimeout(() => {
          closeAlert();
        }, 1000);
      } else {
        Swal.fire("Something went wrong", "Unable to process request", "info");
        setLoading(false);
      }
    } catch (err) {
      Swal.fire("Something went wrong", "Unable to process request");
      setLoading(false);
    }
  };

  const getEntities = async () => {
    try {
      const response = await fetch(`${AppConfig.URL}/entity_types/list`, {
        method: "GET",
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setEntities(data.data);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
    }
  };

  const getComapnyData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/company/get/${userData.data.company_id}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        },
      );
      const { status, data, message } = await response.json();
      if (status === true) {
        setCompany(data);
        setLoading(false);
      } else {
        Swal.fire("", message, "info");
      }
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
    }
  };

  React.useEffect(() => {
    getEntities();
    getComapnyData();
  }, []);

  const closeAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    showAlert(false);
  };

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  return (
    <>
      <Grid container spacing={1} className={classes.box}>
        <Grid item xs={12} sm={8} className={classes.innerbox}>
          <div>
            <Snackbar
              open={alert}
              autoHideDuration={2000}
              onClose={closeAlert}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert onClose={closeAlert} severity="success">
                Company updated successfully!
              </Alert>
            </Snackbar>

            <div className={classes.container}>
              <Paper elevation={3} className={classes.paper}>
                <form className={classes.form} noValidate autoComplete="off">
                  <Typography variant="h4">Company Profile</Typography>
                  <div className={classes.textDiv}>
                    <TextField
                      label="Company Name"
                      name="name"
                      error={!!error.name}
                      required
                      className={classes.textField}
                      helperText={error.name ? error.name : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.name}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      error={!!error.entity_type_id}
                      select
                      variant="outlined"
                      label="Entity type"
                      name="entity_type_id"
                      value={Company.entity_type_id}
                      onChange={(e) => handleChange(e)}
                      className={classes.textField}
                      helperText={
                        error.entity_type_id ? error.entity_type_id : ""
                      }
                    >
                      {entities.map((option) => (
                        <MenuItem
                          key={option.entity_type_id}
                          value={option.entity_type_id}
                        >
                          {option.entity_type}
                        </MenuItem>
                      ))}
                    </TextField>
                  </div>

                  <div className={classes.textDiv}>
                    <TextField
                      label="First name"
                      name="first_name"
                      error={!!error.first_name}
                      required
                      className={classes.textField}
                      helperText={error.first_name ? error.first_name : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.first_name}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Last name"
                      name="last_name"
                      error={!!error.last_name}
                      required
                      className={classes.textField}
                      helperText={error.last_name ? error.last_name : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.last_name}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className={classes.textDiv}>
                    <TextField
                      label="Email"
                      name="email"
                      error={!!error.email}
                      required
                      className={classes.textField}
                      helperText={error.email ? error.email : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.email}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="Phone"
                      name="phone"
                      error={!!error.phone}
                      required
                      className={classes.textField}
                      helperText={error.phone ? error.phone : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.phone}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  <div className={classes.textDiv}>
                    <TextField
                      label="Address"
                      name="address"
                      error={!!error.address}
                      required
                      className={classes.textField}
                      helperText={error.address ? error.address : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.address}
                      onChange={(e) => handleChange(e)}
                    />
                    <TextField
                      label="RC nunmber"
                      name="rc_no"
                      error={!!error.rc_no}
                      required
                      className={classes.textField}
                      helperText={error.rc_no ? error.rc_no : ""}
                      margin="normal"
                      type="text"
                      variant="outlined"
                      value={Company.rc_no || ""}
                      onChange={(e) => handleChange(e)}
                    />
                  </div>

                  {/* <div className={classes.username}> */}
                  <TextField
                    label="Username"
                    name="username"
                    error={!!error.username}
                    required
                    className={classes.username}
                    helperText={error.username ? error.username : ""}
                    margin="normal"
                    type="text"
                    variant="outlined"
                    value={Company.username || ""}
                    onChange={(e) => handleChange(e)}
                  />
                  {/* </div> */}

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleSubmit}
                    className={classes.button}
                    size="large"
                    disabled={loading}
                  >
                    Submit
                  </Button>
                </form>
                {loading && (
                  <div className={classes.spinner}>
                    <CircularProgress />
                    <Typography
                      component="span"
                      variant="body2"
                      className={classes.inline}
                      color="initial"
                    >
                      Submiting Company...
                    </Typography>
                  </div>
                )}
              </Paper>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4} className={classes.innerbox}>
          <div className={classes.container}>
            <Paper elevation={3} className={classes.paper}>
              <div className={classes.form}>
                <Typography variant="h4" className={classes.title}>
                  Admin Actions
                </Typography>
                {
                  <div className={classes.demo}>
                    <List dense={dense}>
                      {userApprovals.approvals.map((document, index) => (
                        <div key={index + "approval"}>
                          <ListItem className={classes.listFlex}>
                            <ListItemText
                              primary={"Admin Remarks"}
                              secondary={document.comment}
                            />
                            {/* {document.file_url ?
                            <ListItemText className={classes.fileTitle}>
                              <a href={document?.file_url} target="_blank" download>

                                <IconButton edge="end" aria-label="download">
                                  <CloudDownloadIcon />
                                </IconButton>
                              </a>
                              <Tooltip title={document?.file_title}>
                                <div className={classes.truncate}>{document?.file_title}</div>

                              </Tooltip>
                            </ListItemText>

                            : null} */}
                            {document?.type == "request_information" ? (
                              <ListItemText>
                                <Button
                                  className={classes.addButton}
                                  onClick={() =>
                                    addRequestInfo(
                                      document?.id,
                                      document?.document_id,
                                    )
                                  }
                                >
                                  Add information
                                </Button>
                              </ListItemText>
                            ) : null}
                          </ListItem>
                          {document?.conversations
                            ? document?.conversations.map((item, index) => (
                                <ListItem key={index + item.comment}>
                                  <ListItemText
                                    primary={"User Response"}
                                    secondary={item.comment}
                                  />
                                  {/* <ListItemSecondaryAction>
                              <a href={item.file_url} target="_blank" download>
                                <IconButton edge="end" aria-label="download">
                                  <CloudDownloadIcon />
                                </IconButton>
                              </a>
                              <Tooltip title={document?.file_title}>
                                <div className={classes.truncate}>{document?.file_title}</div>
                              </Tooltip>
                            </ListItemSecondaryAction> */}
                                </ListItem>
                              ))
                            : null}
                          <hr />
                        </div>
                      ))}
                    </List>
                  </div>
                }
              </div>
            </Paper>
          </div>
        </Grid>
      </Grid>
      <RequestInfoDialog
        open={openDialog}
        handleClose={handleCloseDialog}
        handleClickOpen={handleClickOpenDialog}
        approvalId={approvalId}
        documentId={docId}
        setOpenDialog={setOpenDialog}
      />
    </>
  );
}
