import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";
import { AppConfig } from "app-config";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "2em 0",
    background: "#fff",
    padding: "2em",
  },
  titld: {
    fontSize: "2em",
    fontWeight: "bold",
  },
  btnGroup: {
    margin: "2em 0",
  },
  btn: {
    textTransform: "capitalize",
    fontSize: "16px",
    outline: "none",
    marginRight: "20px",
  },
  table: {
    width: "100%",
  },
  tableHead: {
    fontSize: "16px",
    color: "rgba(176, 174, 174, 1)",
    padding: "1em 0",
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableRow: {
    borderTop: "1px solid rgba(243, 245, 247, 1)",
  },
  tableData: {
    color: "rgba(31, 31, 31, 1)",
    fontWeight: "500",
    fontSize: "17px",
    padding: "1em 0",
  },
  tableData1: {
    color: "rgba(239, 163, 75, 1)",
    fontSize: "16px",
    fontWeight: "bold",
    padding: "1em 0",
  },
}));
const Enterprises = () => {
  const classes = useStyles();
  const history = useHistory();
  const user = localStorage.getItem("userDetails");
  const userData = JSON.parse(user);
  const [inventory, setInventory] = useState([]);
  const [loading, setLoading] = useState(false);

  const getInventory = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${AppConfig.URL}/zmc/enterprise/inventory`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userData.access_token}`,
          },
        }
      );
      const { status, data, message } = await response.json();

      if (status === true) {
        console.log(data.data.data);
        setInventory(data.data?.data);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };
  useEffect(() => {
    getInventory();
  }, []);

  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.btnGroup}>
            <h3 className={classes.title}>My Enterprises</h3>
          </div>
          <div></div>
        </div>
        {loading && (
          <div
            style={{
              width: "100vw",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress />
          </div>
        )}
        <div style={{ overflowX: "auto" }}>
          <table className={classes.table}>
            <tr style={{ textAlign: "left" }}>
              <th className={classes.tableHead}>Enterprise name</th>
              <th className={classes.tableHead}>Application Type</th>
              <th className={classes.tableHead}>RCI number</th>
              <th className={classes.tableHead}>Status</th>
              <th className={classes.tableHead}>Actions</th>
            </tr>

            {!!inventory.length &&
              inventory.map((item) => (
                <tr className={classes.tableRow}>
                  <td className={classes.tableData}>{item.name}</td>
                  <td className={classes.tableData}>{item.application_type}</td>
                  <td className={classes.tableData}>240294YRT73</td>
                  <td className={classes.tableData}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{
                        fontSize: "14px",
                        borderRadius: "25px",
                        background: "rgba(239, 163, 75, 0.3)",
                        color: "rgba(239, 163, 75, 1)",
                      }}
                    >
                      {item.stage}
                    </Button>
                  </td>
                  <td className={classes.tableData}>
                    <Button
                      className={classes.btn}
                      variant="contained"
                      color="primary"
                      disableElevation
                      style={{ fontSize: "14px" }}
                      onClick={() =>
                        history.push(
                          `/me/enterprise-det/${item.application_id}`
                        )
                      }
                    >
                      View Details
                    </Button>
                  </td>
                </tr>
              ))}
          </table>
        </div>
      </div>
    </div>
  );
};

export default Enterprises;
