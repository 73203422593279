import {Dialog, TextField} from "@material-ui/core"
import React, {useState} from "react"
import {makeStyles} from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  formFields: {
    padding: "3em 2em",
  },
  title: {
    margin: "10px 0",
    fontSize: "18px",
  },
  btn: {
    margin: "1em 0",
    width: "100%",
    padding: "1em 0",
    background: "rgba(71, 187, 120, 1)",
    color: "rgb(255, 255, 255)",
    border: "1px solid rgba(71, 187, 120,1)",
    borderRadius: "5px",
    outline: "none",
  },
}))

const AddSpecialty = ({setSpecialties, specialties, open, handleClose}) => {
  const classes = useStyles()
  const [specialty, setSpecialty] = useState("")
  const handleAddSpecialty = () => {
    setSpecialties([...specialties, specialty])
    handleClose()
    setSpecialty("")
  }
  return (
    <div>
      <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div className={classes.formFields}>
          <p className={classes.title}>Enter Specialty</p>
          <TextField
            type="text"
            onChange={e => setSpecialty(e.target.value)}
            value={specialty}
            variant="outlined"
            fullWidth
          />
          <button className={classes.btn} onClick={handleAddSpecialty}>
            Add Specialty
          </button>
        </div>
      </Dialog>
    </div>
  )
}

export default AddSpecialty
