import React, { useState } from "react";
import { TextField, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "6rem",
    gap: "20px",
  },
  button: {
    marginTop: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "20px 40px",
  },
  btnContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const NameChange = () => {
  const [loading, setLoading] = useState(true);
  const [focused, setFocused] = useState(false);
  const [value, setValue] = useState({
    currentName: "",
    newName: "",
  });
  const [error, setError] = useState({
    newName: "",
    currentName: "",
  });
  const classes = useStyles();

  const handleFocused = (e) => {
    setFocused(true);
  };

  const handleChange = (e) => {
    setValue({ ...value, [e.target.name]: [e.target.value] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  };

  return (
    <>
      <Typography
        style={{ textAlign: "center", marginTop: "2rem", fontSize: "1.5rem" }}
      >
        Change of Enterprise Name
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.container}>
          <TextField
            label="Registered Name"
            name=""
            required
            margin="normal"
            type="text"
            variant="outlined"
            style={{ width: "400px" }}
            value={value.currentName}
            onChange={handleChange}
            onBlur={handleFocused}
          />
          <TextField
            label="New Name"
            name="person_names"
            required
            margin="normal"
            type="text"
            variant="outlined"
            style={{ width: "400px" }}
            value={value.newName}
            onChange={handleChange}
            onBlur={handleFocused}
          />
          <TextField
            label="$150.00"
            name="person_names"
            margin="normal"
            type="text"
            variant="outlined"
            style={{ width: "400px" }}
            disabled
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#47BB78",
              padding: "1rem 12rem",
              color: "white",
              outline: "none",
              marginTop: "3rem",
              // textTransform: "capitalize",
            }}
            onClick={handleSubmit}
          >
            Make Payment
          </Button>
        </div>
      </form>
    </>
  );
};

export default NameChange;
