import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import red from '@material-ui/core/colors/red';
import { useHistory, useParams } from "react-router-dom";
import axiosInstance from '../../Api/Api.js'
import MockRemmittaLogo from 'images/mockremitta.jpg'



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minWidth: "400px",
    '& > *': {
      margin: theme.spacing(1),

    },
  },
  grid: {
    marginTop: "100px"
  },
  payButton: {
    backgroundColor: "#8b000f",
    color: "white"
  },
  cardNumber: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  banner: {
    maxHeight: "100px"
  }
}));

export default function BasicTextFields() {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState()


  const query = new URLSearchParams(window.location.search);
  const orderId = query.get('orderID');
  useEffect(() => {
    getDataToMakePayment();
  }, [])


  const getDataToMakePayment = () => {
    setLoading(true);

    axiosInstance
      .get(`/application/generate_rrr/${orderId}`)
      .then(function (response) {

        if (response.status === 200) {
          console.log(response)

        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        console.log(error.response)
      })
      .finally(() => setLoading(false));

  }

  return (
    <Container fluid>
      <Grid
        className={classes.grid}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Paper variant="outlined" >
          <form className={classes.root} noValidate autoComplete="off">
            <img className={classes.banner} src={MockRemmittaLogo} alt="TestLogo" />
            <Typography>Please enter your TEST card details to Pay</Typography>
            <TextField id="standard-basic" label="Amount" variant="filled" />
            <TextField id="standard-basic" label="RRR Code" variant="filled" />
            <div className={classes.cardNumber}>
              <Grid item xs={8}>
                <TextField id="standard-basic" label="Card Number" variant="filled" />

              </Grid>
              <Grid item xs={4}>

                <TextField id="filled-basic" label="CVV" variant="filled" />
              </Grid>
            </div>
            <TextField id="outlined-basic" label="Expiry Date" variant="filled" />
            <Button onClick={() => history.push(`finalize?orderID=${orderId}`)} className={classes.payButton} variant="contained" size="large" color="secondary" disableElevation>
              Pay With Card
</Button>
          </form>
        </Paper>
      </Grid>
    </Container>
  );
}