import {AppConfig} from "app-config"
import React, {useState, useEffect} from "react"
import Swal from "sweetalert2"
import {makeStyles, withStyles} from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Grid from "@material-ui/core/Grid"
import {CircularProgress, LinearProgress, Typography} from "@material-ui/core"
import FileCopyIcon from "@material-ui/icons/FileCopy"
import {blue, green, purple, red} from "@material-ui/core/colors"
import CheckCircleOutlinedIcon from "@material-ui/icons/CheckCircleOutlined"
import DonutLargeOutlinedIcon from "@material-ui/icons/DonutLargeOutlined"
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined"
import Tables from "./Dashboard/Table"
import totalRequest from "../images/totalRequestIcon.svg"
import pendingRequest from "../images/pendingRequest.svg"
import declinedRequest from "../images/declinedRequest.svg"
import approvedRequest from "../images/approvedRequest.svg"
import totalAmount from "../images/totalAmount.svg"
// import totalRequest from '../images/totalRequest.svg'
import {
  Chart,
  Series,
  ArgumentAxis,
  Legend,
  Label,
} from "devextreme-react/chart"
import axiosInstance from "Api/Api"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    // minHeight: "150px",

    display: "flex",
    // flexDirection: "column",
    justifyContent: "space-between",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "3em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))

const TotalRequestBar = withStyles(theme => ({
  root: {height: 5, borderRadius: 6},
  bar: {backgroundColor: blue[500]},
}))(LinearProgress)
const ProcessedRequestBar = withStyles(theme => ({
  root: {height: 5, borderRadius: 6},
  bar: {backgroundColor: green[500]},
}))(LinearProgress)
const InProgreeRequestBar = withStyles(theme => ({
  root: {height: 5, borderRadius: 6},
  bar: {backgroundColor: purple[500]},
}))(LinearProgress)
const DeclinedRequestBar = withStyles(theme => ({
  root: {height: 5, borderRadius: 6},
  bar: {backgroundColor: red[500]},
}))(LinearProgress)

export default () => {
  const [loading, setLoading] = useState(false)
  const [stat, setStat] = useState({})

  const user = localStorage.getItem("userDetails")
  const [charts, setCharts] = useState([])
  const [regStatus, setRegStatus] = useState(false)

  const userData = JSON.parse(user)
  const classes = useStyles()
  console.log("fzc")
  const getCategories = async () => {
    setLoading(true)
    try {
      const response = await fetch(`${AppConfig.URL}/application/stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      })
      const {status, data, message} = await response.json()
      if (status === true) {
        setStat(data)
        setCharts(data.charts)
        console.log(data)
      } else {
        Swal.fire("", message, "info")
      }
      setLoading(false)
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error")
      setLoading(false)
    }
  }

  const formatMoney = money => {
    return `$ ${money.toLocaleString()}`
  }

  React.useEffect(() => {
    getCategories()
  }, [])

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    )

  const iconSelector = item => {
    if (item.title === "Total Requests") {
      return totalRequest
    } else if (item.title === "Requests in progress") {
      return pendingRequest
    } else if (item.title === "Processed Requests") {
      return pendingRequest
    } else if (item.title === "Declined Requests") {
      return declinedRequest
    } else if (item.title === "Pending Requests") {
      return pendingRequest
    } else if (item.title === "Total Request Amount") {
      return totalAmount
    }
  }

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat.cards
          ? stat.cards.map((item, index) => (
              <Grid key={`item${index}`} item xs={12} sm={6} md={3}>
                <Paper className={classes.paper} elevation={0}>
                  <div className={classes.cards}>
                    <img src={iconSelector(item)} />
                    <div>
                      <Typography variant="h5" style={{marginBottom: "1em"}}>
                        {item.isMoney ? formatMoney(item.data) : item.data}
                      </Typography>
                      {/* <CheckCircleOutlinedIcon style={{ color: blue[500] }} /> */}
                      <Typography style={{color: "rgba(176, 174, 174, 1)"}}>
                        {item.title}
                      </Typography>
                    </div>
                  </div>
                </Paper>
              </Grid>
            ))
          : null}
      </Grid>
      {charts.length
        ? charts.map((item, index) => (
            <Grid key={index + item.title} item xs={12} sm={12}>
              <Paper className={classes.paper}>
                <Chart id="chart" dataSource={item.data}>
                  <Series
                    valueField="occurrence"
                    argumentField="name"
                    name={item.title}
                    type="bar"
                    color="#ffaa66"
                  />
                </Chart>
              </Paper>
            </Grid>
          ))
        : null}

      {/* <Grid container className={classes.bottomData} spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography>Upcoming Renewal</Typography>
            <Tables list={stat?.upcoming_renewals} renewal={true} />
          </Paper>
        </Grid>
     
      </Grid> */}
    </div>
  )
}
