import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import axiosInstance from "../Api/Api.js";
import { useHistory } from "react-router-dom";
import Swal from 'sweetalert2';

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        width: '700px',
    },
    formControl: {
        marginTop: theme.spacing(2),
        minWidth: 120,
    },
    formControlLabel: {
        marginTop: theme.spacing(1),
    },
}));


export default function RequestInfoDialog({ handleClose, setOpenDialog, handleClickOpen, approvalId, documentId, open }) {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const [file, setFile] = useState('');
    const [title, setTitle] = useState('')

    const handleCommentChange = (event) => {
        event.preventDefault();
        setComment(event.target.value);
    };
    const handleTitleChange = (event) => {
        event.preventDefault();
        setTitle(event.target.value);
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);

    }

    const handleSubmit = (e) => {
        setLoading(true);
        const formData = new FormData()

        formData.append("zmc_approval_id", Number(approvalId));
        formData.append("comment", comment);
        if (file) {
            formData.append("file", file);
        }
        if (title) {
            formData.append("file_title", title);
        }
        if (documentId) {
            formData.append('replace_document', true)
            formData.append("document_id", Number(documentId));
        }
        if (!documentId) {
            formData.append('replace_document', false)
        }

        console.log(formData, comment, file)
        axiosInstance
            .post("/zmc/conversation/add", formData)
            .then(function (response) {
                if (response.status === 200) {
                    console.log(response);
                    history.push("/me/zmc-action-submitted");
                    // setOpenDialog(false)
                } else {
                    console.log(response);
                    setOpenDialog(false)
                    Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Something went wrong',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }
            })
            .catch(function (error) {
                console.log(error?.response?.data?.message);
                setOpenDialog(false)
                Swal.fire({
                    position: 'center',
                    icon: 'error',
                    title: 'Something went wrong',
                    showConfirmButton: false,
                    timer: 1500
                })
            })
            .finally(() => setLoading(false));
    };

    return (
        <div>
            <form className={classes.form} noValidate>
                <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">ADD INFORMATION</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please provide the required information
                        </DialogContentText>

                        <TextField
                            autoFocus
                            margin="dense"
                            id="name:"
                            label="Note"
                            multiline
                            rows={4}
                            type="text"
                            onChange={handleCommentChange}
                            fullWidth
                        />
                        <div style={{ marginTop: "20px" }}>File Upload:</div>

                        {/* <TextField
                            margin="dense"
                            id="title:"
                            label="FIle Title (Optional)"
                            type="text"
                            onChange={handleTitleChange}
                        /> */}
                        <input
                            onChange={handleFileChange}
                            margin="dense"
                            id="file"
                            label="File Upload (Optional)"
                            type="file"
                            // fullWidth
                            style={{ marginTop: "20px", marginLeft: "5px" }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        {loading ? <Button color="primary">
                            Loading...
                        </Button> : <Button onClick={handleSubmit} color="primary">
                            Submit
                        </Button>}
                    </DialogActions>
                </Dialog>
            </form>
        </div>
    );
}