import { AppConfig } from "app-config";
import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { blue, green, purple, red } from "@material-ui/core/colors";
import TotalRequestIcon from "../images/totalRequestIcon.svg";
import TotalRequestAmountIcon from "../images/totalRequestAmountIcon.svg";
import PendingRequestIcon from "../images/pendingRequestIcon.svg";
import ApprovedRequestIcon from "../images/approvedRequestIcon.svg";
import DeclinedRequestIcon from "../images/declinedRequestsIcon.svg";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowDownIcon from "../images/arrowdown.svg";
import { Link, useLocation } from "react-router-dom";
import { Chart, Series, CommonSeriesSettings } from "devextreme-react/chart";
import { formatAsMoney } from "utils/money";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardGrid: {
    marginBottom: "20px",
    marginTop: "20px",
  },
  bottomData: {
    marginTop: "20px",
  },
  stats: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 2rem",
    margin: "2rem 0",
  },
  paper: {
    padding: theme.spacing(2),
    // textAlign: 'center',
    color: "black",
    minHeight: "150px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },

  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "3em",
  },
  blue: {
    color: blue[500],
  },
  spinner: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "2rem",
  },
  select: {
    padding: "0.8rem 2.5rem",
    border: "1px solid #B0AEAE",
    borderRadius: "5px",
    color: "#B0AEAE",
    appearance: "none",
    backgroundImage: `url(${ArrowDownIcon})`,
    backgroundSize: "12px",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "calc(100% - 8px) center",
    outline: "none",
  },
}));

const icons = [
  TotalRequestIcon,
  ApprovedRequestIcon,
  DeclinedRequestIcon,
  PendingRequestIcon,
  TotalRequestAmountIcon,
];

const ids = ["total", "pending", "declined", "pending", "total"];

export default () => {
  const [loading, setLoading] = useState(false);
  const [stat, setStat] = useState({});

  const user = localStorage.getItem("userDetails");
  const [charts, setCharts] = useState([]);

  const userData = JSON.parse(user);
  const classes = useStyles();

  const roleId = userData.data.role_id;

  const { pathname } = useLocation();

  const getCategories = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/stats`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        setStat(data);
        setCharts(data.chart);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategories();
  }, []);

  if (loading)
    return (
      <div className={classes.spinner}>
        <CircularProgress />
      </div>
    );

  const dataArray = charts?.data?.map((item) => {
    // cumulativeCount += item.val
    return {
      month: item.month,
      count: item.val,
    };
  });

  const routeHandler = (item) => {
    console.log(roleId, "ROLEDI");
    let route;
    if (
      (item.title == "Total Requests" ||
        item.title == "Processed Requests" ||
        item.title == "Declined Requests" ||
        item.title == "Pending Requests" ||
        item.title == "Total Request Amount") &&
      roleId == 8
    ) {
      route = "/me/enterprise-request";
    } else if (item.title == "Total Requests" && roleId == 8) {
      route = "/me/zmc-zones";
    } else if (item.title == "Total Enterprise" && roleId == 14) {
      route = "/me/zmc-enterprise-request";
    } else if (
      (item.title == "Total Requests Amount" ||
        item.title === "Total Requests") &&
      roleId == 14
    ) {
      route = "/me/zmc-total-requests-amount";
    } else if (item.title == "Generated Revenue" && roleId == 14) {
      route = "/me/zmc-generated-revenue";
    }
    return route;
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3} className={classes.cardGrid}>
        {stat.cards
          ? stat.cards.map((item, index) => (
              <Link
                to={{
                  pathname: routeHandler(item),
                  state: {
                    activeTab: item,
                  },
                }}
                key={`item${index}`}
                style={{ background: "white", margin: "10px" }}
              >
                <Grid item xs={12} sm={6} md={3}>
                  <Paper className={classes.paper} elevation={0}>
                    <div className={classes.cards}>
                      <img src={icons[index]} />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div>
                          <Typography style={{ marginBottom: "1em" }}>
                            {item.is_money
                              ? formatAsMoney(item.data)
                              : item.data}
                          </Typography>
                          {/* <CheckCircleOutlinedIcon style={{ color: blue[500] }} /> */}
                          <Typography
                            style={{
                              color: "rgba(176, 174, 174, 1)",
                              fontSize: "0.9rem",
                            }}
                          >
                            {item.title}
                          </Typography>
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <KeyboardArrowRightIcon
                            style={{
                              fontSize: 20,
                              color: "#B0AEAE",
                              marginTop: "20px",
                              marginLeft: "20px",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Paper>
                </Grid>
              </Link>
            ))
          : null}
      </Grid>

      <Paper>
        <div className={classes.stats}>
          <div>
            <Typography>My Requests</Typography>
          </div>
          <div>
            <form>
              <select id="cat" name="cat" className={classes.select}>
                <option value="volvo">Sort by ...</option>
                <option value="name">Name</option>
                <option value="date">Date</option>
              </select>
            </form>
          </div>
        </div>
        <Chart id="chart" dataSource={dataArray}>
          <CommonSeriesSettings argumentField="month" />
          <Series
            name=""
            valueField="count"
            axis="" //
            type="bar"
            color="#fac29a"
          />
        </Chart>
      </Paper>

      {/* <Grid container className={classes.bottomData} spacing={3}>
        <Grid item xs={6}>
          <Paper className={classes.paper}>
            <Typography>Upcoming Renewal</Typography>
            <Tables list={stat?.upcoming_renewals} renewal={true} />
          </Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>Request Amount</Paper>
        </Grid>
        <Grid item xs={6}>
          <Paper className={classes.paper}>Last Treated Request</Paper>
        </Grid>
      </Grid> */}
    </div>
  );
};
