import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  MenuItem,
  Button,
  CircularProgress,
} from "@material-ui/core";
import tick from "../../images/tick.svg";
import downloadIcon from "../../images/downloadIcon.svg";
import accountIcon from "../../images/accountIcon.svg";
import { useParams } from "react-router-dom";
import { AppConfig } from "app-config";
import Swal from "sweetalert2";
import axiosInstance from "Api/Api";

const useStyles = makeStyles((theme) => ({
  container: {
    background: "#Fff",
  },
  wrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    minHeight: "100vh",
    padding: "2em",
    gap: "2em",
    width: "90%",
    marginTop: "2em",
  },
  left: {
    display: "flex",
    flexDirection: "column",
  },
  right: {
    // background: "rgba(250, 250, 251, 1)",
  },
  leftContent: {
    background: "rgba(250, 250, 251, 1)",
    marginBottom: "2em",
  },
  rightContent: {
    background: "rgba(250, 250, 251, 1)",
    marginBottom: "2em",
    maxHeight: "400px",
    overflow: "scroll",
  },
  content: {
    padding: "1.5em",
  },
  upload: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  label: {
    border: "3px solid rgba(71, 187, 120, 1)",
    borderTop: "none",
    borderRight: "none",
    borderBottom: "none",
    lineHeight: "2.3em",
    padding: "0 8px",
    fontSize: "16px",
    color: "rgba(71, 187, 120, 1)",
  },
  contentTitle: {
    color: "rgba(176, 174, 174, 1)",
    fontSize: "16px",
  },
  subTitle: {
    color: "rgba(31, 31, 31, 1)",
    fontSize: "14px",
    marginBottom: "1em",
  },
}));
const EnterpriseDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [enterpriseDetails, setEnterpriseDetails] = useState({});
  const user = localStorage.getItem("userDetails");
  const [sendLoading, setSendLoading] = useState(false);
  const userData = JSON.parse(user);
  const [comment, setComment] = useState("");
  const [approved, setApproval] = useState({});
  const approvalIntances = [
    {
      approval_instance_id: 4,
      approval_type: "Approve",
    },
    {
      approval_instance_id: 5,
      approval_type: "Declined",
    },
    {
      approval_instance_id: 6,
      approval_type: "Request Information",
    },
  ];

  const getEnterpriseDetails = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${AppConfig.URL}/application/show/${id}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${userData.access_token}`,
        },
      });
      const { status, data, message } = await response.json();
      if (status === true) {
        console.log("DATA_APPP", data.application);
        setEnterpriseDetails(data.application);
      } else {
        Swal.fire("", message, "info");
      }
      setLoading(false);
    } catch (error) {
      Swal.fire("Something went wrong", "Unable to process request", "error");
      setLoading(false);
    }
  };

  const formatAsMoney = (fee) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(fee);
  };

  useEffect(() => {
    getEnterpriseDetails();
  }, [id]);

  console.log("Enterprise Details ID:", enterpriseDetails);

  const formatDate = (date) => {
    let options = {
      // weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    let data = new Date(date);
    return data.toLocaleDateString("en-US", options);
  };

  const handleSubmit = async () => {
    console.log("ENTER__ID", enterpriseDetails.id);
    setSendLoading(true);
    axiosInstance

      .post(`/application_approval/add`, {
        comment,
        approval_instance_id: approved,
        application_id: enterpriseDetails.id,
      })
      .then((response) => {
        if (response.status === 200) {
          Swal.fire(
            "Approval recorded",
            "Your approval has been recorded successfully",
            "success",
          );
        } else {
          Swal.fire("", response.message, "info");
        }
      })
      .catch((error) => {
        Swal.fire(
          "Your approval cold not be processed",
          error.message,
          "error",
        );
      })
      .finally(() => setSendLoading(false));
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div className={classes.left}>
          <div className={classes.leftContent}>
            <h3 className={classes.label}>Enterprise Information</h3>
            <div className={classes.content}>
              <p className={classes.contentTitle}>Enterprise Name</p>
              <h3 className={classes.subTitle}>
                {enterpriseDetails?.company?.name}
              </h3>
              {/* <p className={classes.contentTitle}>RCI Number</p>
              <h3 className={classes.subTitle}>97028459FG67</h3> */}
              <p className={classes.contentTitle}>Enterprise address</p>
              <h3 className={classes.subTitle}>
                {enterpriseDetails?.company?.address}
              </h3>
              <p className={classes.contentTitle}>Enterprise speciality</p>
              <h3 className={classes.subTitle}>Oil and gas</h3>
              <p className={classes.contentTitle}>Email</p>
              <h3 className={classes.subTitle}>
                {enterpriseDetails?.company?.email}
              </h3>
            </div>
          </div>
          <div className={classes.leftContent}>
            <h3 className={classes.label}>Application details</h3>
            <div className={classes.content}>
              <p className={classes.contentTitle}>Application type</p>
              <h3 className={classes.subTitle}>
                {enterpriseDetails?.service?.name}
              </h3>
              <p className={classes.contentTitle}>Remitta code</p>
              <h3 className={classes.subTitle}>
                {enterpriseDetails?.remitta_code}
              </h3>
              <p className={classes.contentTitle}>Application fee</p>
              <h3 className={classes.subTitle}>
                {formatAsMoney(enterpriseDetails?.fee)}
              </h3>
              <p className={classes.contentTitle}>Application date</p>
              <h3 className={classes.subTitle}>
                {formatDate(enterpriseDetails?.application_date)}
              </h3>
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <div className={classes.rightContent}>
            <h3 className={classes.label}>Uploaded documents</h3>
            <div className={classes.content}>
              {enterpriseDetails?.application_attachments?.map((item) => (
                <div className={classes.upload}>
                  <img src={tick} alt="" />
                  <small style={{ padding: "0 10px", fontSize: "15px" }}>
                    {item?.document?.name}
                  </small>
                  <img src={downloadIcon} alt="" />
                </div>
              ))}
            </div>
          </div>
          <div className={classes.rightContent}>
            <h3 className={classes.label}>Approval Trail</h3>
            {enterpriseDetails?.application_approvals?.map((item) => (
              <div className={classes.content}>
                <div className={classes.upload}>
                  <img src={accountIcon} alt="" />
                  <small style={{ padding: "0 10px", fontSize: "15px" }}>
                    {item?.approval_instance?.approval_stage?.approval_stage}
                  </small>
                </div>
                {/* <p>I strongly advise semira enterprise is approved</p> */}
              </div>
            ))}
          </div>
          <div className={classes.rightContent}>
            <h3 className={classes.label}>Approval form</h3>
            <div className={classes.content}>
              <div style={{ marginBottom: "10px" }}>
                {approvalIntances.length ? (
                  <TextField
                    id="standard-secondary"
                    label="Approval Action"
                    variant="outlined"
                    select
                    fullWidth
                    // value={values.approval_instance_id}
                    // onChange={handleChange("approval_instance_id")}
                    onChange={(e) => setApproval(e.target.value)}
                  >
                    {approvalIntances.map((option) => (
                      <MenuItem
                        key={option.approval_instance_id}
                        value={option.approval_instance_id}
                      >
                        {option.approval_type === "Approve"
                          ? "Approve"
                          : option.approval_type === "Approve"
                          ? "Approve to Proceed"
                          : option.approval_type}
                      </MenuItem>
                    ))}
                  </TextField>
                ) : null}
              </div>
              <div>
                <TextField
                  id="outlined-secondary"
                  label="Remarks"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  //   value={values.remark}
                  //   onChange={handleChange("comment")}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                style={{ marginTop: "15px", textTransform: "capitalize" }}
                onClick={handleSubmit}
              >
                {sendLoading ? <CircularProgress /> : "Send"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseDetails;
